import * as React from "react";
import "./Foods.scss";

export default function Foods() {
  return (
    <>
      <div className="widget-wrapper">
        <div className="widget-title">Foods</div>
        <div className="card-list">
          <div className="card">5515</div>
          <div className="card">5515</div>
          <div className="card">5515</div>
          <div className="card">5515</div>
          <div className="card">5515</div>
        </div>
      </div>
    </>
  );
}
