const data = [
  {
    "id": "japan",
    "color": "hsl(94, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 143
      },
      {
        "x": "helicopter",
        "y": 95
      },
      {
        "x": "boat",
        "y": 31
      },
      {
        "x": "train",
        "y": 33
      },
      {
        "x": "subway",
        "y": 289
      },
      {
        "x": "bus",
        "y": 265
      },
      {
        "x": "car",
        "y": 20
      },
      {
        "x": "moto",
        "y": 143
      },
      {
        "x": "bicycle",
        "y": 57
      },
      {
        "x": "horse",
        "y": 147
      },
      {
        "x": "skateboard",
        "y": 161
      },
      {
        "x": "others",
        "y": 65
      }
    ]
  },
  {
    "id": "france",
    "color": "hsl(145, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 273
      },
      {
        "x": "helicopter",
        "y": 185
      },
      {
        "x": "boat",
        "y": 258
      },
      {
        "x": "train",
        "y": 220
      },
      {
        "x": "subway",
        "y": 297
      },
      {
        "x": "bus",
        "y": 164
      },
      {
        "x": "car",
        "y": 60
      },
      {
        "x": "moto",
        "y": 267
      },
      {
        "x": "bicycle",
        "y": 293
      },
      {
        "x": "horse",
        "y": 8
      },
      {
        "x": "skateboard",
        "y": 144
      },
      {
        "x": "others",
        "y": 247
      }
    ]
  },
  {
    "id": "us",
    "color": "hsl(330, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 271
      },
      {
        "x": "helicopter",
        "y": 227
      },
      {
        "x": "boat",
        "y": 49
      },
      {
        "x": "train",
        "y": 7
      },
      {
        "x": "subway",
        "y": 179
      },
      {
        "x": "bus",
        "y": 166
      },
      {
        "x": "car",
        "y": 123
      },
      {
        "x": "moto",
        "y": 6
      },
      {
        "x": "bicycle",
        "y": 82
      },
      {
        "x": "horse",
        "y": 266
      },
      {
        "x": "skateboard",
        "y": 245
      },
      {
        "x": "others",
        "y": 93
      }
    ]
  },
  {
    "id": "germany",
    "color": "hsl(322, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 168
      },
      {
        "x": "helicopter",
        "y": 62
      },
      {
        "x": "boat",
        "y": 37
      },
      {
        "x": "train",
        "y": 47
      },
      {
        "x": "subway",
        "y": 122
      },
      {
        "x": "bus",
        "y": 192
      },
      {
        "x": "car",
        "y": 156
      },
      {
        "x": "moto",
        "y": 226
      },
      {
        "x": "bicycle",
        "y": 165
      },
      {
        "x": "horse",
        "y": 186
      },
      {
        "x": "skateboard",
        "y": 88
      },
      {
        "x": "others",
        "y": 162
      }
    ]
  },
  {
    "id": "norway",
    "color": "hsl(61, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 149
      },
      {
        "x": "helicopter",
        "y": 184
      },
      {
        "x": "boat",
        "y": 187
      },
      {
        "x": "train",
        "y": 92
      },
      {
        "x": "subway",
        "y": 21
      },
      {
        "x": "bus",
        "y": 212
      },
      {
        "x": "car",
        "y": 78
      },
      {
        "x": "moto",
        "y": 283
      },
      {
        "x": "bicycle",
        "y": 200
      },
      {
        "x": "horse",
        "y": 110
      },
      {
        "x": "skateboard",
        "y": 260
      },
      {
        "x": "others",
        "y": 275
      }
    ]
  }
]

export default data;