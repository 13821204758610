import * as React from "react";
import Map, { Layer, Source } from "react-map-gl/maplibre";
import data from "../../common/data/world";
import "./Map.scss";
import "maplibre-gl/dist/maplibre-gl.css";

export default function MapComponent() {
  return (
    <>
      <Map
        initialViewState={{
          longitude: -0.4,
          latitude: 37.8,
          zoom: 0.5,
        }}
        style={{
          width: "100%",
          height: "100%",
          background: "#151516",
          borderTopRightRadius: "16px",
          borderBottomRightRadius: "16px"
        }}
      >
        <Source type="geojson" data={data}>
          <Layer
            id="world"
            type="fill"
            paint={{
              "fill-color": "#424A55",
              "fill-outline-color": "#989DA6",
            }}
          />
        </Source>
      </Map>
    </>
  );
}
