const data = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [74.92, 37.24],
              [74.57, 37.03],
              [72.56, 36.82],
              [71.24, 36.13],
              [71.65, 35.42],
              [71.08, 34.06],
              [69.91, 34.04],
              [70.33, 33.33],
              [69.51, 33.03],
              [69.33, 31.94],
              [66.72, 31.21],
              [66.26, 29.85],
              [62.48, 29.41],
              [60.87, 29.86],
              [61.85, 31.02],
              [60.84, 31.5],
              [60.58, 33.07],
              [60.94, 33.52],
              [60.51, 34.14],
              [61.28, 35.61],
              [62.72, 35.25],
              [63.12, 35.86],
              [64.5, 36.28],
              [64.8, 37.12],
              [66.54, 37.37],
              [67.78, 37.19],
              [69.32, 37.12],
              [70.97, 38.47],
              [71.59, 37.9],
              [71.68, 36.68],
              [73.31, 37.46],
              [74.92, 37.24]
            ]
          ]
        ]
      },
      "properties": { "name": "Afghanistan" },
      "id": "AF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [19.44, 41.02],
              [19.37, 41.85],
              [19.65, 42.62],
              [20.07, 42.56],
              [20.59, 41.88],
              [20.82, 40.91],
              [20.98, 40.86],
              [20.01, 39.69],
              [19.29, 40.42],
              [19.44, 41.02]
            ]
          ]
        ]
      },
      "properties": { "name": "Albania" },
      "id": "AL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [2.96, 36.8],
              [8.62, 36.94],
              [8.18, 36.52],
              [8.25, 34.64],
              [7.49, 33.89],
              [9.06, 32.1],
              [9.54, 30.23],
              [9.95, 27.82],
              [9.87, 26.51],
              [9.4, 26.15],
              [10.25, 24.61],
              [11.56, 24.3],
              [11.99, 23.52],
              [5.81, 19.45],
              [4.25, 19.15],
              [3.33, 18.98],
              [3.23, 19.82],
              [1.8, 20.31],
              [-4.81, 25],
              [-6.66, 26.13],
              [-8.67, 27.29],
              [-8.67, 27.67],
              [-8.67, 28.71],
              [-3.63, 30.97],
              [-3.82, 31.7],
              [-1.18, 32.11],
              [-1.75, 34.75],
              [-2.21, 35.09],
              [0.95, 36.45],
              [2.96, 36.8]
            ]
          ]
        ]
      },
      "properties": { "name": "Algeria" },
      "id": "DZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-170.64, -14.29],
              [-170.83, -14.33],
              [-170.56, -14.27],
              [-170.64, -14.29]
            ]
          ],
          [
            [
              [-169.44, -14.26],
              [-169.51, -14.28],
              [-169.54, -14.23],
              [-169.44, -14.26]
            ]
          ]
        ]
      },
      "properties": { "name": "Samoa" },
      "id": "WS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [1.78, 42.57],
              [1.72, 42.51],
              [1.45, 42.6],
              [1.78, 42.57]
            ]
          ]
        ]
      },
      "properties": { "name": "Andorra" },
      "id": "AD"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [14, -5.85],
              [16.58, -5.9],
              [17.62, -8.1],
              [19.37, -8],
              [19.54, -7],
              [21.78, -7.28],
              [22.25, -11.21],
              [23.99, -10.87],
              [24.02, -13.01],
              [22, -13],
              [22, -16.17],
              [23.48, -17.63],
              [23.28, -17.66],
              [20.85, -18.02],
              [18.45, -17.39],
              [13.99, -17.42],
              [13.16, -16.95],
              [11.75, -17.25],
              [12.51, -13.43],
              [13.79, -11.79],
              [12.98, -9.09],
              [13.39, -8.39],
              [12.24, -6.1],
              [13.18, -5.86],
              [14, -5.85]
            ]
          ],
          [
            [
              [13.09, -4.66],
              [12.21, -5.77],
              [12.03, -5.01],
              [12.78, -4.39],
              [13.09, -4.63],
              [13.09, -4.66]
            ]
          ]
        ]
      },
      "properties": { "name": "Angola" },
      "id": "AO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-61.69, 17.02],
              [-61.89, 17.11],
              [-61.79, 17.16],
              [-61.69, 17.02]
            ]
          ],
          [
            [
              [-61.73, 17.61],
              [-61.85, 17.58],
              [-61.87, 17.7],
              [-61.73, 17.61]
            ]
          ]
        ]
      },
      "properties": { "name": "Antigua and Barbuda" },
      "id": "AG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [45.08, 39.77],
              [45.82, 39.55],
              [46.18, 38.84],
              [45, 39.42],
              [44.81, 39.63],
              [44.78, 39.71],
              [45.08, 39.77]
            ]
          ],
          [
            [
              [45.51, 40.61],
              [45.52, 40.67],
              [45.57, 40.63],
              [45.51, 40.61]
            ]
          ],
          [
            [
              [46.57, 41.87],
              [47.77, 41.2],
              [48.58, 41.84],
              [50.37, 40.26],
              [49.49, 40.15],
              [48.89, 38.44],
              [48.02, 38.84],
              [48.36, 39.39],
              [47.98, 39.72],
              [46.54, 38.88],
              [46.54, 39.56],
              [45.6, 39.98],
              [46, 40.23],
              [45.15, 41.2],
              [45.02, 41.3],
              [46.52, 41.05],
              [46.45, 41.9],
              [46.57, 41.87]
            ]
          ]
        ]
      },
      "properties": { "name": "Azerbaijan" },
      "id": "AZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-68.26, -52.99],
              [-68.54, -53.23],
              [-67.36, -54.03],
              [-65.14, -54.65],
              [-66.45, -55.05],
              [-68.64, -54.79],
              [-68.62, -52.64],
              [-68.26, -52.99]
            ]
          ],
          [
            [
              [-65.75, -22.11],
              [-65.19, -22.09],
              [-64.59, -22.21],
              [-64.32, -22.87],
              [-63.94, -22],
              [-62.64, -22.24],
              [-61.01, -23.81],
              [-57.76, -25.18],
              [-57.58, -25.55],
              [-58.6, -27.32],
              [-55.74, -27.44],
              [-54.7, -26.44],
              [-54.6, -25.57],
              [-53.86, -25.68],
              [-53.81, -27.13],
              [-55.77, -28.23],
              [-57.61, -30.18],
              [-57.81, -30.75],
              [-58.2, -32.45],
              [-58.15, -33.05],
              [-58.43, -33.1],
              [-58.53, -33.52],
              [-58.47, -34.54],
              [-57.19, -35.32],
              [-57.38, -35.96],
              [-56.66, -36.9],
              [-58.3, -38.49],
              [-62.38, -38.8],
              [-62.02, -39.38],
              [-62.39, -40.9],
              [-65.13, -40.85],
              [-65.01, -42.09],
              [-64.45, -42.45],
              [-63.75, -42.09],
              [-63.58, -42.62],
              [-64.96, -42.67],
              [-64.3, -42.99],
              [-65.32, -43.65],
              [-65.6, -45.02],
              [-66.95, -45.26],
              [-67.58, -46],
              [-66.82, -46.99],
              [-65.78, -47.19],
              [-66.24, -47.86],
              [-65.79, -47.96],
              [-67.58, -49.03],
              [-67.9, -49.99],
              [-69.01, -50.01],
              [-68.37, -50.15],
              [-69.41, -51.08],
              [-68.97, -51.57],
              [-69.61, -51.63],
              [-68.99, -51.62],
              [-68.44, -52.38],
              [-71.91, -52],
              [-72.4, -51.51],
              [-72.29, -50.65],
              [-73.17, -50.75],
              [-73.58, -49.54],
              [-72.56, -48.8],
              [-72.36, -47.47],
              [-71.67, -46.68],
              [-71.78, -45.65],
              [-71.3, -45.29],
              [-72.08, -44.77],
              [-71.11, -44.54],
              [-71.86, -44.37],
              [-72.13, -42.29],
              [-71.73, -42.1],
              [-71.7, -39.58],
              [-70.82, -38.57],
              [-71.19, -36.84],
              [-70.42, -36.14],
              [-70.57, -35.25],
              [-69.81, -34.24],
              [-70.53, -31.19],
              [-69.83, -30.19],
              [-69.66, -28.4],
              [-68.29, -26.92],
              [-68.57, -24.77],
              [-67.34, -24.02],
              [-67.18, -22.82],
              [-66.22, -21.78],
              [-65.75, -22.11]
            ]
          ]
        ]
      },
      "properties": { "name": "Argentina" },
      "id": "AR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [145.11, -40.82],
              [146.59, -41.19],
              [148.22, -40.85],
              [148, -43.23],
              [147.32, -42.85],
              [146.92, -43.62],
              [146.04, -43.5],
              [144.68, -41.22],
              [144.7, -40.76],
              [145.11, -40.82]
            ]
          ],
          [
            [
              [142.51, -10.87],
              [143.78, -14.41],
              [144.51, -14.17],
              [145.32, -14.95],
              [146.28, -18.88],
              [148.78, -20.23],
              [149.66, -22.5],
              [150.64, -22.34],
              [150.87, -23.51],
              [153.18, -25.95],
              [153.03, -27.18],
              [153.63, -28.67],
              [153.05, -31.04],
              [150.16, -35.94],
              [149.97, -37.52],
              [147.76, -37.98],
              [146.22, -38.71],
              [146.39, -39.15],
              [145.45, -38.23],
              [144.9, -38.5],
              [144.93, -37.87],
              [143.54, -38.86],
              [141.57, -38.42],
              [139.81, -37.3],
              [139.82, -36.55],
              [139.08, -35.68],
              [139.67, -36.23],
              [139.1, -35.62],
              [139.36, -35.37],
              [138.09, -35.62],
              [138.51, -35.03],
              [138.1, -34.14],
              [137.74, -35.14],
              [136.83, -35.25],
              [137.44, -34.93],
              [137.95, -33.56],
              [137.77, -32.52],
              [135.95, -35.01],
              [135.11, -34.6],
              [135.51, -34.62],
              [134.18, -32.49],
              [131.15, -31.47],
              [125.97, -32.27],
              [124.28, -32.99],
              [123.54, -33.91],
              [120, -33.93],
              [117.93, -35.13],
              [115.01, -34.26],
              [114.99, -33.52],
              [115.71, -33.27],
              [115.74, -31.87],
              [114.89, -29.2],
              [113.22, -26.24],
              [113.86, -26.51],
              [113.47, -25.54],
              [114.22, -26.31],
              [113.39, -24.43],
              [114.02, -21.85],
              [114.15, -22.53],
              [116.71, -20.65],
              [121.03, -19.59],
              [122.35, -18.11],
              [122.17, -17.24],
              [122.92, -16.42],
              [123.58, -17.6],
              [123.6, -16.99],
              [123.92, -17.2],
              [123.57, -16.17],
              [123.73, -16.14],
              [123.89, -16.38],
              [123.96, -16.25],
              [124.24, -16.41],
              [124.9, -16.42],
              [124.4, -16.33],
              [124.43, -16.1],
              [124.59, -16.11],
              [124.73, -15.81],
              [124.4, -15.87],
              [124.45, -15.49],
              [125.18, -15.52],
              [124.83, -15.16],
              [125.43, -15.14],
              [125.14, -14.74],
              [125.61, -14.22],
              [125.64, -14.64],
              [126.04, -14.52],
              [126.02, -13.92],
              [126.29, -14.23],
              [126.86, -13.75],
              [127.42, -13.95],
              [128.17, -14.7],
              [128.01, -15.5],
              [128.53, -14.76],
              [129.73, -15.2],
              [129.94, -14.77],
              [129.37, -14.33],
              [130.58, -12.4],
              [132.75, -12.13],
              [131.98, -11.13],
              [135.23, -12.29],
              [135.91, -11.76],
              [135.67, -12.2],
              [136.04, -12.47],
              [136.56, -11.93],
              [136.98, -12.36],
              [136.46, -13.25],
              [135.92, -13.28],
              [135.46, -14.94],
              [139.26, -17.34],
              [140.49, -17.64],
              [141.43, -16.08],
              [141.58, -12.99],
              [141.94, -12.88],
              [141.59, -12.55],
              [142.51, -10.87]
            ]
          ]
        ]
      },
      "properties": { "name": "Australia" },
      "id": "AU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [13.83, 48.77],
              [14.7, 48.58],
              [15.03, 49.02],
              [16.95, 48.62],
              [17.17, 48.01],
              [16.45, 47.7],
              [16.51, 47.01],
              [16.11, 46.87],
              [13.72, 46.53],
              [12.13, 47],
              [10.47, 46.87],
              [9.6, 47.06],
              [9.53, 47.27],
              [9.57, 47.54],
              [13.02, 47.47],
              [12.76, 48.12],
              [13.83, 48.77]
            ]
          ]
        ]
      },
      "properties": { "name": "Austria" },
      "id": "AT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-73.04, 21.14],
              [-73.69, 20.94],
              [-73.02, 21.33],
              [-73.04, 21.14]
            ]
          ],
          [
            [
              [-77.74, 24.03],
              [-77.68, 24.3],
              [-77.57, 23.74],
              [-77.74, 24.03]
            ]
          ],
          [
            [
              [-78.19, 25.2],
              [-77.72, 24.51],
              [-78.02, 24.27],
              [-78.44, 24.61],
              [-78.19, 25.2]
            ]
          ],
          [
            [
              [-77.92, 26.75],
              [-78.71, 26.49],
              [-78.98, 26.7],
              [-77.92, 26.75]
            ]
          ],
          [
            [
              [-77.74, 26.91],
              [-77.04, 26.51],
              [-77.2, 25.88],
              [-77.15, 26.55],
              [-77.74, 26.91]
            ]
          ]
        ]
      },
      "properties": { "name": "Bahamas, The" },
      "id": "BS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [50.53, 26.23],
              [50.57, 25.81],
              [50.46, 25.97],
              [50.53, 26.23]
            ]
          ]
        ]
      },
      "properties": { "name": "Bahrain" },
      "id": "BH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [90.76, 22.06],
              [90.6, 22.78],
              [90.88, 22.46],
              [90.76, 22.06]
            ]
          ],
          [
            [
              [88.43, 26.55],
              [89.74, 26.16],
              [89.85, 25.29],
              [92.41, 25.03],
              [91.16, 23.64],
              [91.61, 22.94],
              [92.28, 23.71],
              [92.6, 21.98],
              [92.26, 21.05],
              [92.32, 20.74],
              [91.7, 22.49],
              [90.83, 22.69],
              [90.59, 23.6],
              [90.27, 21.85],
              [90.02, 21.86],
              [90.07, 22.16],
              [89.93, 22],
              [90, 22.48],
              [89.58, 21.7],
              [89.62, 22.32],
              [89.25, 21.64],
              [89.06, 22.12],
              [88.75, 24.22],
              [88.04, 24.68],
              [89.01, 25.29],
              [88.11, 25.84],
              [88.43, 26.55]
            ],
            [
              [90.24, 22.19],
              [90.06, 21.99],
              [90.22, 22.11],
              [90.24, 22.19]
            ]
          ]
        ]
      },
      "properties": { "name": "Bangladesh" },
      "id": "BD"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [45.15, 41.2],
              [46, 40.23],
              [45.6, 39.98],
              [46.54, 39.56],
              [46.54, 38.88],
              [46.18, 38.84],
              [45.82, 39.55],
              [45.08, 39.77],
              [44.78, 39.71],
              [43.66, 40.11],
              [43.46, 41.11],
              [45.02, 41.3],
              [45.15, 41.2]
            ],
            [
              [45.57, 40.63],
              [45.52, 40.67],
              [45.51, 40.61],
              [45.57, 40.63]
            ]
          ]
        ]
      },
      "properties": { "name": "Armenia" },
      "id": "AM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-59.53, 13.05],
              [-59.64, 13.33],
              [-59.43, 13.16],
              [-59.53, 13.05]
            ]
          ]
        ]
      },
      "properties": { "name": "Barbados" },
      "id": "BB"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [4.3, 51.26],
              [4.25, 51.38],
              [5.04, 51.49],
              [6.01, 50.76],
              [6.13, 50.13],
              [6.03, 50.18],
              [5.81, 49.55],
              [4.83, 50.17],
              [4.15, 49.98],
              [2.54, 51.09],
              [3.37, 51.37],
              [4.24, 51.35],
              [4.3, 51.26]
            ]
          ]
        ]
      },
      "properties": { "name": "Belgium" },
      "id": "BE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-64.78, 32.27],
              [-64.86, 32.27],
              [-64.67, 32.38],
              [-64.78, 32.27]
            ]
          ]
        ]
      },
      "properties": { "name": "Bermuda" },
      "id": "BM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [90.47, 28.07],
              [91.66, 27.76],
              [92.07, 26.86],
              [89.64, 26.72],
              [88.92, 27.32],
              [89.59, 28.14],
              [90.47, 28.07]
            ]
          ]
        ]
      },
      "properties": { "name": "Bhutan" },
      "id": "BT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-65.19, -22.09],
              [-65.75, -22.11],
              [-66.22, -21.78],
              [-67.18, -22.82],
              [-67.88, -22.83],
              [-68.76, -20.41],
              [-68.44, -19.43],
              [-69.48, -17.64],
              [-69.5, -17.51],
              [-68.82, -16.34],
              [-69.42, -15.62],
              [-68.67, -12.5],
              [-69.57, -10.95],
              [-68.58, -11.11],
              [-66.63, -9.91],
              [-65.38, -9.7],
              [-64.99, -12.01],
              [-60.47, -13.81],
              [-60.16, -16.26],
              [-58.33, -16.28],
              [-58.4, -17.25],
              [-57.52, -18.2],
              [-58.16, -20.17],
              [-59.1, -19.35],
              [-61.74, -19.65],
              [-62.64, -22.24],
              [-63.94, -22],
              [-64.32, -22.87],
              [-64.59, -22.21],
              [-65.19, -22.09]
            ]
          ]
        ]
      },
      "properties": { "name": "Bolivia" },
      "id": "BO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [17.65, 42.89],
              [17.58, 42.94],
              [16.14, 44.2],
              [15.79, 45.17],
              [19.04, 44.86],
              [19.62, 44.05],
              [19.23, 43.51],
              [18.46, 42.57],
              [17.65, 42.89]
            ]
          ]
        ]
      },
      "properties": { "name": "Bosnia and Herzegovina" },
      "id": "BA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [26.96, -23.75],
              [25.51, -25.68],
              [23.01, -25.3],
              [21.67, -26.86],
              [20.64, -26.83],
              [20.81, -25.88],
              [20, -24.77],
              [20, -22.01],
              [20.99, -22],
              [20.99, -18.32],
              [23.3, -18],
              [23.62, -18.49],
              [25.26, -17.8],
              [26.17, -19.53],
              [27.71, -20.51],
              [28.02, -21.57],
              [29.37, -22.19],
              [26.96, -23.75]
            ]
          ]
        ]
      },
      "properties": { "name": "Botswana" },
      "id": "BW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [3.36, -54.46],
              [3.37, -54.4],
              [3.48, -54.4],
              [3.36, -54.46]
            ]
          ]
        ]
      },
      "properties": { "name": "Bouvet Island" },
      "id": "BV"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-51.9, -1.48],
              [-51.89, -1.18],
              [-51.66, -1.08],
              [-51.61, -0.73],
              [-51.2, -0.53],
              [-51.27, -1.01],
              [-51.9, -1.48]
            ]
          ],
          [
            [
              [-49.71, -0.23],
              [-48.37, -0.29],
              [-48.63, -1.06],
              [-49.17, -1.61],
              [-50.58, -1.8],
              [-50.66, -0.28],
              [-49.71, -0.23]
            ]
          ],
          [
            [
              [-60.1, 5.22],
              [-60.15, 4.52],
              [-59.57, 3.9],
              [-59.99, 2.69],
              [-59.64, 1.73],
              [-58.81, 1.19],
              [-56.47, 1.94],
              [-55.9, 1.89],
              [-55.97, 2.53],
              [-54.6, 2.33],
              [-52.91, 2.2],
              [-51.68, 4.03],
              [-51.54, 4.39],
              [-51.09, 3.91],
              [-50.68, 2.16],
              [-49.93, 1.71],
              [-49.9, 1.17],
              [-51.26, -0.14],
              [-51.7, -0.75],
              [-51.71, -1.03],
              [-51.92, -1.17],
              [-51.94, -1.34],
              [-52.71, -1.6],
              [-52.21, -1.69],
              [-50.85, -0.92],
              [-50.66, -1.77],
              [-51.34, -1.65],
              [-51.45, -2.27],
              [-51.31, -1.76],
              [-50.85, -2.51],
              [-50.68, -1.81],
              [-49.29, -1.71],
              [-49.49, -2.56],
              [-48.7, -1.47],
              [-48.18, -1.47],
              [-48.5, -1.46],
              [-48.24, -0.87],
              [-47.43, -0.58],
              [-45.33, -1.31],
              [-45.35, -1.74],
              [-44.86, -1.43],
              [-44.36, -2.34],
              [-44.79, -3.3],
              [-44.06, -2.41],
              [-44.34, -2.83],
              [-43.35, -2.37],
              [-41.25, -3.02],
              [-40, -2.85],
              [-37.17, -4.92],
              [-35.42, -5.21],
              [-34.8, -7.63],
              [-35.29, -9.18],
              [-37.15, -10.75],
              [-38.04, -12.63],
              [-38.49, -13.02],
              [-38.9, -12.71],
              [-39.13, -17.68],
              [-40.97, -21.99],
              [-42.03, -22.92],
              [-43.08, -22.67],
              [-44.66, -23.05],
              [-44.58, -23.36],
              [-46.38, -23.87],
              [-48.21, -25.46],
              [-48.72, -25.42],
              [-48.36, -25.58],
              [-48.8, -26.07],
              [-48.49, -27.21],
              [-48.77, -28.52],
              [-52.07, -32.17],
              [-50.57, -30.46],
              [-51.28, -30.01],
              [-51.27, -30.8],
              [-53.37, -33.74],
              [-53.52, -33.15],
              [-53.09, -32.73],
              [-53.88, -31.97],
              [-55.58, -30.85],
              [-56.01, -31.08],
              [-56.81, -30.11],
              [-57.61, -30.18],
              [-55.77, -28.23],
              [-53.81, -27.13],
              [-53.86, -25.68],
              [-54.6, -25.57],
              [-54.33, -24.68],
              [-54.41, -23.92],
              [-55.41, -23.96],
              [-55.85, -22.29],
              [-57.99, -22.09],
              [-58.16, -20.17],
              [-57.52, -18.2],
              [-58.4, -17.25],
              [-58.33, -16.28],
              [-60.16, -16.26],
              [-60.47, -13.81],
              [-64.99, -12.01],
              [-65.38, -9.7],
              [-66.63, -9.91],
              [-68.58, -11.11],
              [-69.57, -10.95],
              [-70.63, -11.01],
              [-70.51, -9.43],
              [-72.14, -10],
              [-72.37, -9.49],
              [-73.21, -9.41],
              [-72.96, -8.98],
              [-74.01, -7.54],
              [-73.12, -6.45],
              [-72.85, -5.12],
              [-70.77, -4.15],
              [-69.96, -4.24],
              [-69.38, -1.34],
              [-70.04, 0.59],
              [-69.12, 0.65],
              [-69.84, 1.07],
              [-69.85, 1.71],
              [-67.91, 1.75],
              [-67.42, 2.14],
              [-66.87, 1.22],
              [-65.52, 0.65],
              [-63.39, 2.15],
              [-64.05, 2.48],
              [-64.8, 4.28],
              [-62.88, 3.56],
              [-62.75, 4.03],
              [-60.99, 4.52],
              [-60.73, 5.2],
              [-60.1, 5.22]
            ]
          ]
        ]
      },
      "properties": { "name": "Brazil" },
      "id": "BR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-88.38, 18.48],
              [-88.3, 18.48],
              [-88.21, 16.97],
              [-88.91, 15.89],
              [-89.22, 15.89],
              [-89.14, 17.82],
              [-88.38, 18.48]
            ]
          ]
        ]
      },
      "properties": { "name": "Belize" },
      "id": "BZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [72.49, -7.38],
              [72.36, -7.27],
              [72.45, -7.23],
              [72.49, -7.38]
            ]
          ]
        ]
      },
      "properties": { "name": "British Indian Ocean Territory" },
      "id": "IO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [161.55, -10.28],
              [162.39, -10.84],
              [161.28, -10.33],
              [161.55, -10.28]
            ]
          ],
          [
            [
              [159.94, -9.43],
              [160.83, -9.86],
              [159.83, -9.8],
              [159.6, -9.32],
              [159.94, -9.43]
            ]
          ],
          [
            [
              [160.97, -8.85],
              [161.38, -9.64],
              [160.58, -8.33],
              [160.97, -8.85]
            ]
          ],
          [
            [
              [157.63, -8.24],
              [157.81, -8.62],
              [157.21, -8.24],
              [157.63, -8.24]
            ]
          ],
          [
            [
              [159.85, -8.33],
              [159.89, -8.57],
              [158.49, -7.55],
              [159.85, -8.33]
            ]
          ],
          [
            [
              [157.43, -7.32],
              [156.94, -7.22],
              [156.44, -6.64],
              [157.43, -7.32]
            ]
          ]
        ]
      },
      "properties": { "name": "Solomon Islands" },
      "id": "SB"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-64.42, 18.44],
              [-64.41, 18.51],
              [-64.32, 18.51],
              [-64.42, 18.44]
            ]
          ]
        ]
      },
      "properties": { "name": "British Virgin Islands" },
      "id": "VG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [115.22, 4.8],
              [115.34, 4.31],
              [115.03, 4.82],
              [115.15, 4.9],
              [115.22, 4.8]
            ]
          ],
          [
            [
              [114.98, 4.89],
              [115.02, 4.9],
              [114.64, 4.02],
              [114.1, 4.59],
              [114.98, 4.89]
            ]
          ]
        ]
      },
      "properties": { "name": "Brunei" },
      "id": "BN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [27.88, 42.84],
              [27.45, 42.47],
              [28.01, 41.98],
              [27.39, 42.01],
              [26.36, 41.71],
              [26.29, 41.71],
              [25.29, 41.24],
              [22.94, 41.34],
              [22.37, 42.32],
              [23, 43.19],
              [22.37, 43.83],
              [22.68, 44.22],
              [24.18, 43.68],
              [27.04, 44.15],
              [28.58, 43.75],
              [27.88, 42.84]
            ]
          ]
        ]
      },
      "properties": { "name": "Bulgaria" },
      "id": "BG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [97.81, 28.34],
              [98.7, 27.54],
              [98.78, 26.64],
              [98.71, 25.86],
              [97.55, 24.74],
              [97.54, 23.94],
              [98.89, 24.16],
              [98.93, 23.19],
              [99.57, 22.94],
              [99.16, 22.16],
              [99.96, 22.05],
              [100.21, 21.43],
              [101.15, 21.57],
              [100.09, 20.35],
              [100.08, 20.35],
              [98.05, 19.81],
              [97.77, 18.57],
              [97.35, 18.56],
              [98.93, 16.39],
              [98.2, 15.07],
              [99.17, 13.73],
              [99.66, 11.83],
              [98.74, 10.35],
              [98.55, 9.98],
              [98.89, 11.7],
              [97.8, 14.88],
              [97.74, 16.56],
              [97.38, 16.49],
              [96.88, 17.45],
              [96.78, 16.7],
              [96.24, 16.8],
              [95.43, 15.73],
              [95.36, 16.14],
              [94.85, 15.78],
              [94.99, 16.25],
              [94.65, 15.85],
              [94.63, 16.34],
              [94.25, 15.96],
              [94.61, 17.55],
              [93.99, 19.46],
              [93.73, 19.93],
              [93.13, 19.84],
              [93.08, 20.55],
              [92.86, 20.12],
              [92.26, 21.05],
              [92.6, 21.98],
              [93.2, 22.26],
              [93.34, 24.08],
              [94.15, 23.86],
              [95.14, 26.61],
              [96.19, 27.27],
              [97.14, 27.09],
              [96.89, 27.61],
              [97.35, 28.22],
              [97.81, 28.34]
            ]
          ]
        ]
      },
      "properties": { "name": "Burma" },
      "id": "MM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [29.23, -3.75],
              [29.02, -2.74],
              [29.85, -2.76],
              [29.95, -2.31],
              [30.57, -2.4],
              [30.83, -3.26],
              [29.42, -4.45],
              [29.23, -3.75]
            ]
          ]
        ]
      },
      "properties": { "name": "Burundi" },
      "id": "BI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [26.61, 55.67],
              [28.17, 56.15],
              [30.93, 55.6],
              [30.78, 54.79],
              [32.74, 53.46],
              [31.27, 53.02],
              [31.78, 52.11],
              [30.94, 52.07],
              [30.55, 51.25],
              [25.78, 51.94],
              [23.6, 51.53],
              [23.64, 52.08],
              [23.17, 52.28],
              [23.94, 52.73],
              [23.5, 53.95],
              [25.79, 54.16],
              [25.79, 54.87],
              [26.82, 55.28],
              [26.61, 55.67]
            ]
          ]
        ]
      },
      "properties": { "name": "Belarus" },
      "id": "BY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [107.49, 14.45],
              [107.55, 12.35],
              [105.85, 11.66],
              [106.2, 10.77],
              [105.1, 10.96],
              [104.45, 10.42],
              [103.62, 10.5],
              [103.56, 11.16],
              [103.13, 10.88],
              [102.92, 11.64],
              [102.38, 13.57],
              [103.18, 14.33],
              [105.21, 14.35],
              [106.06, 13.93],
              [106, 14.37],
              [107.55, 14.71],
              [107.49, 14.45]
            ]
          ]
        ]
      },
      "properties": { "name": "Cambodia" },
      "id": "KH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [14.58, 12.74],
              [15.68, 9.99],
              [13.96, 9.64],
              [15.2, 8.49],
              [15.5, 7.53],
              [14.42, 6.04],
              [14.73, 4.62],
              [16.21, 2.22],
              [16.07, 1.65],
              [13.29, 2.16],
              [12.52, 2.28],
              [11.34, 2.17],
              [10.03, 2.17],
              [9.81, 2.34],
              [9.72, 3.87],
              [8.98, 4.1],
              [8.59, 4.81],
              [9.8, 6.8],
              [10.62, 7.07],
              [11.34, 6.44],
              [11.86, 7.08],
              [13.81, 11.06],
              [14.65, 11.58],
              [14.07, 13.08],
              [14.5, 13],
              [14.58, 12.74]
            ]
          ]
        ]
      },
      "properties": { "name": "Cameroon" },
      "id": "CM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-127.23, 50.64],
              [-125.45, 50.32],
              [-123.29, 48.41],
              [-125.11, 48.73],
              [-124.81, 49.24],
              [-125.48, 48.92],
              [-125.9, 49.44],
              [-126.54, 49.37],
              [-126.09, 49.66],
              [-127.9, 50.11],
              [-127.41, 50.59],
              [-128.42, 50.77],
              [-127.23, 50.64]
            ]
          ],
          [
            [
              [-53.76, 48.5],
              [-52.98, 48.6],
              [-53.95, 48.18],
              [-53.55, 47.53],
              [-52.83, 48.1],
              [-53.27, 47.61],
              [-52.61, 47.52],
              [-53.1, 46.64],
              [-53.62, 46.64],
              [-53.59, 47.16],
              [-54.19, 46.82],
              [-53.87, 47.4],
              [-54.2, 47.86],
              [-55.69, 46.86],
              [-55.98, 46.95],
              [-54.94, 47.78],
              [-55.59, 47.4],
              [-56.17, 47.5],
              [-55.77, 47.96],
              [-56.84, 47.52],
              [-59.41, 47.89],
              [-58.42, 48.49],
              [-59.26, 48.48],
              [-58.4, 49.13],
              [-57.88, 48.97],
              [-58.22, 49.39],
              [-57.7, 49.46],
              [-57.38, 50.69],
              [-55.9, 51.63],
              [-55.41, 51.56],
              [-56.09, 51.37],
              [-55.73, 51.08],
              [-56.85, 49.54],
              [-56.15, 50.15],
              [-55.49, 50.01],
              [-56.13, 49.43],
              [-55.14, 49.55],
              [-55.38, 49.04],
              [-53.78, 49.4],
              [-53.49, 49.22],
              [-54.1, 48.81],
              [-53.76, 48.5]
            ],
            [
              [-53.76, 48.5],
              [-54.09, 48.43],
              [-54.14, 48.36],
              [-53.76, 48.5]
            ]
          ],
          [
            [
              [-85.48, 65.79],
              [-84.92, 65.21],
              [-84.44, 65.46],
              [-81.76, 64.5],
              [-81.99, 63.99],
              [-80.17, 63.77],
              [-81.08, 63.45],
              [-83.07, 64.19],
              [-85.27, 63.12],
              [-85.72, 63.72],
              [-87.19, 63.59],
              [-86.19, 64.1],
              [-86.1, 65.53],
              [-85.48, 65.79]
            ]
          ],
          [
            [
              [-95.9, 66.95],
              [-95.22, 66.97],
              [-95.71, 67.73],
              [-95.47, 68.06],
              [-93.55, 68.59],
              [-93.67, 68.97],
              [-94.63, 68.76],
              [-94.3, 69.3],
              [-93.36, 69.37],
              [-96.53, 70.13],
              [-95.79, 70.54],
              [-96.61, 70.79],
              [-96.5, 71.28],
              [-94.61, 71.86],
              [-95.22, 71.94],
              [-93.71, 71.76],
              [-92.97, 71.34],
              [-93.03, 70.85],
              [-91.51, 70.17],
              [-92.92, 69.67],
              [-90.31, 69.45],
              [-91.45, 69.35],
              [-90.44, 68.87],
              [-90.27, 68.24],
              [-89.31, 69.25],
              [-88.05, 68.82],
              [-87.79, 68.33],
              [-88.37, 67.96],
              [-87.51, 67.11],
              [-86.52, 67.35],
              [-85.66, 68.73],
              [-84.53, 69.02],
              [-85.47, 69.27],
              [-85.57, 69.86],
              [-82.26, 69.64],
              [-83.23, 69.54],
              [-81.33, 69.18],
              [-82.06, 68.87],
              [-81.27, 68.63],
              [-82.64, 68.5],
              [-81.24, 67.47],
              [-81.5, 67],
              [-83.4, 66.35],
              [-83.92, 66.88],
              [-84.27, 66.72],
              [-84.37, 66.97],
              [-84.92, 67.06],
              [-84.64, 66.98],
              [-85.23, 66.88],
              [-84.6, 66.94],
              [-83.69, 66.19],
              [-86.78, 66.53],
              [-85.9, 66.17],
              [-87.4, 65.32],
              [-91.43, 65.95],
              [-86.93, 65.14],
              [-88.11, 64.14],
              [-90.12, 64.13],
              [-89.81, 63.94],
              [-90.28, 64],
              [-90.21, 63.61],
              [-92.48, 63.81],
              [-90.63, 63.06],
              [-92.42, 62.83],
              [-91.89, 62.6],
              [-93.62, 61.94],
              [-93.24, 61.78],
              [-93.98, 61.46],
              [-94.82, 59.64],
              [-94.23, 58.78],
              [-94.36, 58.22],
              [-94.14, 58.76],
              [-93.15, 58.74],
              [-92.42, 57.34],
              [-92.88, 56.91],
              [-90.82, 57.26],
              [-85.71, 55.63],
              [-85.12, 55.34],
              [-85.42, 54.99],
              [-82.31, 55.15],
              [-82.3, 53.02],
              [-81.55, 52.44],
              [-81.88, 52.19],
              [-80.44, 51.46],
              [-81.02, 51.03],
              [-80.12, 51.3],
              [-79.33, 50.72],
              [-79.75, 51.18],
              [-79.32, 51.66],
              [-78.85, 51.17],
              [-79.04, 51.77],
              [-78.51, 52.46],
              [-79.05, 54.18],
              [-79.76, 54.65],
              [-77.75, 55.3],
              [-76.53, 56.32],
              [-76.86, 57.72],
              [-78.57, 58.64],
              [-77.77, 59.71],
              [-77.31, 59.56],
              [-77.43, 59.91],
              [-76.76, 60.16],
              [-77.59, 60.06],
              [-77.41, 60.54],
              [-77.83, 60.64],
              [-77.51, 60.84],
              [-78.19, 60.79],
              [-77.47, 61.54],
              [-78.16, 62.28],
              [-77.51, 62.56],
              [-74.57, 62.1],
              [-73.68, 62.48],
              [-72.01, 61.68],
              [-72.3, 61.57],
              [-71.58, 61.61],
              [-71.89, 61.43],
              [-71.39, 61.14],
              [-69.52, 61.07],
              [-69.62, 60.07],
              [-70.95, 60.06],
              [-69.6, 59.83],
              [-69.76, 59.32],
              [-69.23, 59.23],
              [-70.15, 58.78],
              [-69.82, 58.59],
              [-68.36, 58.78],
              [-68.34, 58.13],
              [-69.37, 57.77],
              [-68.4, 58.04],
              [-68, 58.58],
              [-68.13, 58.07],
              [-67.72, 58.46],
              [-67.71, 57.92],
              [-66.39, 58.85],
              [-66.06, 58.32],
              [-65.99, 58.9],
              [-65.32, 59.04],
              [-65.56, 59.49],
              [-64.98, 59.38],
              [-65.53, 59.72],
              [-64.47, 60.28],
              [-64.83, 59.99],
              [-64.17, 60.02],
              [-63.72, 59.51],
              [-64.06, 59.38],
              [-63.36, 59.2],
              [-64.04, 59.02],
              [-62.85, 58.69],
              [-63.59, 58.3],
              [-62.56, 58.48],
              [-63.34, 57.98],
              [-62.45, 58.18],
              [-62.67, 57.93],
              [-61.88, 57.63],
              [-62.55, 57.5],
              [-61.36, 57.09],
              [-61.67, 56.62],
              [-62.57, 56.79],
              [-60.67, 55.59],
              [-60.33, 55.78],
              [-60.68, 54.99],
              [-59.78, 55.33],
              [-59.43, 55.14],
              [-59.94, 54.75],
              [-59.16, 55.24],
              [-59.39, 54.98],
              [-57.35, 54.58],
              [-60.13, 53.53],
              [-60.86, 53.79],
              [-60.1, 53.5],
              [-60.42, 53.27],
              [-57.79, 54.07],
              [-58.18, 54.24],
              [-57.38, 54.15],
              [-57.34, 53.44],
              [-56.46, 53.78],
              [-55.81, 53.34],
              [-56.17, 53.03],
              [-55.76, 52.61],
              [-56.5, 52.59],
              [-55.65, 52.44],
              [-56.2, 52.44],
              [-55.7, 52.08],
              [-56.95, 51.42],
              [-58.63, 51.28],
              [-60.01, 50.25],
              [-66.47, 50.26],
              [-67.38, 49.33],
              [-69.06, 48.77],
              [-71.3, 46.74],
              [-68.21, 48.64],
              [-65, 49.22],
              [-64.22, 48.9],
              [-64.55, 48.88],
              [-64.25, 48.49],
              [-66.84, 47.99],
              [-64.8, 47.81],
              [-65.37, 47.09],
              [-64.8, 47.08],
              [-64.5, 46.24],
              [-62.46, 45.61],
              [-61.92, 45.89],
              [-60.96, 45.31],
              [-64.2, 44.58],
              [-65.48, 43.46],
              [-66.17, 43.86],
              [-65.84, 44.58],
              [-66.19, 44.42],
              [-64.49, 45.34],
              [-64.16, 44.98],
              [-63.36, 45.36],
              [-64.94, 45.33],
              [-64.28, 45.8],
              [-64.75, 46.09],
              [-64.78, 45.61],
              [-67.21, 45.18],
              [-67.8, 45.7],
              [-67.79, 47.07],
              [-69.23, 47.47],
              [-70.88, 45.24],
              [-74.99, 44.99],
              [-76.8, 43.63],
              [-78.72, 43.63],
              [-79.18, 43.47],
              [-78.99, 42.82],
              [-82.7, 41.68],
              [-83.17, 42.05],
              [-82.13, 43.59],
              [-82.54, 45.36],
              [-84.13, 46.53],
              [-88.37, 48.31],
              [-91.42, 48.04],
              [-94.61, 48.72],
              [-95.08, 49.36],
              [-95.15, 49],
              [-122.76, 49],
              [-123.21, 49.13],
              [-122.85, 49.44],
              [-123.24, 49.34],
              [-123.16, 49.7],
              [-123.96, 49.51],
              [-123.53, 49.7],
              [-123.83, 50.16],
              [-123.98, 49.8],
              [-124.77, 49.99],
              [-124.35, 50.5],
              [-125.08, 50.32],
              [-124.81, 50.92],
              [-125.71, 50.43],
              [-126.27, 50.63],
              [-125.62, 50.75],
              [-125.63, 51.1],
              [-126.13, 50.68],
              [-126.56, 50.84],
              [-126.18, 50.95],
              [-127.18, 50.93],
              [-127.03, 50.82],
              [-127.54, 51.01],
              [-126.66, 51.19],
              [-127.79, 51.17],
              [-126.62, 51.68],
              [-127.88, 51.67],
              [-127.17, 52.31],
              [-126.67, 51.98],
              [-126.73, 52.37],
              [-127.19, 52.38],
              [-127.02, 52.85],
              [-128.39, 52.29],
              [-128.13, 52.88],
              [-128.97, 53.55],
              [-127.87, 53.24],
              [-128.81, 53.62],
              [-128.6, 54.03],
              [-129.27, 53.38],
              [-130.05, 53.89],
              [-129.47, 54.24],
              [-130.48, 54.36],
              [-129.96, 54.32],
              [-130.37, 54.65],
              [-129.91, 54.61],
              [-130.17, 54.85],
              [-129.47, 55.47],
              [-130.11, 55],
              [-130.16, 55.09],
              [-129.94, 55.28],
              [-130.1, 55.56],
              [-130.13, 55.72],
              [-130.02, 55.91],
              [-131.82, 56.6],
              [-133.43, 58.46],
              [-135.47, 59.8],
              [-137.48, 58.91],
              [-139.07, 60.35],
              [-141, 60.31],
              [-141, 69.64],
              [-134.31, 68.68],
              [-134.55, 69.09],
              [-132.89, 69.65],
              [-129.4, 70.12],
              [-133.49, 68.83],
              [-132.92, 68.69],
              [-133.35, 68.83],
              [-127.52, 70.22],
              [-128, 70.59],
              [-125.43, 69.31],
              [-124.82, 69.71],
              [-125.2, 70],
              [-124.44, 70.15],
              [-124.5, 69.73],
              [-124.04, 69.7],
              [-124.45, 69.37],
              [-121.68, 69.79],
              [-114.07, 68.48],
              [-115.54, 67.92],
              [-115.1, 67.8],
              [-110.08, 68.01],
              [-108.02, 67.29],
              [-108.62, 67.15],
              [-107.25, 66.35],
              [-107.75, 66.92],
              [-107.08, 66.82],
              [-107.89, 68.08],
              [-106.43, 68.15],
              [-106.47, 68.34],
              [-105.74, 68.42],
              [-105.64, 68.63],
              [-106.54, 68.51],
              [-106.54, 68.29],
              [-107.89, 68.27],
              [-107.6, 68.17],
              [-108.82, 68.27],
              [-106.23, 68.94],
              [-105.49, 68.73],
              [-105.54, 68.41],
              [-104.61, 68.24],
              [-104.5, 68.03],
              [-102.25, 67.73],
              [-98.35, 67.8],
              [-98.62, 68.07],
              [-97.51, 67.6],
              [-97.12, 67.79],
              [-98.71, 68.37],
              [-95.98, 68.25],
              [-96.46, 67.48],
              [-95.33, 67.03],
              [-95.9, 66.95]
            ],
            [
              [-95.9, 66.95],
              [-96.46, 67.06],
              [-95.63, 66.68],
              [-95.9, 66.95]
            ],
            [
              [-93.52, 63.84],
              [-92.51, 63.82],
              [-93.78, 64.19],
              [-93.52, 63.84]
            ],
            [
              [-70.78, 48.38],
              [-69.83, 48.17],
              [-71.05, 48.45],
              [-70.78, 48.38]
            ]
          ],
          [
            [
              [-114, 72.8],
              [-114.6, 72.6],
              [-113.03, 73.01],
              [-111.22, 72.72],
              [-111.66, 72.28],
              [-109.78, 72.43],
              [-110.76, 72.97],
              [-109.66, 72.92],
              [-108.62, 72.55],
              [-107.83, 71.6],
              [-107.25, 71.9],
              [-108.29, 73.15],
              [-106.76, 73.29],
              [-105.33, 72.75],
              [-104.36, 71.57],
              [-104.59, 71.07],
              [-101, 70.17],
              [-100.87, 69.79],
              [-103.48, 69.69],
              [-103.02, 69.49],
              [-103.19, 69.11],
              [-102.31, 69.5],
              [-101.75, 69.18],
              [-102.89, 68.8],
              [-105.14, 68.9],
              [-106.6, 69.5],
              [-109.1, 68.71],
              [-113.27, 68.45],
              [-113.52, 69.18],
              [-116.53, 69.41],
              [-117.44, 69.99],
              [-111.49, 70.34],
              [-117.56, 70.6],
              [-118.42, 70.99],
              [-115.06, 71.52],
              [-118.11, 71.37],
              [-117.7, 71.67],
              [-119.13, 71.77],
              [-117.35, 72.92],
              [-114.56, 73.38],
              [-114, 72.8]
            ]
          ],
          [
            [
              [-73.35, 68.33],
              [-74.82, 69.08],
              [-76.66, 68.7],
              [-75.59, 69.22],
              [-77.2, 69.65],
              [-76.98, 69.94],
              [-77.63, 69.74],
              [-77.68, 70.19],
              [-79.01, 70.68],
              [-79.59, 70.4],
              [-78.79, 69.89],
              [-81.76, 70.12],
              [-80.95, 69.71],
              [-82.1, 70.11],
              [-83.01, 70.3],
              [-81.71, 69.93],
              [-81.74, 69.87],
              [-82.02, 69.87],
              [-82.14, 69.78],
              [-83.07, 70.01],
              [-85.82, 70],
              [-86.55, 70.23],
              [-86.37, 70.53],
              [-87.92, 70.24],
              [-89.55, 71.09],
              [-87, 70.99],
              [-89.83, 71.33],
              [-90.05, 71.95],
              [-88.41, 73.52],
              [-85.07, 73.8],
              [-86.73, 72.72],
              [-86.24, 72.42],
              [-86.42, 72.01],
              [-84.83, 71.27],
              [-86.82, 70.99],
              [-84.8, 70.92],
              [-84.63, 71.67],
              [-86.05, 72.01],
              [-84.16, 72.02],
              [-85.69, 72.89],
              [-83.95, 72.75],
              [-85.45, 73.12],
              [-83.63, 72.98],
              [-85.19, 73.23],
              [-81.55, 73.72],
              [-80.25, 72.73],
              [-81.38, 72.24],
              [-80.52, 72.5],
              [-80.97, 71.88],
              [-79.67, 72.13],
              [-80.17, 72.32],
              [-79.8, 72.5],
              [-79.01, 72.27],
              [-79.2, 71.96],
              [-77.79, 71.79],
              [-78.87, 72.23],
              [-77, 72.13],
              [-78.56, 72.44],
              [-77.61, 72.75],
              [-75.19, 72.49],
              [-74.95, 72.25],
              [-76.35, 71.89],
              [-74.12, 71.98],
              [-75.39, 71.68],
              [-74.63, 71.66],
              [-75.08, 71.18],
              [-73.75, 71.78],
              [-74.24, 71.2],
              [-73.62, 71.58],
              [-73.38, 71.39],
              [-73.9, 71.06],
              [-73.05, 71.27],
              [-73.38, 70.98],
              [-72.54, 71.66],
              [-71.12, 71.26],
              [-72.57, 70.61],
              [-70.6, 71.05],
              [-71.8, 70.43],
              [-71.16, 70.53],
              [-71.54, 70.02],
              [-69.9, 70.88],
              [-70.5, 70.48],
              [-68.31, 70.56],
              [-70.47, 69.84],
              [-67.79, 70.26],
              [-67.13, 69.73],
              [-70.03, 69.54],
              [-66.8, 69.34],
              [-69.02, 69.35],
              [-68.08, 69.22],
              [-69.03, 68.97],
              [-68.18, 69.15],
              [-67.71, 69.02],
              [-68.56, 68.96],
              [-67.77, 68.78],
              [-69.4, 68.86],
              [-68.04, 68.68],
              [-68.9, 68.6],
              [-68.47, 68.61],
              [-68.71, 68.57],
              [-67.5, 68.54],
              [-67.61, 68.38],
              [-66.71, 68.44],
              [-67.88, 68.27],
              [-67.23, 68.36],
              [-67.01, 68.32],
              [-67.6, 68.16],
              [-67.01, 68.29],
              [-66.77, 68.24],
              [-66.95, 68.01],
              [-66.18, 68.02],
              [-66.73, 67.87],
              [-66.36, 67.82],
              [-65.91, 68.16],
              [-66.01, 67.63],
              [-64.72, 67.99],
              [-65.2, 67.65],
              [-64.51, 67.81],
              [-63.9, 67.31],
              [-64.8, 67.36],
              [-63.96, 67.27],
              [-64.69, 67],
              [-63.11, 67.33],
              [-63.77, 66.81],
              [-62.1, 67.05],
              [-61.26, 66.63],
              [-62.9, 66.33],
              [-61.95, 66.02],
              [-62.97, 66.15],
              [-62.32, 65.81],
              [-63.72, 65.68],
              [-63.32, 65.59],
              [-63.55, 64.89],
              [-65.51, 65.74],
              [-64.36, 66.35],
              [-65.92, 65.95],
              [-65.47, 66.39],
              [-66.07, 66.12],
              [-67.05, 66.64],
              [-67.74, 66.57],
              [-67.28, 66.28],
              [-67.99, 66.51],
              [-67.19, 65.91],
              [-68.85, 66.19],
              [-64.66, 64.03],
              [-64.99, 63.82],
              [-64.53, 63.25],
              [-65.3, 63.81],
              [-64.63, 62.9],
              [-65.25, 62.99],
              [-65.19, 62.56],
              [-66.64, 63.37],
              [-66.55, 62.99],
              [-67.91, 63.76],
              [-67.69, 63.37],
              [-69, 63.75],
              [-65.99, 62.24],
              [-66.07, 61.87],
              [-71.4, 63.05],
              [-72.15, 63.45],
              [-71.23, 63.6],
              [-72.32, 63.68],
              [-73.38, 64.27],
              [-73.3, 64.66],
              [-74.06, 64.33],
              [-74.64, 64.9],
              [-74.99, 64.8],
              [-74.69, 64.37],
              [-75.82, 64.61],
              [-76.67, 64.18],
              [-78.18, 64.57],
              [-77.42, 65.46],
              [-75.77, 65.22],
              [-75.37, 64.71],
              [-75.19, 65.1],
              [-75.95, 65.32],
              [-73.5, 65.47],
              [-74.47, 66.15],
              [-72.26, 67.25],
              [-73.35, 68.33]
            ],
            [
              [-73.35, 68.33],
              [-73.21, 68.38],
              [-73.32, 68.39],
              [-73.35, 68.33]
            ]
          ],
          [
            [
              [-99.8, 73.89],
              [-96.96, 73.74],
              [-98.45, 72.87],
              [-96.52, 72.71],
              [-96.3, 72.42],
              [-96.87, 72.04],
              [-96.49, 71.91],
              [-98.27, 71.9],
              [-98.04, 71.53],
              [-98.73, 71.27],
              [-102.74, 72.72],
              [-102.14, 73.09],
              [-100.41, 72.74],
              [-100.03, 72.93],
              [-100.58, 73.17],
              [-99.77, 73.21],
              [-101.62, 73.49],
              [-100.43, 73.41],
              [-101.12, 73.73],
              [-99.8, 73.89]
            ]
          ],
          [
            [
              [-92.64, 74.1],
              [-90.19, 73.9],
              [-92.1, 72.74],
              [-94.32, 72.76],
              [-93.46, 72.46],
              [-94.06, 71.98],
              [-95.21, 71.99],
              [-94.75, 72.15],
              [-95.67, 72.81],
              [-95.67, 73.72],
              [-92.64, 74.1]
            ]
          ],
          [
            [
              [-120.15, 74.27],
              [-117.42, 74.23],
              [-115.32, 73.48],
              [-119.14, 72.63],
              [-120.25, 72.26],
              [-120.54, 71.52],
              [-122.78, 71.09],
              [-126, 71.97],
              [-123.77, 73.76],
              [-124.77, 74.34],
              [-120.15, 74.27]
            ]
          ],
          [
            [
              [-94.36, 75.59],
              [-93.49, 75.26],
              [-93.47, 74.7],
              [-96.62, 74.99],
              [-94.36, 75.59]
            ]
          ],
          [
            [
              [-98.42, 76.67],
              [-97.51, 76.19],
              [-97.94, 75.74],
              [-97.28, 75.4],
              [-98.17, 75.33],
              [-97.58, 75.14],
              [-100.15, 74.99],
              [-100.78, 75.35],
              [-98.95, 75.71],
              [-102.88, 75.62],
              [-101.18, 75.78],
              [-101.91, 76.08],
              [-101.39, 76.25],
              [-102.17, 76.24],
              [-101.89, 76.44],
              [-99.89, 75.89],
              [-99.44, 75.97],
              [-100.15, 76.13],
              [-99.41, 76.16],
              [-100.98, 76.5],
              [-98.42, 76.67]
            ]
          ],
          [
            [
              [-108.65, 76.81],
              [-108.08, 76.28],
              [-108.4, 76.05],
              [-107.63, 75.99],
              [-108.02, 75.78],
              [-106.34, 76.05],
              [-105.39, 75.64],
              [-106.01, 75.05],
              [-112.75, 74.4],
              [-114.45, 74.67],
              [-110.91, 75.23],
              [-117.68, 75.25],
              [-115, 75.69],
              [-117.25, 75.6],
              [-114.82, 75.88],
              [-116.73, 75.92],
              [-114.66, 76.16],
              [-115.93, 76.29],
              [-114.9, 76.52],
              [-112.45, 76.18],
              [-111.25, 75.52],
              [-108.9, 75.48],
              [-110.06, 75.89],
              [-109.31, 76.11],
              [-110.39, 76.39],
              [-108.65, 76.81]
            ]
          ],
          [
            [
              [-95.66, 77.06],
              [-93.18, 76.74],
              [-93.55, 76.39],
              [-91.41, 76.69],
              [-89.29, 76.3],
              [-91.61, 76.26],
              [-88.95, 75.43],
              [-81.54, 75.81],
              [-79.57, 75.45],
              [-80.44, 75.04],
              [-79.33, 74.89],
              [-81.81, 74.46],
              [-83.51, 74.9],
              [-83.47, 74.58],
              [-84.29, 74.5],
              [-88.5, 74.5],
              [-88.55, 74.91],
              [-91.54, 74.65],
              [-92.49, 75.21],
              [-92.11, 75.86],
              [-93.08, 76.36],
              [-95.38, 76.23],
              [-94.8, 76.32],
              [-96.96, 76.73],
              [-95.66, 77.06]
            ]
          ],
          [
            [
              [-116.35, 77.54],
              [-115.39, 77.31],
              [-116.28, 77.18],
              [-115.9, 76.69],
              [-117.1, 76.3],
              [-118.06, 76.41],
              [-117.84, 76.82],
              [-118.97, 76.51],
              [-118.57, 76.34],
              [-119.08, 76.08],
              [-119.65, 76.3],
              [-119.48, 75.97],
              [-119.87, 75.86],
              [-123.04, 76.08],
              [-119.15, 77.33],
              [-116.35, 77.54]
            ]
          ],
          [
            [
              [-96.77, 78.68],
              [-94.89, 78.1],
              [-97.1, 77.8],
              [-97.78, 78.03],
              [-96.87, 78.13],
              [-98.41, 78.5],
              [-96.77, 78.68]
            ]
          ],
          [
            [
              [-103.59, 79.33],
              [-99.95, 78.73],
              [-98.95, 78.06],
              [-99.91, 77.78],
              [-104.47, 78.27],
              [-105.05, 78.49],
              [-103.32, 78.73],
              [-105.63, 79.16],
              [-103.59, 79.33]
            ]
          ],
          [
            [
              [-92.73, 81.31],
              [-88.78, 80.13],
              [-87.68, 80.41],
              [-88.07, 80.12],
              [-86.96, 79.9],
              [-87.46, 79.53],
              [-84.9, 79.27],
              [-87.62, 78.65],
              [-88, 78.81],
              [-87.72, 79.08],
              [-88.16, 78.99],
              [-87.91, 78.55],
              [-88.8, 78.61],
              [-88.82, 78.15],
              [-89.98, 78.61],
              [-89.45, 78.16],
              [-92.06, 78.21],
              [-92.99, 78.47],
              [-91.64, 78.55],
              [-94.29, 78.99],
              [-90.36, 79.25],
              [-95.09, 79.27],
              [-95.78, 79.43],
              [-94.28, 79.76],
              [-95.85, 79.65],
              [-96.8, 80.09],
              [-94.38, 79.98],
              [-94.75, 80.08],
              [-94.08, 80.18],
              [-96.68, 80.34],
              [-93.79, 80.53],
              [-95.53, 80.82],
              [-93.09, 81.16],
              [-94.27, 81.35],
              [-92.73, 81.31]
            ]
          ],
          [
            [
              [-70.11, 83.11],
              [-66.3, 82.93],
              [-68.64, 82.63],
              [-64.73, 82.9],
              [-61.08, 82.32],
              [-64.36, 81.73],
              [-69.29, 81.72],
              [-66.61, 81.51],
              [-70.21, 81.17],
              [-64.44, 81.48],
              [-69.43, 80.38],
              [-70.83, 80.56],
              [-70.15, 80.19],
              [-72.42, 80.21],
              [-70.5, 80.08],
              [-71.46, 79.9],
              [-71.18, 79.78],
              [-78.05, 79.35],
              [-74.44, 79.06],
              [-78.89, 79.06],
              [-74.72, 78.71],
              [-76.69, 78.51],
              [-75.06, 78.31],
              [-76.91, 78.2],
              [-75.58, 78.11],
              [-75.92, 77.96],
              [-78.26, 78],
              [-77.72, 77.61],
              [-78.69, 77.32],
              [-81.93, 77.68],
              [-81.17, 77.34],
              [-82.17, 77.29],
              [-81.83, 77.16],
              [-77.78, 76.79],
              [-81.05, 76.13],
              [-80.77, 76.42],
              [-82.73, 76.82],
              [-82.13, 76.44],
              [-89.68, 76.57],
              [-86.74, 77.17],
              [-88.07, 77.82],
              [-84.48, 77.29],
              [-84.61, 77.39],
              [-83.46, 77.35],
              [-83.84, 77.46],
              [-82.32, 78.07],
              [-83.9, 77.49],
              [-84.78, 77.52],
              [-84.43, 77.72],
              [-84.95, 77.6],
              [-85.35, 77.73],
              [-85.05, 77.83],
              [-85.4, 77.82],
              [-84.33, 77.9],
              [-85.68, 77.93],
              [-84.13, 78.17],
              [-84.97, 78.2],
              [-84.64, 78.59],
              [-85.49, 78.1],
              [-87.54, 78.14],
              [-86.86, 78.73],
              [-82.34, 78.57],
              [-83.25, 78.83],
              [-81.48, 79.05],
              [-84.75, 79.03],
              [-83.36, 79.05],
              [-86.49, 79.76],
              [-85.26, 79.92],
              [-86.51, 80.3],
              [-79.9, 79.65],
              [-83.2, 80.32],
              [-78.04, 80.57],
              [-79.96, 80.61],
              [-76.48, 80.87],
              [-78.93, 80.88],
              [-76.75, 81.44],
              [-80.92, 80.66],
              [-85.07, 80.51],
              [-86.74, 80.6],
              [-82.36, 81.18],
              [-87.59, 80.63],
              [-89.47, 80.91],
              [-84.73, 81.28],
              [-89.82, 81.01],
              [-90.35, 81.17],
              [-87.24, 81.49],
              [-91.96, 81.66],
              [-88.08, 82.1],
              [-84.6, 81.89],
              [-86.88, 82.2],
              [-85.05, 82.48],
              [-79.23, 81.82],
              [-82.73, 82.4],
              [-80.58, 82.55],
              [-81.47, 82.82],
              [-78.5, 82.68],
              [-80.43, 82.89],
              [-79.79, 82.96],
              [-75.89, 82.59],
              [-76.23, 82.44],
              [-75.4, 82.61],
              [-77.38, 82.99],
              [-72.63, 82.69],
              [-73.65, 82.93],
              [-70.11, 83.11]
            ]
          ]
        ]
      },
      "properties": { "name": "Canada" },
      "id": "CA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-24.37, 14.81],
              [-24.53, 14.92],
              [-24.38, 15.05],
              [-24.37, 14.81]
            ]
          ],
          [
            [
              [-23.45, 14.98],
              [-23.68, 14.94],
              [-23.77, 15.25],
              [-23.45, 14.98]
            ]
          ],
          [
            [
              [-22.71, 16.04],
              [-22.96, 16.09],
              [-22.8, 16.24],
              [-22.71, 16.04]
            ]
          ],
          [
            [
              [-24.03, 16.59],
              [-24.32, 16.48],
              [-24.43, 16.64],
              [-24.03, 16.59]
            ]
          ],
          [
            [
              [-25.28, 16.91],
              [-25.33, 17.1],
              [-24.97, 17.11],
              [-25.28, 16.91]
            ]
          ]
        ]
      },
      "properties": { "name": "Cape Verde" },
      "id": "CV"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-81.1, 19.31],
              [-81.4, 19.29],
              [-81.25, 19.35],
              [-81.1, 19.31]
            ]
          ]
        ]
      },
      "properties": { "name": "Cayman Islands" },
      "id": "KY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [19.06, 8.58],
              [18.99, 8.96],
              [20.37, 9.11],
              [21.72, 10.64],
              [22.87, 10.92],
              [23.67, 9.87],
              [23.52, 8.71],
              [25.25, 7.85],
              [27.46, 5.02],
              [25.89, 5.19],
              [22.9, 4.82],
              [22.38, 4.13],
              [20.59, 4.41],
              [19.42, 5.13],
              [18.54, 4.34],
              [18.62, 3.48],
              [16.66, 3.53],
              [16.21, 2.22],
              [14.73, 4.62],
              [14.42, 6.04],
              [15.5, 7.53],
              [18.59, 8.04],
              [19.06, 8.58]
            ]
          ]
        ]
      },
      "properties": { "name": "Central African Republic" },
      "id": "CF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [80.27, 9.78],
              [80.82, 9.26],
              [81.13, 8.5],
              [81.36, 8.49],
              [81.88, 7.29],
              [81.66, 6.44],
              [80.59, 5.92],
              [80.05, 6.24],
              [79.7, 8.08],
              [80.05, 9.59],
              [80.61, 9.44],
              [79.93, 9.74],
              [80.27, 9.78]
            ]
          ]
        ]
      },
      "properties": { "name": "Sri Lanka" },
      "id": "LK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [14.5, 13],
              [14.07, 13.08],
              [13.63, 13.72],
              [13.47, 14.46],
              [15.49, 16.91],
              [16, 20.35],
              [15.2, 21.5],
              [15, 23],
              [16, 23.45],
              [24, 19.5],
              [24, 15.7],
              [22.94, 15.56],
              [21.83, 12.8],
              [22.47, 12.62],
              [22.87, 10.92],
              [21.72, 10.64],
              [20.37, 9.11],
              [18.99, 8.96],
              [19.06, 8.58],
              [18.59, 8.04],
              [15.5, 7.53],
              [15.2, 8.49],
              [13.96, 9.64],
              [15.68, 9.99],
              [14.58, 12.74],
              [14.5, 13]
            ]
          ]
        ]
      },
      "properties": { "name": "Chad" },
      "id": "TD"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-68.4, -54.96],
              [-69.06, -55.06],
              [-68.21, -55.27],
              [-68.75, -55.27],
              [-68.16, -55.4],
              [-68.05, -55.71],
              [-68.38, -55.48],
              [-68.94, -55.46],
              [-68.78, -55.38],
              [-68.91, -55.34],
              [-68.8, -55.19],
              [-69.43, -55.16],
              [-69.17, -55.51],
              [-70.03, -55.16],
              [-68.4, -54.96]
            ]
          ],
          [
            [
              [-67.78, -54.92],
              [-67.05, -55.14],
              [-68.36, -54.94],
              [-67.78, -54.92]
            ]
          ],
          [
            [
              [-71.26, -54.08],
              [-71.12, -54.38],
              [-71.7, -54.16],
              [-71.26, -54.08]
            ]
          ],
          [
            [
              [-71.67, -53.94],
              [-71.85, -54.34],
              [-72.26, -53.94],
              [-71.67, -53.94]
            ]
          ],
          [
            [
              [-70.44, -53.86],
              [-70.37, -54.04],
              [-70.67, -53.93],
              [-70.52, -54.23],
              [-70.88, -54.05],
              [-70.53, -53.56],
              [-70.44, -53.86]
            ]
          ],
          [
            [
              [-72.91, -53.43],
              [-72.14, -53.8],
              [-72.87, -54.14],
              [-72.73, -53.84],
              [-73.59, -53.76],
              [-72.91, -53.43]
            ]
          ],
          [
            [
              [-74.36, -52.95],
              [-74.75, -52.76],
              [-73.09, -53.35],
              [-74.36, -52.95]
            ]
          ],
          [
            [
              [-70.92, -54.71],
              [-70.77, -54.68],
              [-70.87, -54.69],
              [-70.92, -54.71],
              [-72, -54.46],
              [-70.13, -54.55],
              [-70.88, -54.13],
              [-70.14, -54.43],
              [-70.06, -54.25],
              [-69.77, -54.56],
              [-69.86, -54.28],
              [-69.24, -54.45],
              [-69.38, -54.69],
              [-69.18, -54.58],
              [-69.16, -54.46],
              [-68.99, -54.43],
              [-70.04, -54.1],
              [-70.18, -53.81],
              [-69.36, -53.35],
              [-70.45, -53.37],
              [-70.1, -52.9],
              [-70.42, -52.77],
              [-68.62, -52.64],
              [-68.64, -54.79],
              [-70.92, -54.71]
            ]
          ],
          [
            [
              [-74.26, -51.25],
              [-74.32, -50.92],
              [-74.19, -51.2],
              [-74.26, -51.25]
            ]
          ],
          [
            [
              [-74.4, -50.86],
              [-74.41, -51.09],
              [-74.96, -50.97],
              [-74.4, -50.86]
            ]
          ],
          [
            [
              [-74.69, -50.89],
              [-74.95, -50.73],
              [-74.75, -50.7],
              [-74.69, -50.89]
            ]
          ],
          [
            [
              [-74.36, -50.49],
              [-74.19, -50.85],
              [-74.67, -50.48],
              [-74.36, -50.49]
            ]
          ],
          [
            [
              [-75.05, -50.17],
              [-75.46, -50.36],
              [-75.4, -50.04],
              [-75.05, -50.17]
            ]
          ],
          [
            [
              [-75.29, -49.1],
              [-75.66, -49.22],
              [-75.36, -48.99],
              [-75.29, -49.1]
            ]
          ],
          [
            [
              [-74.61, -48.46],
              [-74.41, -49.73],
              [-74.77, -50.06],
              [-74.65, -49.36],
              [-75.02, -49.9],
              [-75.47, -49.33],
              [-74.92, -49.34],
              [-75.05, -48.8],
              [-74.61, -48.7],
              [-75.03, -48.5],
              [-74.74, -48.12],
              [-74.61, -48.46]
            ]
          ],
          [
            [
              [-75.24, -48.27],
              [-75.23, -48.71],
              [-75.58, -48.09],
              [-75.24, -48.27]
            ]
          ],
          [
            [
              [-74.93, -48.16],
              [-75.04, -48.44],
              [-75.26, -48.07],
              [-74.93, -48.16]
            ]
          ],
          [
            [
              [-74.46, -45.78],
              [-74.39, -45.44],
              [-74.21, -45.64],
              [-74.46, -45.78]
            ]
          ],
          [
            [
              [-73.65, -45.76],
              [-73.78, -45.67],
              [-73.7, -45.44],
              [-73.65, -45.76]
            ]
          ],
          [
            [
              [-74.02, -45.43],
              [-73.82, -45.48],
              [-74.14, -45.58],
              [-74.02, -45.43]
            ]
          ],
          [
            [
              [-73.98, -45.27],
              [-73.78, -45.34],
              [-74.17, -45.25],
              [-73.98, -45.27]
            ]
          ],
          [
            [
              [-73.85, -45],
              [-73.73, -45.28],
              [-74.24, -45.16],
              [-73.85, -45]
            ]
          ],
          [
            [
              [-74.21, -44.78],
              [-74.41, -44.63],
              [-73.87, -44.69],
              [-74.21, -44.78]
            ]
          ],
          [
            [
              [-72.72, -44.55],
              [-72.98, -44.61],
              [-72.83, -44.69],
              [-73.22, -44.94],
              [-73.41, -44.82],
              [-73.21, -44.8],
              [-73.39, -44.79],
              [-73.46, -44.64],
              [-73.23, -44.42],
              [-72.72, -44.55]
            ]
          ],
          [
            [
              [-73.89, -41.82],
              [-73.37, -42.25],
              [-73.82, -42.51],
              [-73.49, -43.11],
              [-73.86, -43.4],
              [-74.41, -43.24],
              [-73.89, -41.82]
            ]
          ],
          [
            [
              [-69.48, -17.64],
              [-68.44, -19.43],
              [-68.76, -20.41],
              [-67.88, -22.83],
              [-67.18, -22.82],
              [-67.34, -24.02],
              [-68.57, -24.77],
              [-68.29, -26.92],
              [-69.66, -28.4],
              [-69.83, -30.19],
              [-70.53, -31.19],
              [-69.81, -34.24],
              [-70.57, -35.25],
              [-70.42, -36.14],
              [-71.19, -36.84],
              [-70.82, -38.57],
              [-71.7, -39.58],
              [-71.73, -42.1],
              [-72.13, -42.29],
              [-71.86, -44.37],
              [-71.11, -44.54],
              [-72.08, -44.77],
              [-71.3, -45.29],
              [-71.78, -45.65],
              [-71.67, -46.68],
              [-72.36, -47.47],
              [-72.56, -48.8],
              [-73.58, -49.54],
              [-73.17, -50.75],
              [-72.29, -50.65],
              [-72.4, -51.51],
              [-71.91, -52],
              [-68.44, -52.38],
              [-70.81, -52.73],
              [-71.28, -53.89],
              [-72.45, -53.4],
              [-71.8, -53.52],
              [-71.17, -52.81],
              [-72.55, -53.07],
              [-72.19, -53.18],
              [-72.65, -53.32],
              [-72.4, -53.54],
              [-73.22, -53.23],
              [-71.48, -52.63],
              [-72.8, -52.54],
              [-72.98, -53.07],
              [-73.45, -53.01],
              [-72.89, -52.52],
              [-73.69, -52.73],
              [-73.73, -52.03],
              [-73.33, -52.22],
              [-72.99, -52.07],
              [-72.86, -52.26],
              [-72.78, -52.06],
              [-72.94, -52.09],
              [-72.7, -51.98],
              [-72.9, -52.46],
              [-72.47, -51.79],
              [-73.24, -51.46],
              [-72.56, -51.78],
              [-73.28, -51.61],
              [-72.92, -51.86],
              [-73.24, -52.09],
              [-73.39, -51.66],
              [-73.28, -52.16],
              [-73.56, -52.05],
              [-73.64, -51.82],
              [-73.4, -52.02],
              [-73.46, -51.69],
              [-73.91, -51.62],
              [-73.89, -51.37],
              [-73.6, -51.62],
              [-73.71, -51.16],
              [-74.25, -50.94],
              [-73.53, -50.71],
              [-73.72, -50.56],
              [-73.57, -50.4],
              [-74.05, -50.83],
              [-74.29, -50.48],
              [-73.88, -50.54],
              [-74.69, -50.2],
              [-73.87, -50.29],
              [-74.33, -49.63],
              [-73.71, -49.76],
              [-74.11, -49.48],
              [-73.83, -49.03],
              [-74.37, -49.43],
              [-74.02, -48.41],
              [-74.65, -48.02],
              [-73.22, -48],
              [-73.72, -47.53],
              [-73.93, -47.85],
              [-74.74, -47.72],
              [-74.04, -47.62],
              [-74.53, -47.44],
              [-73.93, -47.04],
              [-74.27, -46.79],
              [-75.01, -46.75],
              [-74.94, -46.44],
              [-75.41, -46.93],
              [-75.72, -46.73],
              [-74.36, -45.79],
              [-74.14, -45.81],
              [-73.97, -46.09],
              [-74.08, -46.19],
              [-74.31, -46.25],
              [-74.49, -46.19],
              [-74.34, -46.27],
              [-74.05, -46.2],
              [-74, -46.29],
              [-73.85, -46.35],
              [-74.02, -46.22],
              [-73.88, -46.14],
              [-73.76, -46.24],
              [-73.77, -46.3],
              [-73.81, -46.39],
              [-74, -46.56],
              [-73.91, -46.6],
              [-73.84, -46.59],
              [-73.18, -45.67],
              [-73.59, -45.78],
              [-73.52, -45.46],
              [-72.83, -45.42],
              [-73.39, -44.98],
              [-73.14, -44.94],
              [-72.77, -44.75],
              [-72.61, -44.47],
              [-73.29, -44.14],
              [-72.54, -42.56],
              [-72.85, -42.28],
              [-72.42, -42.45],
              [-72.86, -41.91],
              [-72.31, -41.44],
              [-73.75, -41.75],
              [-73.49, -41.52],
              [-74, -40.97],
              [-73.22, -39.41],
              [-73.64, -37.21],
              [-73.19, -37.14],
              [-71.44, -32.64],
              [-71.52, -28.97],
              [-70.45, -25.36],
              [-70.62, -23.49],
              [-70.05, -21.43],
              [-70.41, -18.35],
              [-69.5, -17.51],
              [-69.48, -17.64]
            ],
            [
              [-74.07, -46.01],
              [-74.16, -46.14],
              [-74.07, -46.1],
              [-74.07, -46.01]
            ]
          ]
        ]
      },
      "properties": { "name": "Chile" },
      "id": "CL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [110.72, 20.06],
              [111.03, 19.64],
              [110.05, 18.38],
              [108.69, 18.51],
              [108.63, 19.28],
              [109.26, 19.9],
              [110.72, 20.06]
            ]
          ],
          [
            [
              [123.38, 53.53],
              [126.1, 52.76],
              [127.53, 49.79],
              [130.67, 48.86],
              [130.99, 47.69],
              [134.74, 48.27],
              [133.12, 45.13],
              [131.86, 45.35],
              [130.95, 44.84],
              [131.31, 43.39],
              [130.41, 42.72],
              [130.6, 42.42],
              [129.91, 43.01],
              [129.71, 42.44],
              [128.06, 42],
              [128.16, 41.38],
              [126.91, 41.8],
              [124.37, 40.09],
              [121.15, 38.72],
              [121.75, 39.35],
              [121.23, 39.54],
              [122.3, 40.51],
              [121.18, 40.92],
              [118.92, 39.13],
              [117.74, 39.1],
              [117.67, 38.39],
              [118.84, 38.15],
              [119.23, 37.14],
              [120.74, 37.84],
              [122.56, 37.4],
              [122.5, 36.89],
              [120.09, 36.2],
              [119.18, 34.88],
              [120.25, 34.31],
              [120.84, 32.64],
              [121.9, 31.75],
              [119.63, 32.26],
              [121.88, 30.97],
              [120.15, 30.19],
              [122.13, 29.89],
              [121.45, 29.51],
              [121.98, 29.59],
              [121.41, 29.16],
              [121.61, 28.72],
              [121.14, 28.84],
              [121.58, 28.27],
              [120.59, 28.08],
              [120.84, 27.88],
              [120.13, 26.64],
              [119.55, 26.75],
              [119.94, 26.35],
              [119.09, 26.14],
              [119.71, 25.99],
              [119.65, 25.36],
              [119.31, 25.61],
              [118.62, 24.54],
              [117.79, 24.46],
              [118.12, 24.26],
              [116.52, 23.42],
              [116.48, 22.94],
              [114.22, 22.55],
              [114.03, 22.51],
              [113.48, 23.05],
              [113.55, 22.21],
              [113.53, 22.19],
              [113.17, 22.57],
              [113.39, 22.18],
              [112.94, 21.87],
              [110.4, 21.38],
              [110.28, 20.25],
              [109.92, 20.23],
              [109.57, 21.72],
              [109.14, 21.4],
              [108.48, 21.94],
              [107.99, 21.54],
              [106.69, 22.03],
              [106.71, 22.86],
              [105.58, 23.06],
              [105.35, 23.33],
              [103.96, 22.5],
              [102.48, 22.77],
              [102.14, 22.4],
              [101.57, 22.21],
              [101.79, 21.14],
              [101.15, 21.57],
              [100.21, 21.43],
              [99.96, 22.05],
              [99.16, 22.16],
              [99.57, 22.94],
              [98.93, 23.19],
              [98.89, 24.16],
              [97.54, 23.94],
              [97.55, 24.74],
              [98.71, 25.86],
              [98.78, 26.64],
              [98.7, 27.54],
              [97.81, 28.34],
              [97.35, 28.22],
              [96.4, 28.35],
              [96.62, 28.79],
              [96.08, 29.47],
              [95.39, 29.04],
              [94.65, 29.33],
              [92.54, 27.86],
              [91.66, 27.76],
              [90.47, 28.07],
              [89.59, 28.14],
              [88.92, 27.32],
              [88.83, 28.01],
              [88.14, 27.87],
              [86.01, 27.88],
              [82.1, 30.34],
              [81.03, 30.2],
              [78.77, 31.31],
              [78.4, 32.55],
              [79.53, 32.75],
              [78.08, 35.45],
              [77.82, 35.5],
              [76.17, 35.82],
              [75.86, 36.66],
              [74.82, 37.02],
              [74.57, 37.03],
              [74.92, 37.24],
              [74.86, 38.47],
              [73.82, 38.61],
              [73.66, 39.45],
              [74.86, 40.52],
              [76.35, 40.35],
              [76.87, 41.01],
              [78.08, 41.04],
              [80.23, 42.2],
              [80.38, 43.03],
              [80.82, 43.16],
              [80.52, 44.73],
              [79.87, 44.9],
              [82.56, 45.13],
              [82.32, 45.57],
              [83.04, 47.21],
              [85.53, 47.06],
              [85.76, 48.39],
              [87.35, 49.09],
              [87.84, 49.17],
              [88.65, 48.18],
              [90.07, 47.89],
              [91.02, 46.6],
              [90.9, 45.25],
              [95.42, 44.29],
              [96.38, 42.73],
              [100.84, 42.68],
              [105.01, 41.58],
              [107.47, 42.47],
              [110.44, 42.78],
              [111.96, 43.69],
              [111.42, 44.38],
              [111.98, 45.09],
              [113.64, 44.75],
              [117.42, 46.58],
              [119.9, 46.68],
              [118.54, 47.99],
              [117.37, 47.65],
              [115.59, 47.92],
              [116.71, 49.83],
              [117.87, 49.52],
              [119.21, 50.02],
              [120.78, 52.11],
              [120.03, 52.77],
              [120.86, 53.28],
              [123.38, 53.53]
            ]
          ]
        ]
      },
      "properties": { "name": "China" },
      "id": "CN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [121.73, 25.14],
              [121.84, 24.48],
              [120.72, 21.93],
              [120.11, 23.62],
              [121.01, 25.01],
              [121.73, 25.14]
            ]
          ]
        ]
      },
      "properties": { "name": "Taiwan" },
      "id": "TW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [105.7, -10.51],
              [105.63, -10.44],
              [105.74, -10.38],
              [105.7, -10.51]
            ]
          ]
        ]
      },
      "properties": { "name": "Christmas Island" },
      "id": "CX"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [96.86, -12.2],
              [96.82, -12.18],
              [96.83, -12.13],
              [96.86, -12.2]
            ]
          ]
        ]
      },
      "properties": { "name": "Cocos (Keeling) Islands" },
      "id": "CC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-71.56, 12.45],
              [-71.32, 11.85],
              [-71.98, 11.66],
              [-72.49, 11.12],
              [-73.38, 9.17],
              [-72.78, 9.08],
              [-72, 7.02],
              [-70.12, 6.98],
              [-69.25, 6.08],
              [-67.45, 6.19],
              [-67.86, 4.56],
              [-67.29, 3.4],
              [-67.83, 2.83],
              [-67.19, 2.39],
              [-66.87, 1.22],
              [-67.42, 2.14],
              [-67.91, 1.75],
              [-69.85, 1.71],
              [-69.84, 1.07],
              [-69.12, 0.65],
              [-70.04, 0.59],
              [-69.38, -1.34],
              [-69.96, -4.24],
              [-70.72, -3.78],
              [-70.29, -2.51],
              [-71.7, -2.15],
              [-72.88, -2.51],
              [-73.56, -1.37],
              [-75.29, -0.12],
              [-77.38, 0.38],
              [-78.59, 1.24],
              [-78.81, 1.44],
              [-78.57, 2.43],
              [-77.74, 2.6],
              [-77.03, 3.92],
              [-77.43, 4.03],
              [-77.34, 6.57],
              [-77.89, 7.23],
              [-77.22, 7.94],
              [-77.37, 8.67],
              [-76.76, 7.92],
              [-76.93, 8.57],
              [-75.63, 9.45],
              [-75.27, 10.8],
              [-74.86, 11.13],
              [-74.39, 10.74],
              [-74.16, 11.33],
              [-73.28, 11.3],
              [-71.56, 12.45]
            ]
          ]
        ]
      },
      "properties": { "name": "Colombia" },
      "id": "CO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [44.49, -12.09],
              [44.51, -12.38],
              [44.21, -12.16],
              [44.49, -12.09]
            ]
          ],
          [
            [
              [43.46, -11.94],
              [43.22, -11.76],
              [43.28, -11.38],
              [43.46, -11.94]
            ]
          ]
        ]
      },
      "properties": { "name": "Comoros" },
      "id": "KM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [45.2, -12.85],
              [45.1, -12.99],
              [45.08, -12.66],
              [45.2, -12.85]
            ]
          ]
        ]
      },
      "properties": { "name": "Mayotte" },
      "id": "YT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [12.78, -4.39],
              [12.03, -5.01],
              [11.14, -3.93],
              [11.93, -3.64],
              [11.57, -2.33],
              [12.48, -2.33],
              [12.65, -1.82],
              [13, -2.37],
              [13.76, -2.09],
              [14.11, -2.49],
              [14.43, -1.89],
              [14.52, -0.61],
              [13.85, -0.2],
              [14.49, 0.91],
              [14.19, 1.39],
              [13.19, 1.22],
              [13.29, 2.16],
              [16.07, 1.65],
              [16.21, 2.22],
              [16.66, 3.53],
              [18.62, 3.48],
              [17.71, -0.54],
              [16.2, -2.18],
              [15.89, -3.94],
              [14.66, -4.91],
              [14.4, -4.28],
              [13.41, -4.88],
              [13.09, -4.63],
              [12.78, -4.39]
            ]
          ]
        ]
      },
      "properties": { "name": "Congo, Republic of the" },
      "id": "CG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [25.89, 5.19],
              [27.46, 5.02],
              [28.36, 4.29],
              [29.64, 4.64],
              [30.86, 3.49],
              [30.73, 2.45],
              [31.3, 2.12],
              [29.96, 0.83],
              [29.6, -1.39],
              [29.02, -2.74],
              [29.23, -3.75],
              [29.42, -4.45],
              [29.55, -6.3],
              [30.77, -8.19],
              [28.9, -8.48],
              [28.37, -9.26],
              [28.7, -10.65],
              [28.36, -11.55],
              [29.03, -12.38],
              [29.81, -12.16],
              [29.8, -13.45],
              [29.02, -13.4],
              [27.2, -11.57],
              [26.87, -11.97],
              [26, -11.9],
              [25.33, -11.19],
              [24.45, -11.46],
              [23.99, -10.87],
              [22.25, -11.21],
              [21.78, -7.28],
              [19.54, -7],
              [19.37, -8],
              [17.62, -8.1],
              [16.58, -5.9],
              [14, -5.85],
              [13.18, -5.86],
              [12.21, -5.77],
              [13.09, -4.66],
              [13.09, -4.63],
              [13.41, -4.88],
              [14.4, -4.28],
              [14.66, -4.91],
              [15.89, -3.94],
              [16.2, -2.18],
              [17.71, -0.54],
              [18.62, 3.48],
              [18.54, 4.34],
              [19.42, 5.13],
              [20.59, 4.41],
              [22.38, 4.13],
              [22.9, 4.82],
              [25.89, 5.19]
            ]
          ]
        ]
      },
      "properties": { "name": "Congo, Democratic Republic of the" },
      "id": "CD"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-157.89, -21.94],
              [-157.96, -21.9],
              [-157.92, -21.88],
              [-157.89, -21.94]
            ]
          ],
          [
            [
              [-159.74, -21.25],
              [-159.83, -21.2],
              [-159.75, -21.19],
              [-159.74, -21.25]
            ]
          ],
          [
            [
              [-157.32, -20.19],
              [-157.33, -20.13],
              [-157.31, -20.15],
              [-157.32, -20.19]
            ]
          ]
        ]
      },
      "properties": { "name": "Cook Islands" },
      "id": "CK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-85.09, 11.01],
              [-83.65, 10.92],
              [-82.56, 9.56],
              [-82.93, 9.47],
              [-82.9, 8.03],
              [-84.74, 9.97],
              [-85.24, 10.2],
              [-85.14, 9.59],
              [-85.66, 9.91],
              [-85.69, 11.08],
              [-85.09, 11.01]
            ]
          ]
        ]
      },
      "properties": { "name": "Costa Rica" },
      "id": "CR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [17.3, 43.01],
              [17.65, 42.89],
              [18.46, 42.57],
              [18.5, 42.45],
              [17.3, 43.01]
            ]
          ],
          [
            [
              [16.85, 43.27],
              [16.4, 43.33],
              [16.75, 43.36],
              [16.85, 43.27]
            ]
          ],
          [
            [
              [14.46, 44.91],
              [14.49, 44.61],
              [14.29, 44.91],
              [14.4, 44.92],
              [14.32, 45.17],
              [14.46, 44.91]
            ]
          ],
          [
            [
              [14.76, 44.94],
              [14.43, 45.08],
              [14.54, 45.24],
              [14.76, 44.94]
            ]
          ],
          [
            [
              [16.57, 46.48],
              [16.61, 46.48],
              [17.67, 45.83],
              [18.82, 45.91],
              [19.42, 45.23],
              [19.04, 44.86],
              [15.79, 45.17],
              [16.14, 44.2],
              [17.58, 42.94],
              [16.88, 43.4],
              [15.99, 43.5],
              [14.48, 45.31],
              [14.29, 45.32],
              [14.17, 44.98],
              [13.9, 44.77],
              [13.59, 45.48],
              [15.17, 45.43],
              [15.65, 46.22],
              [16.57, 46.48]
            ]
          ]
        ]
      },
      "properties": { "name": "Croatia" },
      "id": "HR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-82.55, 21.57],
              [-83.19, 21.62],
              [-82.97, 21.94],
              [-82.55, 21.57]
            ]
          ],
          [
            [
              [-82, 23.19],
              [-80.03, 22.95],
              [-74.13, 20.19],
              [-77.72, 19.83],
              [-77.24, 20.66],
              [-78.05, 20.7],
              [-78.75, 21.64],
              [-81.82, 22.18],
              [-82.16, 22.4],
              [-81.65, 22.49],
              [-81.89, 22.68],
              [-82.76, 22.7],
              [-83.37, 22.2],
              [-83.93, 22.16],
              [-84.03, 21.91],
              [-84.95, 21.86],
              [-84.34, 22.01],
              [-84.4, 22.33],
              [-84.08, 22.66],
              [-82, 23.19]
            ]
          ]
        ]
      },
      "properties": { "name": "Cuba" },
      "id": "CU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [33.65, 35.35],
              [34.59, 35.69],
              [33.03, 34.56],
              [32.27, 35.04],
              [33.65, 35.35]
            ]
          ]
        ]
      },
      "properties": { "name": "Cyprus" },
      "id": "CY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [14.7, 48.58],
              [13.83, 48.77],
              [12.09, 50.32],
              [14.83, 50.87],
              [16.34, 50.66],
              [16.64, 50.11],
              [17.72, 50.32],
              [18.85, 49.52],
              [16.95, 48.62],
              [15.03, 49.02],
              [14.7, 48.58]
            ]
          ]
        ]
      },
      "properties": { "name": "Czech Republic" },
      "id": "CZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [2.48, 6.34],
              [1.64, 6.22],
              [1.4, 9.43],
              [0.78, 10.38],
              [0.92, 11],
              [2.4, 11.9],
              [2.84, 12.4],
              [3.6, 11.69],
              [3.86, 10.58],
              [2.79, 9.04],
              [2.72, 6.37],
              [2.48, 6.34]
            ]
          ]
        ]
      },
      "properties": { "name": "Benin" },
      "id": "BJ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [10.75, 55.48],
              [10.5, 55.03],
              [9.68, 55.5],
              [10.75, 55.48]
            ]
          ],
          [
            [
              [12.57, 55.99],
              [12.07, 54.97],
              [10.87, 55.73],
              [12.57, 55.99]
            ]
          ],
          [
            [
              [8.92, 56.92],
              [8.77, 56.69],
              [8.51, 56.74],
              [8.92, 56.92]
            ]
          ],
          [
            [
              [9.97, 57.07],
              [10.31, 56.98],
              [10.31, 56.75],
              [9.87, 56.65],
              [10.96, 56.44],
              [9.55, 55.71],
              [9.77, 54.89],
              [9.45, 54.83],
              [8.66, 54.91],
              [8.62, 55.43],
              [8.09, 55.55],
              [8.17, 56.65],
              [8.73, 56.48],
              [8.68, 56.62],
              [9.08, 56.81],
              [9.15, 56.7],
              [9.06, 56.57],
              [9.27, 56.63],
              [9.32, 56.53],
              [9.18, 56.92],
              [9.97, 57.07]
            ]
          ],
          [
            [
              [10.43, 57.59],
              [10.34, 56.99],
              [10.01, 57.09],
              [9.12, 57.05],
              [8.67, 56.95],
              [8.42, 56.68],
              [8.59, 56.69],
              [8.55, 56.58],
              [8.24, 56.71],
              [8.62, 57.12],
              [9.39, 57.15],
              [9.95, 57.58],
              [10.65, 57.74],
              [10.43, 57.59]
            ]
          ]
        ]
      },
      "properties": { "name": "Denmark" },
      "id": "DK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-61.36, 15.2],
              [-61.45, 15.63],
              [-61.25, 15.46],
              [-61.36, 15.2]
            ]
          ]
        ]
      },
      "properties": { "name": "Dominica" },
      "id": "DM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-70.78, 19.85],
              [-69.22, 19.36],
              [-69.63, 19.1],
              [-68.73, 18.95],
              [-68.45, 18.36],
              [-70.69, 18.43],
              [-71.42, 17.6],
              [-71.77, 18.04],
              [-71.75, 19.71],
              [-70.78, 19.85]
            ]
          ]
        ]
      },
      "properties": { "name": "Dominican Republic" },
      "id": "DO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-91.22, -0.01],
              [-90.81, -0.73],
              [-91.38, -1.03],
              [-91.08, -0.59],
              [-91.61, 0],
              [-91.22, -0.01]
            ]
          ],
          [
            [
              [-78.59, 1.24],
              [-77.38, 0.38],
              [-75.29, -0.12],
              [-75.63, -0.11],
              [-75.22, -0.97],
              [-75.56, -1.53],
              [-78.34, -3.42],
              [-78.71, -4.58],
              [-79.05, -5.01],
              [-79.65, -4.43],
              [-80.47, -4.44],
              [-80.34, -3.38],
              [-79.76, -2.01],
              [-80.26, -2.74],
              [-80.89, -2.32],
              [-80.91, -1.03],
              [-80.26, -0.63],
              [-80.06, 0.83],
              [-78.81, 1.44],
              [-78.59, 1.24]
            ]
          ]
        ]
      },
      "properties": { "name": "Ecuador" },
      "id": "EC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-89.34, 14.42],
              [-87.75, 13.86],
              [-87.82, 13.41],
              [-88.54, 13.19],
              [-90.1, 13.75],
              [-89.35, 14.43],
              [-89.34, 14.42]
            ]
          ]
        ]
      },
      "properties": { "name": "El Salvador" },
      "id": "SV"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [10.03, 2.17],
              [11.34, 2.17],
              [11.35, 1],
              [9.8, 1],
              [9.36, 1.17],
              [9.81, 2.34],
              [10.03, 2.17]
            ]
          ]
        ]
      },
      "properties": { "name": "Equatorial Guinea" },
      "id": "GQ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [36.54, 14.26],
              [37.29, 14.45],
              [37.57, 14.1],
              [37.91, 14.88],
              [38.45, 14.42],
              [40.23, 14.44],
              [42.4, 12.47],
              [41.79, 11.01],
              [42.94, 11],
              [42.85, 10.22],
              [44.01, 9.01],
              [47.99, 8],
              [44.95, 4.9],
              [43.69, 4.89],
              [41.91, 3.98],
              [40.78, 4.29],
              [39.52, 3.41],
              [35.94, 4.62],
              [34.7, 6.68],
              [32.99, 7.92],
              [33.25, 8.46],
              [34.12, 8.58],
              [34.29, 10.55],
              [34.86, 10.73],
              [35.1, 11.83],
              [36.14, 12.71],
              [36.54, 14.26]
            ]
          ]
        ]
      },
      "properties": { "name": "Ethiopia" },
      "id": "ET"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [38.8, 17.65],
              [39.72, 15.08],
              [39.88, 15.49],
              [41.17, 14.63],
              [43.12, 12.71],
              [42.4, 12.47],
              [40.23, 14.44],
              [38.45, 14.42],
              [37.91, 14.88],
              [37.57, 14.1],
              [37.29, 14.45],
              [36.54, 14.26],
              [37, 17.07],
              [38.6, 17.99],
              [38.8, 17.65]
            ]
          ]
        ]
      },
      "properties": { "name": "Eritrea" },
      "id": "ER"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [22.99, 58.6],
              [23.33, 58.44],
              [21.99, 57.92],
              [21.83, 58.5],
              [22.99, 58.6]
            ]
          ],
          [
            [
              [22.75, 59],
              [23.05, 58.84],
              [22.04, 58.94],
              [22.75, 59]
            ]
          ],
          [
            [
              [25.78, 59.63],
              [28.02, 59.48],
              [27.43, 58.81],
              [27.82, 57.87],
              [27.37, 57.54],
              [25.29, 58.08],
              [24.31, 57.87],
              [24.56, 58.33],
              [23.73, 58.37],
              [23.46, 59.21],
              [25.78, 59.63]
            ]
          ]
        ]
      },
      "properties": { "name": "Estonia" },
      "id": "EE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-7.05, 62.07],
              [-7.2, 62.02],
              [-7.44, 62.14],
              [-7.05, 62.07]
            ]
          ],
          [
            [
              [-6.71, 61.94],
              [-7.23, 62.17],
              [-7.21, 62.28],
              [-6.71, 61.94]
            ]
          ],
          [
            [
              [-6.66, 62.09],
              [-7.06, 62.31],
              [-6.6, 62.2],
              [-6.66, 62.09]
            ]
          ]
        ]
      },
      "properties": { "name": "Faroe Islands" },
      "id": "FO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-59.21, -51.41],
              [-60.37, -52.16],
              [-60.98, -52.06],
              [-60.18, -51.76],
              [-60.64, -51.36],
              [-59.21, -51.41]
            ]
          ],
          [
            [
              [-58.7, -51.34],
              [-57.73, -51.69],
              [-59.72, -52.12],
              [-58.7, -51.34]
            ]
          ]
        ]
      },
      "properties": { "name": "Falkland Islands (Islas Malvinas)" },
      "id": "FK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-37.5, -54.01],
              [-35.78, -54.77],
              [-38.03, -54.05],
              [-37.5, -54.01]
            ]
          ]
        ]
      },
      "properties": { "name": "South Georgia South Sandwich Islands" },
      "id": "GS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [179.96, -16.2],
              [179.48, -16.7],
              [179.93, -16.46],
              [179.9, -16.77],
              [178.48, -16.78],
              [179.96, -16.2]
            ]
          ],
          [
            [
              [-179.97, -16.17],
              [-180, -16.15],
              [-179.94, -16.13],
              [-179.97, -16.17]
            ]
          ]
        ]
      },
      "properties": { "name": "Fiji" },
      "id": "FJ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [28.17, 69.91],
              [29.18, 69.64],
              [28.96, 69.05],
              [28.43, 68.9],
              [28.82, 68.84],
              [28.69, 68.2],
              [30.03, 67.69],
              [29.07, 66.9],
              [30.13, 65.72],
              [29.64, 64.93],
              [30.58, 64.22],
              [29.99, 63.74],
              [31.59, 62.91],
              [27.81, 60.55],
              [22.91, 59.8],
              [23.34, 60.02],
              [23.09, 60.35],
              [21.36, 60.65],
              [21.67, 61.55],
              [21.06, 62.61],
              [21.5, 63.21],
              [25.45, 64.95],
              [25.31, 65.51],
              [24.17, 65.81],
              [23.66, 66.31],
              [24.01, 66.8],
              [23.43, 67.47],
              [23.67, 67.94],
              [21.81, 68.57],
              [20.58, 69.06],
              [21.32, 69.33],
              [22.4, 68.71],
              [24.93, 68.58],
              [26.45, 69.93],
              [28.17, 69.91]
            ]
          ]
        ]
      },
      "properties": { "name": "Finland" },
      "id": "FI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [20.08, 60.35],
              [19.94, 60.04],
              [19.65, 60.26],
              [20.08, 60.35]
            ]
          ]
        ]
      },
      "properties": { "name": "Aland Islands" },
      "id": "AX"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [9.45, 42.68],
              [9.18, 41.36],
              [8.58, 42.38],
              [9.35, 43],
              [9.45, 42.68]
            ]
          ],
          [
            [
              [2.54, 51.09],
              [4.15, 49.98],
              [4.83, 50.17],
              [5.81, 49.55],
              [6.36, 49.46],
              [8.23, 48.96],
              [7.59, 47.58],
              [6.99, 47.5],
              [5.97, 46.21],
              [6.79, 46.43],
              [7.04, 45.93],
              [7.13, 45.26],
              [6.62, 45.11],
              [6.98, 44.28],
              [7.66, 44.17],
              [7.53, 43.79],
              [7.44, 43.76],
              [7.42, 43.77],
              [7.39, 43.73],
              [6.17, 43.05],
              [3.96, 43.54],
              [3.08, 43.07],
              [3.18, 42.44],
              [1.72, 42.51],
              [1.78, 42.57],
              [1.45, 42.6],
              [-1.78, 43.36],
              [-1.04, 44.68],
              [-1.25, 44.66],
              [-1.08, 45.56],
              [-0.54, 44.9],
              [-0.78, 45.46],
              [-1.24, 45.7],
              [-1.11, 46.32],
              [-2.13, 46.84],
              [-2.13, 47.28],
              [-1.73, 47.21],
              [-4.37, 47.8],
              [-4.73, 48.04],
              [-4.19, 48.3],
              [-4.78, 48.51],
              [-1.37, 48.64],
              [-1.94, 49.72],
              [0.42, 49.45],
              [0.07, 49.53],
              [1.46, 50.12],
              [1.63, 50.88],
              [2.54, 51.09]
            ]
          ]
        ]
      },
      "properties": { "name": "France" },
      "id": "FR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-53.49, 5.57],
              [-52.29, 4.94],
              [-52.04, 4.33],
              [-51.85, 4.65],
              [-51.68, 4.03],
              [-52.91, 2.2],
              [-54.6, 2.33],
              [-54, 3.45],
              [-54.48, 4.75],
              [-54.17, 5.35],
              [-53.94, 5.74],
              [-53.49, 5.57]
            ]
          ]
        ]
      },
      "properties": { "name": "French Guiana" },
      "id": "GF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-149.21, -17.73],
              [-149.18, -17.87],
              [-149.63, -17.55],
              [-149.21, -17.73]
            ]
          ],
          [
            [
              [-151.4, -16.89],
              [-151.48, -16.9],
              [-151.48, -16.74],
              [-151.4, -16.89]
            ]
          ],
          [
            [
              [-138.96, -9.74],
              [-138.81, -9.74],
              [-139.17, -9.78],
              [-138.96, -9.74]
            ]
          ],
          [
            [
              [-140.03, -8.9],
              [-140.19, -8.95],
              [-140.25, -8.8],
              [-140.03, -8.9]
            ]
          ]
        ]
      },
      "properties": { "name": "French Polynesia" },
      "id": "PF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [69, -48.8],
              [69.58, -49.3],
              [70.57, -49.25],
              [69.77, -49.39],
              [70.25, -49.69],
              [68.8, -49.72],
              [69, -48.8]
            ]
          ]
        ]
      },
      "properties": { "name": "French Southern and Antarctic Lands" },
      "id": "TF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [42.87, 11.59],
              [43.25, 11.47],
              [42.94, 11],
              [41.79, 11.01],
              [42.4, 12.47],
              [43.12, 12.71],
              [43.41, 12.06],
              [42.51, 11.57],
              [42.87, 11.59]
            ]
          ]
        ]
      },
      "properties": { "name": "Djibouti" },
      "id": "DJ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [12.52, 2.28],
              [13.29, 2.16],
              [13.19, 1.22],
              [14.19, 1.39],
              [14.49, 0.91],
              [13.85, -0.2],
              [14.52, -0.61],
              [14.43, -1.89],
              [14.11, -2.49],
              [13.76, -2.09],
              [13, -2.37],
              [12.65, -1.82],
              [12.48, -2.33],
              [11.57, -2.33],
              [11.93, -3.64],
              [11.14, -3.93],
              [9.62, -2.38],
              [8.71, -0.64],
              [9.3, -0.37],
              [9.35, 0.36],
              [9.92, 0.19],
              [9.3, 0.53],
              [9.8, 1],
              [11.35, 1],
              [11.34, 2.17],
              [12.52, 2.28]
            ]
          ]
        ]
      },
      "properties": { "name": "Gabon" },
      "id": "GA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [41.55, 42.41],
              [40, 43.38],
              [42.85, 43.18],
              [43.91, 42.58],
              [44.93, 42.76],
              [46.45, 41.9],
              [46.52, 41.05],
              [45.02, 41.3],
              [43.46, 41.11],
              [42.83, 41.58],
              [41.53, 41.52],
              [41.55, 42.41]
            ]
          ]
        ]
      },
      "properties": { "name": "Georgia" },
      "id": "GE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-16.73, 13.45],
              [-16.2, 13.25],
              [-16.16, 13.43],
              [-15.3, 13.49],
              [-16.14, 13.45],
              [-16.39, 13.33],
              [-16.57, 13.59],
              [-15.07, 13.83],
              [-13.8, 13.41],
              [-15.11, 13.6],
              [-15.29, 13.37],
              [-15.8, 13.35],
              [-15.81, 13.16],
              [-16.75, 13.06],
              [-16.73, 13.45]
            ]
          ]
        ]
      },
      "properties": { "name": "Gambia, The" },
      "id": "GM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [34.33, 31.26],
              [34.27, 31.22],
              [34.22, 31.32],
              [34.49, 31.6],
              [34.33, 31.26]
            ]
          ],
          [
            [
              [35.28, 32.52],
              [35.55, 32.39],
              [35.48, 31.5],
              [34.88, 31.39],
              [35.28, 32.52]
            ],
            [
              [35.25, 31.79],
              [35.26, 31.79],
              [35.25, 31.81],
              [35.25, 31.79]
            ]
          ]
        ]
      },
      "properties": { "name": "Palestine" },
      "id": "PS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [10.98, 54.38],
              [10.82, 53.89],
              [12.53, 54.47],
              [14.28, 53.7],
              [14.83, 50.87],
              [12.09, 50.32],
              [13.83, 48.77],
              [12.76, 48.12],
              [13.02, 47.47],
              [9.57, 47.54],
              [7.7, 47.54],
              [7.59, 47.58],
              [8.23, 48.96],
              [6.36, 49.46],
              [6.13, 50.13],
              [6.01, 50.76],
              [5.96, 51.81],
              [6.83, 51.97],
              [7.07, 52.39],
              [6.69, 52.55],
              [7.21, 53.24],
              [7.3, 53.69],
              [8.5, 53.35],
              [8.67, 53.89],
              [9.83, 53.54],
              [8.9, 53.94],
              [8.6, 54.33],
              [9.02, 54.5],
              [8.28, 54.75],
              [8.66, 54.91],
              [9.45, 54.83],
              [10.98, 54.38]
            ]
          ]
        ]
      },
      "properties": { "name": "Germany" },
      "id": "DE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [0.64, 5.85],
              [0.66, 5.75],
              [0.26, 5.76],
              [-0.8, 5.21],
              [-1.61, 5.02],
              [-2.06, 4.73],
              [-2.93, 5.1],
              [-3.25, 6.61],
              [-2.49, 8.2],
              [-2.69, 9.48],
              [-2.83, 11],
              [-0.15, 11.14],
              [0.73, 8.32],
              [0.53, 6.95],
              [1.2, 6.1],
              [0.69, 5.75],
              [0.63, 5.95],
              [0.51, 6.06],
              [0.41, 6.08],
              [0.37, 6.04],
              [0.26, 6.1],
              [0.21, 6.09],
              [0.36, 6.02],
              [0.42, 6.07],
              [0.49, 6.04],
              [0.61, 5.95],
              [0.64, 5.85]
            ]
          ]
        ]
      },
      "properties": { "name": "Ghana" },
      "id": "GH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-5.33, 36.16],
              [-5.34, 36.11],
              [-5.36, 36.16],
              [-5.33, 36.16]
            ]
          ]
        ]
      },
      "properties": { "name": "Gibraltar" },
      "id": "GI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [175.59, -1.92],
              [175.55, -1.82],
              [175.59, -1.88],
              [175.59, -1.92]
            ]
          ],
          [
            [
              [175.04, -1.55],
              [175, -1.53],
              [175.05, -1.43],
              [175.04, -1.55]
            ]
          ],
          [
            [
              [173.62, 0.13],
              [173.6, 0.21],
              [173.63, 0.22],
              [173.62, 0.13]
            ]
          ],
          [
            [
              [173.02, 1.01],
              [173.08, 0.95],
              [172.98, 0.82],
              [173.02, 1.01]
            ]
          ],
          [
            [
              [173.01, 1.71],
              [172.93, 1.94],
              [173.03, 1.82],
              [173.01, 1.71]
            ]
          ],
          [
            [
              [172.87, 3.06],
              [172.77, 3],
              [172.75, 3.02],
              [172.87, 3.06]
            ]
          ]
        ]
      },
      "properties": { "name": "Kiribati" },
      "id": "KI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [23.86, 35.52],
              [26.29, 35.13],
              [24.75, 34.94],
              [23.52, 35.29],
              [23.86, 35.52]
            ]
          ],
          [
            [
              [23.46, 38.85],
              [24.56, 37.99],
              [22.83, 38.83],
              [23.46, 38.85]
            ]
          ],
          [
            [
              [26.29, 41.71],
              [26.36, 41.71],
              [26.63, 41.35],
              [26.04, 40.74],
              [23.74, 40.75],
              [24.4, 40.15],
              [23.35, 40.25],
              [23.71, 39.91],
              [22.59, 40.48],
              [23.34, 39.18],
              [22.94, 39.36],
              [23.07, 39.04],
              [22.52, 38.86],
              [24.07, 38.2],
              [24.03, 37.65],
              [23.52, 38.04],
              [22.99, 37.88],
              [23.51, 37.43],
              [22.73, 37.57],
              [23.2, 36.43],
              [22.63, 36.8],
              [22.49, 36.39],
              [22.15, 37.02],
              [21.7, 36.82],
              [21.11, 37.85],
              [21.86, 38.34],
              [23.23, 38.15],
              [22.4, 38.45],
              [21.15, 38.3],
              [20.73, 38.8],
              [21.15, 39],
              [20.01, 39.69],
              [20.98, 40.86],
              [22.94, 41.34],
              [25.29, 41.24],
              [26.29, 41.71]
            ]
          ]
        ]
      },
      "properties": { "name": "Greece" },
      "id": "GR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-52.69, 69.92],
              [-51.83, 69.63],
              [-53.57, 69.23],
              [-54.27, 69.4],
              [-53.35, 69.58],
              [-54.99, 69.69],
              [-54.39, 69.68],
              [-54.94, 69.85],
              [-54.23, 69.91],
              [-54.83, 70.08],
              [-54.43, 70.31],
              [-52.69, 69.92]
            ]
          ],
          [
            [
              [-23.61, 72.83],
              [-21.93, 72.4],
              [-22.76, 72.44],
              [-22.13, 72.27],
              [-22.56, 72.14],
              [-24.48, 72.83],
              [-23.61, 72.83]
            ]
          ],
          [
            [
              [-32.3, 83.57],
              [-25.65, 83.29],
              [-35.62, 82.9],
              [-25.14, 83.16],
              [-24.75, 83],
              [-25.9, 82.78],
              [-21.31, 82.61],
              [-25.07, 82.15],
              [-31.62, 82.21],
              [-29.9, 82.09],
              [-33.1, 81.77],
              [-25.2, 81.99],
              [-27.63, 81.49],
              [-27.33, 81.38],
              [-22.02, 81.93],
              [-22.23, 81.47],
              [-24.51, 80.54],
              [-19.94, 81.68],
              [-20.31, 81.45],
              [-16.7, 81.93],
              [-12.16, 81.6],
              [-16.01, 80.73],
              [-21.25, 80.58],
              [-16.11, 80.5],
              [-20.55, 80.11],
              [-20.33, 79.76],
              [-17.45, 80.06],
              [-19.63, 79.66],
              [-19.87, 79.15],
              [-19.08, 79.2],
              [-21.18, 78.81],
              [-20.91, 78.62],
              [-22.04, 77.69],
              [-19.24, 77.76],
              [-18.96, 77.63],
              [-21.05, 77.54],
              [-18.4, 77.34],
              [-18.31, 76.81],
              [-22.74, 76.7],
              [-19.81, 76.23],
              [-21.98, 75.99],
              [-19.83, 75.91],
              [-19.34, 75.4],
              [-19.88, 75.15],
              [-22.25, 75.66],
              [-20.52, 75.14],
              [-22.44, 75.16],
              [-18.98, 74.48],
              [-22.48, 74.31],
              [-21.82, 73.65],
              [-21.75, 74.06],
              [-20.28, 73.88],
              [-20.5, 73.45],
              [-22.38, 73.25],
              [-24.03, 73.7],
              [-22.18, 73.62],
              [-24.04, 73.81],
              [-24.46, 73.54],
              [-25.69, 73.95],
              [-24.67, 73.51],
              [-27.34, 73.49],
              [-26.39, 73.24],
              [-27.73, 73.13],
              [-25.05, 73.08],
              [-27.39, 72.84],
              [-24.84, 72.72],
              [-24.61, 72.52],
              [-25.91, 72.41],
              [-22.49, 71.89],
              [-23.13, 71.63],
              [-21.9, 71.74],
              [-22.47, 71.26],
              [-21.81, 71.51],
              [-21.69, 71.15],
              [-22.33, 71.05],
              [-21.68, 71.07],
              [-21.93, 70.8],
              [-21.47, 70.54],
              [-22.38, 70.44],
              [-22.51, 70.85],
              [-22.62, 70.45],
              [-23.35, 70.44],
              [-24.74, 71.33],
              [-28.64, 72.12],
              [-27.33, 71.71],
              [-28.47, 71.55],
              [-25.41, 71.35],
              [-28.41, 70.98],
              [-27.91, 70.87],
              [-29.21, 70.39],
              [-26.32, 70.37],
              [-28.54, 70.04],
              [-25.23, 70.41],
              [-22.08, 70.13],
              [-26.36, 68.67],
              [-29.38, 68.2],
              [-31.57, 68.07],
              [-32.49, 68.62],
              [-32.12, 67.86],
              [-33.2, 67.69],
              [-34.72, 66.34],
              [-35.85, 66.43],
              [-35.59, 66.11],
              [-37.19, 65.77],
              [-37.81, 66.03],
              [-37.18, 66.34],
              [-38.11, 66.39],
              [-37.69, 66.26],
              [-38.48, 66.01],
              [-38.24, 65.63],
              [-40.1, 65.57],
              [-39.76, 65.24],
              [-41.16, 64.96],
              [-40.36, 64.35],
              [-41.57, 64.27],
              [-40.57, 64.11],
              [-40.52, 63.7],
              [-41.62, 63.79],
              [-40.75, 63.51],
              [-41.91, 63.46],
              [-41.43, 63.13],
              [-42.17, 63.2],
              [-41.76, 62.84],
              [-43.15, 62.76],
              [-42.16, 62.38],
              [-42.98, 62.52],
              [-42.12, 62.01],
              [-43.08, 61.59],
              [-42.51, 61.36],
              [-43.25, 61.34],
              [-42.63, 61.1],
              [-43.61, 61.13],
              [-42.7, 61.06],
              [-43.53, 60.84],
              [-42.75, 60.68],
              [-44.2, 60.59],
              [-43.14, 60.08],
              [-44.1, 60.38],
              [-45.15, 60.07],
              [-44.47, 60.56],
              [-45.19, 60.13],
              [-44.63, 60.73],
              [-45.98, 60.57],
              [-45.25, 60.9],
              [-46.22, 60.75],
              [-45.2, 61.19],
              [-46.07, 60.92],
              [-45.77, 61.33],
              [-46.86, 60.8],
              [-48.24, 60.82],
              [-47.69, 61],
              [-48.41, 60.99],
              [-47.92, 61.32],
              [-49.3, 61.56],
              [-48.6, 61.64],
              [-49.15, 61.72],
              [-48.76, 61.98],
              [-49.44, 61.84],
              [-48.84, 62.08],
              [-49.67, 62],
              [-49.29, 62.17],
              [-50.32, 62.5],
              [-49.7, 63.06],
              [-50.38, 62.78],
              [-50.15, 63.02],
              [-50.61, 63.09],
              [-50.06, 63.23],
              [-51.11, 63.34],
              [-50.28, 63.4],
              [-51.22, 63.44],
              [-50.5, 63.67],
              [-51.56, 63.71],
              [-50.92, 63.93],
              [-51.6, 64.03],
              [-50.05, 64.19],
              [-51.76, 64.18],
              [-50.17, 64.45],
              [-50.86, 64.63],
              [-49.58, 64.34],
              [-50, 64.87],
              [-50.98, 65.22],
              [-50.64, 64.75],
              [-52.01, 64.2],
              [-52.12, 64.72],
              [-51.25, 65.02],
              [-52.21, 64.81],
              [-52.1, 65.24],
              [-52.56, 65.33],
              [-50.55, 65.71],
              [-52.5, 65.39],
              [-53.27, 65.75],
              [-51.83, 66.06],
              [-53.46, 66.03],
              [-50, 66.98],
              [-53.48, 66.1],
              [-53.12, 66.29],
              [-53.63, 66.5],
              [-52.42, 66.55],
              [-53.45, 66.64],
              [-52.23, 66.84],
              [-53.97, 67.07],
              [-50.35, 67.18],
              [-53.88, 67.26],
              [-52.5, 67.77],
              [-50.07, 67.51],
              [-51.23, 67.7],
              [-50.42, 67.84],
              [-51.06, 67.97],
              [-53.75, 67.6],
              [-53.19, 68.04],
              [-52.06, 67.98],
              [-53.32, 68.18],
              [-50.15, 67.93],
              [-51.43, 68.2],
              [-50.82, 68.5],
              [-53.39, 68.33],
              [-50.87, 68.61],
              [-51.29, 68.75],
              [-51.07, 69.13],
              [-50.21, 68.96],
              [-50.69, 69.12],
              [-50.38, 69.34],
              [-51.12, 69.2],
              [-50.2, 69.52],
              [-50.88, 69.49],
              [-50.21, 70.02],
              [-54.63, 70.65],
              [-50.49, 70.51],
              [-51.95, 71.02],
              [-50.93, 70.99],
              [-51.23, 71.14],
              [-52.25, 71.12],
              [-51.65, 71.36],
              [-52.56, 71.17],
              [-51.35, 71.49],
              [-52.98, 71.42],
              [-51.64, 71.71],
              [-53.25, 71.7],
              [-52.68, 72],
              [-53.32, 71.82],
              [-53.56, 72.36],
              [-53.96, 72.32],
              [-53.4, 71.85],
              [-54.1, 71.71],
              [-53.92, 71.44],
              [-55.91, 71.68],
              [-54.38, 72.22],
              [-55.3, 71.93],
              [-54.68, 72.37],
              [-55.63, 72.46],
              [-54.3, 72.48],
              [-55.7, 73.07],
              [-55.09, 73.36],
              [-56.08, 73.65],
              [-55.61, 73.72],
              [-56.41, 74.07],
              [-56.13, 74.28],
              [-57.33, 74.11],
              [-56.19, 74.55],
              [-58.7, 75.35],
              [-58.21, 75.44],
              [-58.41, 75.72],
              [-60.88, 76.15],
              [-68.5, 76.09],
              [-69.63, 76.38],
              [-67.98, 76.68],
              [-71.38, 77.06],
              [-66.45, 77.13],
              [-69.1, 77.27],
              [-66.06, 77.49],
              [-69.25, 77.45],
              [-73.05, 78.16],
              [-65.98, 79.1],
              [-64.82, 79.53],
              [-65.07, 80.01],
              [-63.78, 80.15],
              [-67.48, 80.33],
              [-63.68, 81.14],
              [-62.79, 80.75],
              [-63.37, 81.16],
              [-61.06, 81.12],
              [-61.31, 81.36],
              [-60.77, 81.5],
              [-61.45, 81.75],
              [-60.81, 81.88],
              [-56.48, 81.33],
              [-59.47, 82],
              [-54.51, 82.37],
              [-53.56, 82.12],
              [-53.63, 81.51],
              [-52.9, 82.03],
              [-49.61, 81.64],
              [-51.07, 81.93],
              [-49.43, 81.93],
              [-51.12, 82.49],
              [-50.32, 82.52],
              [-44.64, 81.75],
              [-44.18, 81.83],
              [-44.93, 81.99],
              [-44.62, 82.28],
              [-42.3, 82.22],
              [-45.77, 82.76],
              [-39.75, 82.4],
              [-46.89, 82.96],
              [-43.39, 82.91],
              [-45.52, 83.12],
              [-42.7, 83.27],
              [-38.57, 82.74],
              [-39.15, 82.98],
              [-36.88, 83.15],
              [-38.86, 83.43],
              [-32.3, 83.57]
            ]
          ]
        ]
      },
      "properties": { "name": "Greenland" },
      "id": "GL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-61.75, 12],
              [-61.61, 12.23],
              [-61.63, 12.05],
              [-61.75, 12]
            ]
          ],
          [
            [
              [-61.43, 12.45],
              [-61.5, 12.44],
              [-61.43, 12.53],
              [-61.43, 12.45]
            ]
          ]
        ]
      },
      "properties": { "name": "Grenada" },
      "id": "GD"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-61.35, 16.33],
              [-61.7, 15.95],
              [-61.78, 16.33],
              [-61.35, 16.33]
            ]
          ]
        ]
      },
      "properties": { "name": "Guadeloupe" },
      "id": "GP"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [144.71, 13.23],
              [144.66, 13.43],
              [144.88, 13.65],
              [144.71, 13.23]
            ]
          ]
        ]
      },
      "properties": { "name": "Guam" },
      "id": "GU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-90.63, 13.93],
              [-92.25, 14.55],
              [-91.73, 16.07],
              [-90.44, 16.09],
              [-91.44, 17.24],
              [-90.98, 17.26],
              [-90.98, 17.82],
              [-89.14, 17.82],
              [-89.22, 15.89],
              [-88.91, 15.89],
              [-88.21, 15.72],
              [-89.35, 14.43],
              [-90.1, 13.75],
              [-90.63, 13.93]
            ]
          ]
        ]
      },
      "properties": { "name": "Guatemala" },
      "id": "GT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-13.6, 9.73],
              [-15.02, 10.96],
              [-14.69, 11.51],
              [-13.71, 11.72],
              [-13.71, 12.68],
              [-11.37, 12.41],
              [-10.65, 11.89],
              [-9.16, 12.49],
              [-8.53, 11.49],
              [-8.68, 10.97],
              [-8.29, 11.01],
              [-7.97, 10.17],
              [-7.65, 8.38],
              [-8.2, 8.5],
              [-7.95, 8.02],
              [-8.47, 7.56],
              [-9.49, 7.36],
              [-9.48, 8.35],
              [-10.27, 8.49],
              [-10.7, 8.3],
              [-10.57, 9.06],
              [-11.21, 10],
              [-12.46, 9.89],
              [-13.3, 9.03],
              [-13.6, 9.73]
            ]
          ]
        ]
      },
      "properties": { "name": "Guinea" },
      "id": "GN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-59.79, 8.34],
              [-58.47, 7.35],
              [-58.65, 6.43],
              [-58.31, 6.89],
              [-57.2, 6.15],
              [-57.25, 5.49],
              [-58.05, 4.01],
              [-56.47, 1.94],
              [-58.81, 1.19],
              [-59.64, 1.73],
              [-59.99, 2.69],
              [-59.57, 3.9],
              [-60.15, 4.52],
              [-60.1, 5.22],
              [-60.73, 5.2],
              [-61.39, 5.94],
              [-61.13, 6.71],
              [-60.29, 7.06],
              [-60.72, 7.54],
              [-59.83, 8.24],
              [-59.99, 8.54],
              [-59.79, 8.34]
            ]
          ]
        ]
      },
      "properties": { "name": "Guyana" },
      "id": "GY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-72.67, 19.92],
              [-71.75, 19.71],
              [-71.77, 18.04],
              [-74.45, 18.34],
              [-74.27, 18.67],
              [-72.35, 18.53],
              [-72.72, 19.45],
              [-73.47, 19.69],
              [-72.67, 19.92]
            ]
          ]
        ]
      },
      "properties": { "name": "Haiti" },
      "id": "HT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [73.77, -53.13],
              [73.47, -53.19],
              [73.23, -52.99],
              [73.77, -53.13]
            ]
          ]
        ]
      },
      "properties": { "name": "Heard Island and McDonald Islands" },
      "id": "HM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [12.45, 41.9],
              [12.45, 41.91],
              [12.46, 41.9],
              [12.45, 41.9]
            ]
          ]
        ]
      },
      "properties": { "name": "Holy See (Vatican City)" },
      "id": "VA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-85.84, 16.01],
              [-84.26, 15.83],
              [-83.13, 14.99],
              [-83.24, 14.98],
              [-84.91, 14.81],
              [-87.3, 12.99],
              [-87.82, 13.41],
              [-87.75, 13.86],
              [-89.34, 14.42],
              [-89.35, 14.43],
              [-88.21, 15.72],
              [-85.84, 16.01]
            ]
          ]
        ]
      },
      "properties": { "name": "Honduras" },
      "id": "HN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [114.25, 22.2],
              [114.12, 22.28],
              [114.2, 22.29],
              [114.25, 22.2]
            ]
          ],
          [
            [
              [113.9, 22.2],
              [113.83, 22.23],
              [114.05, 22.34],
              [113.9, 22.2]
            ]
          ],
          [
            [
              [114.22, 22.47],
              [114.03, 22.51],
              [114.22, 22.55],
              [114.22, 22.47]
            ]
          ]
        ]
      },
      "properties": { "name": "Hong Kong" },
      "id": "HK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [18.82, 45.91],
              [17.67, 45.83],
              [16.61, 46.48],
              [16.11, 46.87],
              [16.51, 47.01],
              [16.45, 47.7],
              [17.17, 48.01],
              [17.25, 48.02],
              [18.66, 47.76],
              [20.66, 48.56],
              [22.15, 48.41],
              [22.89, 47.95],
              [20.73, 46.18],
              [20.26, 46.11],
              [18.82, 45.91]
            ]
          ]
        ]
      },
      "properties": { "name": "Hungary" },
      "id": "HU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-15.07, 66.14],
              [-14.34, 65.78],
              [-14.56, 65.49],
              [-13.61, 65.51],
              [-14.03, 65.19],
              [-13.5, 65.07],
              [-14.93, 64.26],
              [-18.71, 63.39],
              [-21.05, 63.95],
              [-22.69, 63.81],
              [-21.51, 64.65],
              [-24.06, 64.89],
              [-21.84, 65.03],
              [-22.56, 65.17],
              [-21.7, 65.45],
              [-24.54, 65.5],
              [-23.23, 65.74],
              [-23.87, 65.87],
              [-23.21, 65.84],
              [-23.82, 66.01],
              [-23.47, 66.2],
              [-22.42, 65.85],
              [-22.94, 66.47],
              [-21.4, 66.03],
              [-21.78, 65.77],
              [-21.08, 65.16],
              [-20.18, 66.13],
              [-19.45, 65.73],
              [-18.78, 66.19],
              [-18.07, 65.64],
              [-18.3, 66.17],
              [-16.59, 66.09],
              [-16.53, 66.51],
              [-14.71, 66.37],
              [-15.07, 66.14]
            ]
          ]
        ]
      },
      "properties": { "name": "Iceland" },
      "id": "IS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [78.08, 35.45],
              [79.53, 32.75],
              [78.4, 32.55],
              [78.77, 31.31],
              [81.03, 30.2],
              [80.06, 28.84],
              [82.07, 27.91],
              [83.29, 27.34],
              [84.15, 27.51],
              [85.86, 26.57],
              [88.01, 26.36],
              [88.14, 27.87],
              [88.83, 28.01],
              [88.92, 27.32],
              [89.64, 26.72],
              [92.07, 26.86],
              [91.66, 27.76],
              [92.54, 27.86],
              [94.65, 29.33],
              [95.39, 29.04],
              [96.08, 29.47],
              [96.62, 28.79],
              [96.4, 28.35],
              [97.35, 28.22],
              [96.89, 27.61],
              [97.14, 27.09],
              [96.19, 27.27],
              [95.14, 26.61],
              [94.15, 23.86],
              [93.34, 24.08],
              [93.2, 22.26],
              [92.6, 21.98],
              [92.28, 23.71],
              [91.61, 22.94],
              [91.16, 23.64],
              [92.41, 25.03],
              [89.85, 25.29],
              [89.74, 26.16],
              [88.43, 26.55],
              [88.11, 25.84],
              [89.01, 25.29],
              [88.04, 24.68],
              [88.75, 24.22],
              [89.06, 22.12],
              [89.07, 21.61],
              [88.71, 21.57],
              [88.67, 22.2],
              [88.25, 21.55],
              [88.2, 22.16],
              [87.91, 22.42],
              [88.17, 22.09],
              [86.96, 21.38],
              [87.03, 20.67],
              [86.42, 19.98],
              [85.43, 19.89],
              [82.36, 17.1],
              [82.3, 16.58],
              [80.28, 15.7],
              [79.86, 10.29],
              [79.32, 10.28],
              [78.91, 9.48],
              [79.45, 9.15],
              [78.4, 9.09],
              [77.54, 8.07],
              [76.58, 8.88],
              [73.45, 16.06],
              [72.66, 19.87],
              [72.93, 20.77],
              [72.56, 21.38],
              [73.13, 21.75],
              [72.5, 21.98],
              [72.92, 22.27],
              [72.15, 22.28],
              [72.11, 21.2],
              [70.82, 20.7],
              [68.94, 22.29],
              [70.17, 22.55],
              [70.51, 23.1],
              [69.22, 22.84],
              [68.43, 23.43],
              [68.74, 23.84],
              [68.2, 23.77],
              [68.78, 24.33],
              [71.11, 24.42],
              [69.58, 27.17],
              [70.37, 28.02],
              [71.9, 27.96],
              [74.69, 31.05],
              [74.61, 31.88],
              [75.38, 32.21],
              [74.02, 33.19],
              [74.3, 33.98],
              [73.94, 34.65],
              [76.87, 34.66],
              [77.82, 35.5],
              [78.08, 35.45]
            ]
          ]
        ]
      },
      "properties": { "name": "India" },
      "id": "IN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [120.03, -9.38],
              [120.72, -10.2],
              [118.93, -9.56],
              [120.03, -9.38]
            ]
          ],
          [
            [
              [125.16, -9.07],
              [125.13, -9.44],
              [124.44, -10.16],
              [123.49, -10.32],
              [124.05, -9.34],
              [124.34, -9.46],
              [124.45, -9.18],
              [124.95, -8.95],
              [125.16, -9.07]
            ]
          ],
          [
            [
              [116.55, -8.78],
              [115.84, -8.76],
              [116.39, -8.2],
              [116.55, -8.78]
            ]
          ],
          [
            [
              [124.58, -8.14],
              [125.14, -8.33],
              [124.35, -8.45],
              [124.58, -8.14]
            ]
          ],
          [
            [
              [118.31, -8.37],
              [119, -8.31],
              [119.18, -8.72],
              [116.75, -9.01],
              [117.12, -8.38],
              [117.97, -8.75],
              [118.28, -8.59],
              [117.74, -8.15],
              [118.31, -8.37]
            ]
          ],
          [
            [
              [122.98, -8.15],
              [122.83, -8.6],
              [121.77, -8.89],
              [119.8, -8.72],
              [120.52, -8.26],
              [122.29, -8.64],
              [122.98, -8.15]
            ]
          ],
          [
            [
              [115.5, -8.18],
              [115.13, -8.85],
              [114.45, -8.1],
              [115.5, -8.18]
            ]
          ],
          [
            [
              [126.72, -7.67],
              [125.77, -8.01],
              [125.97, -7.66],
              [126.72, -7.67]
            ]
          ],
          [
            [
              [138.97, -7.56],
              [138.44, -8.38],
              [137.64, -8.43],
              [138.15, -7.51],
              [138.97, -7.56]
            ]
          ],
          [
            [
              [131.74, -7.21],
              [131.11, -8],
              [131.24, -7.48],
              [131.74, -7.21]
            ]
          ],
          [
            [
              [113.99, -6.88],
              [113.5, -7.23],
              [112.69, -7.05],
              [113.99, -6.88]
            ]
          ],
          [
            [
              [106.16, -6.01],
              [108.31, -6.26],
              [108.73, -6.82],
              [110.39, -6.98],
              [110.92, -6.41],
              [112.56, -6.91],
              [112.85, -7.6],
              [114.45, -7.8],
              [114.37, -8.52],
              [114.62, -8.75],
              [108.16, -7.78],
              [105.24, -6.81],
              [106.16, -6.01]
            ]
          ],
          [
            [
              [134.58, -5.43],
              [134.73, -5.97],
              [134.3, -6.03],
              [134.58, -5.43]
            ]
          ],
          [
            [
              [123.21, -4.7],
              [122.65, -5.69],
              [122.9, -4.49],
              [123.21, -4.7]
            ]
          ],
          [
            [
              [126.99, -3.14],
              [127.24, -3.62],
              [126.7, -3.83],
              [126.02, -3.35],
              [126.99, -3.14]
            ]
          ],
          [
            [
              [129.81, -2.92],
              [130.58, -3.13],
              [130.83, -3.87],
              [128.17, -3.07],
              [127.91, -3.54],
              [128.17, -2.86],
              [129.81, -2.92]
            ]
          ],
          [
            [
              [107.83, -2.54],
              [108.27, -2.76],
              [108.07, -3.24],
              [107.61, -3.21],
              [107.83, -2.54]
            ]
          ],
          [
            [
              [133.59, -2.53],
              [133.5, -2.43],
              [133.61, -2.48],
              [133.59, -2.53]
            ]
          ],
          [
            [
              [134.57, -2.45],
              [134.52, -2.4],
              [134.57, -2.29],
              [134.57, -2.45]
            ]
          ],
          [
            [
              [134.37, -2.16],
              [134.33, -2.09],
              [134.39, -2],
              [134.37, -2.16]
            ]
          ],
          [
            [
              [130.19, -2.06],
              [129.72, -1.89],
              [130.35, -1.68],
              [130.19, -2.06]
            ]
          ],
          [
            [
              [125.01, -1.72],
              [125.32, -1.89],
              [124.33, -1.88],
              [125.01, -1.72]
            ]
          ],
          [
            [
              [136.29, -1.69],
              [136.9, -1.8],
              [135.42, -1.61],
              [136.29, -1.69]
            ]
          ],
          [
            [
              [106.09, -1.77],
              [106.72, -3.1],
              [105.13, -2.07],
              [105.57, -1.53],
              [106.09, -1.77]
            ]
          ],
          [
            [
              [127.88, -1.43],
              [128.15, -1.68],
              [127.38, -1.63],
              [127.88, -1.43]
            ]
          ],
          [
            [
              [133.11, -0.54],
              [134.11, -0.84],
              [134.16, -2.32],
              [135, -3.34],
              [137.86, -1.47],
              [141, -2.61],
              [141.01, -9.13],
              [139.99, -8.19],
              [140.15, -7.88],
              [138.91, -8.3],
              [139.1, -7.56],
              [138.66, -7.2],
              [139.22, -7.16],
              [138.56, -6.91],
              [139.19, -6.97],
              [138.06, -5.41],
              [134.22, -3.96],
              [133.64, -3.49],
              [133.83, -2.96],
              [132.9, -4.09],
              [132.82, -3.3],
              [131.96, -2.78],
              [133.24, -2.42],
              [133.68, -2.72],
              [133.94, -2.1],
              [132.3, -2.27],
              [130.96, -1.4],
              [132.27, -0.38],
              [133.11, -0.54]
            ]
          ],
          [
            [
              [130.88, -0.02],
              [131.26, -0.39],
              [130.21, -0.21],
              [130.88, -0.02]
            ]
          ],
          [
            [
              [97.53, 1.42],
              [97.81, 0.55],
              [97.11, 1.39],
              [97.53, 1.42]
            ]
          ],
          [
            [
              [125.14, 1.42],
              [124.25, 0.38],
              [120.24, 0.35],
              [120.07, -0.61],
              [120.67, -1.4],
              [121.62, -0.8],
              [123.45, -0.84],
              [121.3, -1.8],
              [122.9, -4.4],
              [121.55, -4.75],
              [120.77, -2.61],
              [120.2, -2.96],
              [120.46, -5.62],
              [119.46, -5.56],
              [119.51, -3.53],
              [118.92, -3.57],
              [118.76, -2.77],
              [119.86, -0.84],
              [119.62, -0.01],
              [120.03, 0.71],
              [120.95, 1.34],
              [123.84, 0.83],
              [124.97, 1.69],
              [125.14, 1.42]
            ]
          ],
          [
            [
              [127.89, 1.8],
              [127.79, 0.8],
              [128.73, 1.56],
              [128.21, 0.78],
              [128.91, 0.2],
              [127.88, 0.31],
              [128.4, -0.89],
              [127.67, -0.23],
              [127.39, 1.05],
              [128.05, 2.2],
              [127.89, 1.8]
            ]
          ],
          [
            [
              [116.05, 4.28],
              [117.59, 4.17],
              [117.83, 3.7],
              [117.03, 3.59],
              [118.1, 2.31],
              [117.87, 1.88],
              [119.01, 0.98],
              [117.89, 1.12],
              [117.62, -0.78],
              [116.74, -1.02],
              [116.22, -1.78],
              [116.6, -2.23],
              [115.98, -3.59],
              [114.71, -4.18],
              [114.48, -3.5],
              [113.06, -2.99],
              [111.89, -3.57],
              [111.75, -2.74],
              [110.24, -2.98],
              [110.06, -1.33],
              [109.27, -0.86],
              [108.85, 0.81],
              [109.65, 2.07],
              [110.56, 0.85],
              [111.83, 1],
              [112.47, 1.57],
              [114.56, 1.43],
              [116.05, 4.28]
            ]
          ],
          [
            [
              [95.74, 5.59],
              [97.52, 5.25],
              [100, 2.6],
              [100.94, 1.82],
              [101.06, 2.29],
              [102.93, 0.7],
              [102.54, 0.17],
              [103.74, 0.28],
              [103.36, -0.7],
              [104.38, -1.04],
              [104.88, -2.15],
              [104.53, -2.77],
              [104.86, -2.29],
              [105.61, -2.39],
              [106.06, -3.03],
              [105.73, -5.9],
              [105.27, -5.44],
              [105.14, -5.8],
              [104.54, -5.51],
              [104.56, -5.93],
              [101.63, -3.25],
              [100.29, -0.81],
              [99.14, 0.26],
              [98.77, 1.75],
              [95.53, 4.68],
              [95.23, 5.57],
              [95.74, 5.59]
            ]
          ]
        ]
      },
      "properties": { "name": "Indonesia" },
      "id": "ID"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [45, 39.42],
              [46.18, 38.84],
              [46.54, 38.88],
              [47.98, 39.72],
              [48.36, 39.39],
              [48.02, 38.84],
              [48.89, 38.44],
              [49.1, 37.64],
              [51.1, 36.73],
              [53.94, 36.8],
              [53.91, 37.35],
              [57.21, 38.28],
              [59.34, 37.54],
              [60.33, 36.66],
              [61.16, 36.65],
              [61.28, 35.61],
              [60.51, 34.14],
              [60.94, 33.52],
              [60.58, 33.07],
              [60.84, 31.5],
              [61.85, 31.02],
              [60.87, 29.86],
              [61.91, 28.55],
              [62.78, 28.27],
              [62.78, 27.26],
              [63.34, 27.12],
              [63.18, 26.63],
              [61.86, 26.23],
              [61.61, 25.2],
              [57.32, 25.77],
              [56.69, 27.15],
              [54.79, 26.49],
              [53.75, 26.71],
              [51.43, 27.94],
              [50.05, 30.21],
              [49.55, 30.01],
              [48.98, 30.51],
              [48.55, 29.96],
              [47.69, 31],
              [47.86, 31.8],
              [47.43, 32.4],
              [46.11, 32.97],
              [45.4, 33.98],
              [45.8, 34.91],
              [46.35, 35.82],
              [45.41, 35.99],
              [44.79, 37.15],
              [44.03, 39.38],
              [44.81, 39.63],
              [45, 39.42]
            ]
          ]
        ]
      },
      "properties": { "name": "Iran" },
      "id": "IR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [45.8, 34.91],
              [45.4, 33.98],
              [46.11, 32.97],
              [47.43, 32.4],
              [47.86, 31.8],
              [47.69, 31],
              [48.55, 29.96],
              [47.94, 30.02],
              [47.93, 30.02],
              [47.17, 30.02],
              [46.55, 29.1],
              [44.72, 29.2],
              [42.08, 31.11],
              [39.2, 32.15],
              [38.79, 33.38],
              [41, 34.42],
              [41.29, 36.36],
              [42.36, 37.11],
              [44.79, 37.15],
              [45.41, 35.99],
              [46.35, 35.82],
              [45.8, 34.91]
            ]
          ]
        ]
      },
      "properties": { "name": "Iraq" },
      "id": "IQ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-7.41, 54.95],
              [-8.16, 54.44],
              [-6.27, 54.1],
              [-6.01, 52.95],
              [-6.36, 52.18],
              [-9.23, 51.48],
              [-10.34, 51.78],
              [-9.76, 52.15],
              [-10.46, 52.18],
              [-8.82, 52.67],
              [-9.94, 52.56],
              [-8.94, 53.26],
              [-10.18, 53.41],
              [-9.56, 53.86],
              [-10.11, 54.23],
              [-8.47, 54.27],
              [-8.19, 54.63],
              [-8.8, 54.69],
              [-8.32, 55.11],
              [-6.93, 55.24],
              [-7.25, 55.07],
              [-7.41, 54.95]
            ]
          ]
        ]
      },
      "properties": { "name": "Ireland" },
      "id": "IE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [35.26, 31.79],
              [35.25, 31.79],
              [35.25, 31.81],
              [35.26, 31.79]
            ]
          ],
          [
            [
              [35.62, 33.25],
              [35.65, 32.69],
              [35.55, 32.39],
              [35.28, 32.52],
              [34.88, 31.39],
              [35.48, 31.5],
              [34.98, 29.55],
              [34.9, 29.49],
              [34.27, 31.22],
              [34.33, 31.26],
              [34.49, 31.6],
              [35.1, 33.09],
              [35.62, 33.25]
            ]
          ]
        ]
      },
      "properties": { "name": "Israel" },
      "id": "IL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [15.53, 38.14],
              [15.08, 36.65],
              [12.42, 37.8],
              [13.32, 38.22],
              [15.53, 38.14]
            ]
          ],
          [
            [
              [9.51, 41.15],
              [9.57, 39.15],
              [8.41, 38.96],
              [8.19, 40.91],
              [9.51, 41.15]
            ]
          ],
          [
            [
              [12.13, 47],
              [13.72, 46.53],
              [13.38, 46.3],
              [13.72, 45.6],
              [12.28, 45.47],
              [12.37, 44.25],
              [13.62, 43.55],
              [14.74, 42.09],
              [16.14, 41.91],
              [15.93, 41.48],
              [18.51, 40.14],
              [18.35, 39.79],
              [16.91, 40.45],
              [16.49, 39.77],
              [17.17, 38.96],
              [16.06, 37.92],
              [15.63, 38.01],
              [16.22, 38.91],
              [15.67, 40.03],
              [11.1, 42.39],
              [10.11, 44.01],
              [8.75, 44.43],
              [7.53, 43.79],
              [7.66, 44.17],
              [6.98, 44.28],
              [6.62, 45.11],
              [7.13, 45.26],
              [7.04, 45.93],
              [7.86, 45.92],
              [8.44, 46.46],
              [9.04, 45.84],
              [9.28, 46.5],
              [10.13, 46.23],
              [10.47, 46.87],
              [12.13, 47]
            ],
            [
              [12.46, 43.9],
              [12.51, 43.99],
              [12.42, 43.96],
              [12.46, 43.9]
            ],
            [
              [12.45, 41.9],
              [12.46, 41.9],
              [12.45, 41.91],
              [12.45, 41.9]
            ]
          ]
        ]
      },
      "properties": { "name": "Italy" },
      "id": "IT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-4.79, 5.17],
              [-7.53, 4.35],
              [-7.42, 5.84],
              [-8.61, 6.51],
              [-8.47, 7.56],
              [-7.95, 8.02],
              [-8.2, 8.5],
              [-7.65, 8.38],
              [-7.97, 10.17],
              [-6.99, 10.15],
              [-6.24, 10.74],
              [-6.11, 10.2],
              [-5.52, 10.44],
              [-4.7, 9.7],
              [-3.63, 9.95],
              [-2.69, 9.48],
              [-2.49, 8.2],
              [-3.25, 6.61],
              [-2.93, 5.1],
              [-4.79, 5.17]
            ]
          ]
        ]
      },
      "properties": { "name": "Cote d'Ivoire" },
      "id": "CI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-77.13, 17.88],
              [-78.34, 18.36],
              [-76.95, 18.39],
              [-76.22, 17.9],
              [-77.13, 17.88]
            ]
          ]
        ]
      },
      "properties": { "name": "Jamaica" },
      "id": "JM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [131.21, 33.6],
              [131.67, 33.65],
              [131.99, 32.83],
              [131.34, 31.37],
              [130.67, 31],
              [130.81, 31.68],
              [130.64, 31.18],
              [130.23, 31.25],
              [130.16, 32.01],
              [130.61, 32.79],
              [130.21, 33.17],
              [130.35, 32.66],
              [129.75, 32.56],
              [129.58, 33.35],
              [130.69, 33.94],
              [131.21, 33.6]
            ]
          ],
          [
            [
              [134.22, 34.34],
              [134.75, 33.83],
              [134.19, 33.24],
              [133.6, 33.5],
              [132.8, 32.74],
              [132.37, 33.47],
              [132.02, 33.34],
              [132.9, 34.11],
              [134.22, 34.34]
            ]
          ],
          [
            [
              [141.27, 41.34],
              [142.07, 39.55],
              [141.53, 38.27],
              [140.95, 38.15],
              [140.84, 35.74],
              [140.33, 35.13],
              [139.77, 34.95],
              [139.97, 35.66],
              [138.85, 34.59],
              [138.74, 35.12],
              [138.21, 34.6],
              [137.04, 34.56],
              [137.35, 34.72],
              [136.85, 35.08],
              [136.52, 34.69],
              [136.9, 34.27],
              [135.77, 33.45],
              [135.06, 33.88],
              [135.33, 34.72],
              [132.37, 34.36],
              [132.05, 33.77],
              [130.89, 33.92],
              [130.95, 34.42],
              [133.09, 35.58],
              [136.07, 35.65],
              [136.79, 37.36],
              [137.36, 37.5],
              [136.86, 37.09],
              [137.3, 36.75],
              [138.58, 37.4],
              [140.02, 39.38],
              [139.85, 40.6],
              [140.35, 41.25],
              [141.15, 40.86],
              [141.23, 41.23],
              [140.76, 41.17],
              [141.27, 41.34]
            ]
          ],
          [
            [
              [142.05, 45.4],
              [143.77, 44.09],
              [144.78, 43.91],
              [145.34, 44.34],
              [145.26, 43.31],
              [145.82, 43.37],
              [143.99, 42.91],
              [143.24, 41.92],
              [141.79, 42.61],
              [140.46, 42.57],
              [140.28, 42.25],
              [141.2, 41.8],
              [140.07, 41.42],
              [139.84, 42.62],
              [140.47, 43.37],
              [141.41, 43.29],
              [141.58, 45.23],
              [142.05, 45.4]
            ]
          ]
        ]
      },
      "properties": { "name": "Japan" },
      "id": "JP"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [69.19, 55.34],
              [70.84, 55.3],
              [71.19, 54.1],
              [73.76, 54.07],
              [73.44, 53.44],
              [76.81, 54.45],
              [76.52, 53.99],
              [77.91, 53.27],
              [80.08, 50.76],
              [80.69, 51.31],
              [81.47, 50.74],
              [83.46, 51],
              [85.26, 49.59],
              [86.77, 49.79],
              [87.35, 49.09],
              [85.76, 48.39],
              [85.53, 47.06],
              [83.04, 47.21],
              [82.32, 45.57],
              [82.56, 45.13],
              [79.87, 44.9],
              [80.52, 44.73],
              [80.82, 43.16],
              [80.38, 43.03],
              [80.23, 42.2],
              [79.19, 42.8],
              [74.29, 43.22],
              [73.58, 43.04],
              [73.52, 42.41],
              [71.75, 42.82],
              [70.97, 42.25],
              [69.06, 41.38],
              [68.46, 40.6],
              [67.94, 41.18],
              [66.72, 41.17],
              [66.53, 42],
              [66.03, 42],
              [66.12, 43],
              [64.93, 43.74],
              [62.03, 43.48],
              [58.57, 45.57],
              [56, 45],
              [56, 41.33],
              [54.17, 42.34],
              [53.01, 42.14],
              [52.44, 41.74],
              [52.74, 42.71],
              [51.27, 43.15],
              [50.24, 44.58],
              [51.57, 44.51],
              [50.95, 44.86],
              [51.41, 45.37],
              [53.23, 45.34],
              [52.73, 45.55],
              [53.19, 46.71],
              [51.19, 47.11],
              [49.22, 46.35],
              [48.56, 46.56],
              [49.03, 46.78],
              [48.2, 47.7],
              [47.38, 47.69],
              [46.5, 48.42],
              [47.52, 50.44],
              [48.8, 49.94],
              [48.7, 50.59],
              [50.77, 51.77],
              [53.43, 51.49],
              [54.52, 50.53],
              [54.65, 51.04],
              [55.69, 50.53],
              [56.51, 51.08],
              [58.34, 51.16],
              [59.54, 50.48],
              [61.38, 50.78],
              [61.69, 51.27],
              [60, 51.96],
              [61.06, 52.34],
              [60.69, 52.68],
              [61.1, 52.98],
              [62.12, 53],
              [61.18, 53.31],
              [61.58, 53.51],
              [60.91, 53.62],
              [61.01, 53.95],
              [65.22, 54.32],
              [69.19, 55.34]
            ]
          ]
        ]
      },
      "properties": { "name": "Kazakhstan" },
      "id": "KZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [34.96, 29.36],
              [34.98, 29.55],
              [35.48, 31.5],
              [35.55, 32.39],
              [35.65, 32.69],
              [36.84, 32.31],
              [38.79, 33.38],
              [39.2, 32.15],
              [37.01, 31.51],
              [38, 30.5],
              [37.5, 30],
              [36.07, 29.19],
              [34.96, 29.36]
            ]
          ]
        ]
      },
      "properties": { "name": "Jordan" },
      "id": "JO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [35.94, 4.62],
              [39.52, 3.41],
              [40.78, 4.29],
              [41.91, 3.98],
              [40.99, 2.83],
              [41, -0.87],
              [41.56, -1.67],
              [40.24, -2.66],
              [39.2, -4.67],
              [37.61, -3.5],
              [37.6, -3],
              [33.92, -1],
              [33.91, 0.1],
              [35.01, 1.9],
              [34, 4.22],
              [34.39, 4.61],
              [35.94, 4.62]
            ]
          ]
        ]
      },
      "properties": { "name": "Kenya" },
      "id": "KE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [130.64, 42.41],
              [130.7, 42.29],
              [129.7, 41.65],
              [129.71, 40.83],
              [127.51, 39.72],
              [127.39, 39.2],
              [128.36, 38.63],
              [126.69, 37.83],
              [125.59, 38.03],
              [125.34, 37.67],
              [124.66, 38.12],
              [125.65, 38.63],
              [125.14, 38.8],
              [125.45, 39.58],
              [124.62, 39.59],
              [124.37, 40.09],
              [126.91, 41.8],
              [128.16, 41.38],
              [128.06, 42],
              [129.71, 42.44],
              [129.91, 43.01],
              [130.6, 42.42],
              [130.64, 42.41]
            ]
          ]
        ]
      },
      "properties": { "name": "Korea, North" },
      "id": "KP"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [128.53, 38.33],
              [129.43, 37.06],
              [129.44, 35.48],
              [126.56, 34.3],
              [126.25, 35.12],
              [126.87, 36.05],
              [126.12, 36.71],
              [126.99, 36.91],
              [126.69, 37.83],
              [128.36, 38.63],
              [128.53, 38.33]
            ]
          ]
        ]
      },
      "properties": { "name": "Korea, South" },
      "id": "KR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [47.93, 30.02],
              [47.94, 30.02],
              [48.17, 29.55],
              [47.71, 29.38],
              [48.42, 28.55],
              [46.55, 29.1],
              [47.17, 30.02],
              [47.93, 30.02]
            ]
          ]
        ]
      },
      "properties": { "name": "Kuwait" },
      "id": "KW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [80.23, 42.2],
              [78.08, 41.04],
              [76.87, 41.01],
              [76.35, 40.35],
              [74.86, 40.52],
              [73.66, 39.45],
              [69.31, 39.54],
              [69.54, 40.13],
              [70.98, 40.24],
              [73.17, 40.82],
              [71.69, 41.56],
              [71.42, 41.12],
              [70.19, 41.53],
              [71.28, 42.2],
              [70.97, 42.25],
              [71.75, 42.82],
              [73.52, 42.41],
              [73.58, 43.04],
              [74.29, 43.22],
              [79.19, 42.8],
              [80.23, 42.2]
            ]
          ]
        ]
      },
      "properties": { "name": "Kyrgyzstan" },
      "id": "KG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [107.55, 14.71],
              [106, 14.37],
              [106.06, 13.93],
              [105.21, 14.35],
              [105.64, 15.66],
              [104.75, 16.53],
              [104.72, 17.5],
              [103.4, 18.43],
              [102.68, 17.82],
              [102.09, 18.21],
              [100.92, 17.57],
              [101.28, 19.56],
              [100.5, 19.53],
              [100.58, 20.16],
              [100.09, 20.35],
              [101.15, 21.57],
              [101.79, 21.14],
              [101.57, 22.21],
              [102.14, 22.4],
              [102.98, 21.74],
              [103.17, 20.85],
              [104.64, 20.66],
              [104.38, 20.44],
              [104.98, 20],
              [103.88, 19.29],
              [105.19, 18.64],
              [106.69, 16.46],
              [107.46, 16.08],
              [107.18, 15.78],
              [107.7, 15.27],
              [107.55, 14.71]
            ]
          ]
        ]
      },
      "properties": { "name": "Laos" },
      "id": "LA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [35.62, 33.25],
              [35.1, 33.09],
              [35.97, 34.65],
              [36.62, 34.2],
              [35.62, 33.25]
            ]
          ]
        ]
      },
      "properties": { "name": "Lebanon" },
      "id": "LB"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [27.56, -30.4],
              [27.01, -29.63],
              [28.57, -28.61],
              [29.43, -29.28],
              [29.17, -29.91],
              [28.08, -30.65],
              [27.56, -30.4]
            ]
          ]
        ]
      },
      "properties": { "name": "Lesotho" },
      "id": "LS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [21.07, 56.44],
              [21.73, 57.58],
              [22.61, 57.76],
              [23.79, 56.97],
              [24.41, 57.26],
              [24.31, 57.87],
              [25.29, 58.08],
              [27.37, 57.54],
              [27.86, 57.3],
              [28.17, 56.15],
              [26.61, 55.67],
              [25, 56.3],
              [21.05, 56.08],
              [21.07, 56.44]
            ]
          ]
        ]
      },
      "properties": { "name": "Latvia" },
      "id": "LV"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-10.81, 6.4],
              [-11.49, 6.93],
              [-10.27, 8.49],
              [-9.48, 8.35],
              [-9.49, 7.36],
              [-8.47, 7.56],
              [-8.61, 6.51],
              [-7.42, 5.84],
              [-7.53, 4.35],
              [-10.81, 6.4]
            ]
          ]
        ]
      },
      "properties": { "name": "Liberia" },
      "id": "LR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [9.95, 27.82],
              [9.54, 30.23],
              [10.21, 30.73],
              [10.29, 31.69],
              [11.57, 32.44],
              [11.53, 33.17],
              [15.17, 32.4],
              [15.76, 31.39],
              [19, 30.27],
              [20.06, 30.86],
              [20.08, 32.18],
              [21.62, 32.93],
              [25.15, 31.65],
              [24.71, 30.17],
              [25, 22],
              [25, 20],
              [24, 20],
              [24, 19.5],
              [16, 23.45],
              [15, 23],
              [14.23, 22.61],
              [11.99, 23.52],
              [11.56, 24.3],
              [10.25, 24.61],
              [9.4, 26.15],
              [9.87, 26.51],
              [9.95, 27.82]
            ]
          ]
        ]
      },
      "properties": { "name": "Libya" },
      "id": "LY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [9.53, 47.27],
              [9.6, 47.06],
              [9.47, 47.06],
              [9.53, 47.27]
            ]
          ]
        ]
      },
      "properties": { "name": "Liechtenstein" },
      "id": "LI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [25, 56.3],
              [26.61, 55.67],
              [26.82, 55.28],
              [25.79, 54.87],
              [25.79, 54.16],
              [23.5, 53.95],
              [22.79, 54.36],
              [22.84, 54.9],
              [21.43, 55.25],
              [21.26, 55.25],
              [21.05, 56.08],
              [25, 56.3]
            ]
          ]
        ]
      },
      "properties": { "name": "Lithuania" },
      "id": "LT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [6.03, 50.18],
              [6.13, 50.13],
              [6.36, 49.46],
              [5.81, 49.55],
              [6.03, 50.18]
            ]
          ]
        ]
      },
      "properties": { "name": "Luxembourg" },
      "id": "LU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [113.53, 22.19],
              [113.55, 22.21],
              [113.55, 22.18],
              [113.53, 22.19]
            ]
          ]
        ]
      },
      "properties": { "name": "Macau" },
      "id": "MO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [49.36, -12.09],
              [50.43, -15.58],
              [50.17, -15.98],
              [49.63, -15.56],
              [49.79, -16.83],
              [47.13, -24.93],
              [45.21, -25.59],
              [44.02, -24.99],
              [43.24, -22.28],
              [44.48, -19.97],
              [43.94, -17.48],
              [44.46, -16.18],
              [46.48, -15.97],
              [46.34, -15.62],
              [47.22, -15.45],
              [47.45, -14.67],
              [47.43, -15.11],
              [48, -14.77],
              [47.91, -13.6],
              [48.74, -13.43],
              [48.73, -12.43],
              [49.36, -12.09]
            ]
          ]
        ]
      },
      "properties": { "name": "Madagascar" },
      "id": "MG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [34.73, -12.1],
              [34.7, -12.08],
              [34.72, -12.03],
              [34.75, -12.04],
              [34.73, -12.1]
            ]
          ],
          [
            [
              [34.62, -12.04],
              [34.6, -12.01],
              [34.63, -12.01],
              [34.62, -12.04]
            ]
          ],
          [
            [
              [33.13, -9.49],
              [34.33, -9.73],
              [34.97, -11.57],
              [34.38, -12.16],
              [34.57, -13.34],
              [35.92, -14.89],
              [35.81, -16.02],
              [35.14, -16.55],
              [35.29, -17.13],
              [34.26, -15.9],
              [34.52, -14.57],
              [33.63, -14.54],
              [33.22, -14.01],
              [32.68, -13.61],
              [33.55, -12.36],
              [33.25, -10.89],
              [33.7, -10.56],
              [32.94, -9.41],
              [33.13, -9.49]
            ]
          ]
        ]
      },
      "properties": { "name": "Malawi" },
      "id": "MW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [100.65, 6.45],
              [101.14, 5.63],
              [102.1, 6.24],
              [103.41, 4.86],
              [103.44, 2.93],
              [104.28, 1.37],
              [103.96, 1.65],
              [103.51, 1.27],
              [101.28, 2.84],
              [100.13, 6.42],
              [100.65, 6.45]
            ]
          ],
          [
            [
              [116.79, 6.58],
              [117.18, 6.99],
              [117.74, 6.39],
              [117.5, 5.9],
              [118.01, 6.06],
              [117.96, 5.68],
              [119.28, 5.34],
              [118.14, 4.89],
              [118.55, 4.35],
              [117.59, 4.17],
              [116.05, 4.28],
              [114.56, 1.43],
              [112.47, 1.57],
              [111.83, 1],
              [110.56, 0.85],
              [109.65, 2.07],
              [111.38, 1.34],
              [111, 1.58],
              [111.44, 2.69],
              [113.01, 3.16],
              [114.1, 4.59],
              [114.64, 4.02],
              [115.02, 4.9],
              [115.03, 4.82],
              [115.34, 4.31],
              [115.22, 4.8],
              [115.15, 4.9],
              [116.76, 7.02],
              [116.79, 6.58]
            ]
          ]
        ]
      },
      "properties": { "name": "Malaysia" },
      "id": "MY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [73.18, -0.69],
              [73.16, -0.68],
              [73.17, -0.68],
              [73.18, -0.69]
            ]
          ],
          [
            [
              [73.2, -0.68],
              [73.19, -0.68],
              [73.2, -0.67],
              [73.2, -0.68]
            ]
          ],
          [
            [
              [73.13, -0.67],
              [73.13, -0.67],
              [73.12, -0.65],
              [73.13, -0.67]
            ]
          ],
          [
            [
              [73.23, -0.65],
              [73.23, -0.63],
              [73.24, -0.62],
              [73.23, -0.65]
            ]
          ],
          [
            [
              [73.12, -0.64],
              [73.09, -0.61],
              [73.09, -0.58],
              [73.12, -0.64]
            ]
          ],
          [
            [
              [73.25, -0.61],
              [73.24, -0.59],
              [73.25, -0.58],
              [73.25, -0.61]
            ]
          ]
        ]
      },
      "properties": { "name": "Maldives" },
      "id": "MV"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-8.53, 11.49],
              [-9.16, 12.49],
              [-10.65, 11.89],
              [-11.37, 12.41],
              [-12.24, 14.76],
              [-11.49, 15.65],
              [-10.9, 15.11],
              [-9.34, 15.7],
              [-5.5, 15.5],
              [-6.58, 25],
              [-4.81, 25],
              [1.8, 20.31],
              [3.23, 19.82],
              [3.33, 18.98],
              [4.25, 19.15],
              [4.25, 18.65],
              [4.2, 16.39],
              [3.52, 15.36],
              [0.24, 14.92],
              [-0.73, 15.08],
              [-2.47, 14.29],
              [-3.44, 13.17],
              [-3.96, 13.5],
              [-4.42, 12.3],
              [-5.27, 11.84],
              [-5.52, 10.44],
              [-6.11, 10.2],
              [-6.24, 10.74],
              [-6.99, 10.15],
              [-7.97, 10.17],
              [-8.29, 11.01],
              [-8.68, 10.97],
              [-8.53, 11.49]
            ]
          ]
        ]
      },
      "properties": { "name": "Mali" },
      "id": "ML"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [14.56, 35.82],
              [14.37, 35.85],
              [14.33, 35.98],
              [14.56, 35.82]
            ]
          ],
          [
            [
              [14.27, 36.01],
              [14.18, 36.06],
              [14.34, 36.03],
              [14.27, 36.01]
            ]
          ]
        ]
      },
      "properties": { "name": "Malta" },
      "id": "MT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-60.86, 14.4],
              [-61.17, 14.88],
              [-60.94, 14.74],
              [-60.86, 14.4]
            ]
          ]
        ]
      },
      "properties": { "name": "Martinique" },
      "id": "MQ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-6.66, 26.13],
              [-4.81, 25],
              [-6.58, 25],
              [-5.5, 15.5],
              [-9.34, 15.7],
              [-10.9, 15.11],
              [-11.49, 15.65],
              [-12.24, 14.76],
              [-14.35, 16.64],
              [-16.28, 16.52],
              [-16.53, 16.06],
              [-16.04, 17.73],
              [-16.51, 19.35],
              [-16.2, 20.22],
              [-16.92, 21.16],
              [-17.05, 20.76],
              [-16.95, 21.34],
              [-15.74, 21.34],
              [-13, 21.34],
              [-13.11, 22.89],
              [-12, 23.45],
              [-12, 26],
              [-8.67, 26],
              [-8.67, 27.29],
              [-6.66, 26.13]
            ]
          ]
        ]
      },
      "properties": { "name": "Mauritania" },
      "id": "MR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [57.57, -20.51],
              [57.3, -20.45],
              [57.62, -19.99],
              [57.57, -20.51]
            ]
          ]
        ]
      },
      "properties": { "name": "Mauritius" },
      "id": "MU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-113.05, 31.97],
              [-111.05, 31.33],
              [-106.4, 31.75],
              [-103.38, 29.02],
              [-102.31, 29.89],
              [-101.41, 29.77],
              [-99.51, 27.57],
              [-99.1, 26.43],
              [-97.14, 25.97],
              [-97.89, 22.6],
              [-95.91, 18.83],
              [-94.48, 18.15],
              [-92, 18.73],
              [-91.48, 18.44],
              [-90.45, 19.98],
              [-90.33, 21.03],
              [-87.03, 21.59],
              [-86.77, 21.15],
              [-87.74, 19.67],
              [-87.41, 19.58],
              [-87.85, 18.19],
              [-88.04, 18.87],
              [-88.3, 18.48],
              [-88.38, 18.48],
              [-89.14, 17.82],
              [-90.98, 17.82],
              [-90.98, 17.26],
              [-91.44, 17.24],
              [-90.44, 16.09],
              [-91.73, 16.07],
              [-92.25, 14.55],
              [-92.77, 15.17],
              [-93.93, 16.09],
              [-94.37, 16.29],
              [-94.06, 16.04],
              [-94.72, 16.2],
              [-94.58, 16.32],
              [-94.79, 16.26],
              [-94.86, 16.43],
              [-96.48, 15.64],
              [-97.79, 15.97],
              [-104.98, 19.34],
              [-105.68, 20.39],
              [-105.24, 20.57],
              [-105.54, 20.79],
              [-105.19, 21.44],
              [-105.82, 22.66],
              [-108.04, 25.07],
              [-109.11, 25.53],
              [-108.83, 25.8],
              [-109.39, 25.76],
              [-109.1, 26.28],
              [-110.53, 27.37],
              [-110.51, 27.87],
              [-112.16, 28.97],
              [-113.09, 31.23],
              [-115.03, 31.97],
              [-114.55, 30],
              [-111.56, 26.72],
              [-110.66, 24.34],
              [-109.41, 23.47],
              [-110, 22.89],
              [-112.09, 24.76],
              [-112.4, 26.27],
              [-114.99, 27.72],
              [-113.98, 27.7],
              [-114.31, 27.87],
              [-114.06, 28.53],
              [-115.69, 29.77],
              [-117.12, 32.54],
              [-114.72, 32.72],
              [-113.05, 31.97]
            ]
          ]
        ]
      },
      "properties": { "name": "Mexico" },
      "id": "MX"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [7.44, 43.76],
              [7.39, 43.73],
              [7.42, 43.77],
              [7.44, 43.76]
            ]
          ]
        ]
      },
      "properties": { "name": "Monaco" },
      "id": "MC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [91.02, 46.6],
              [90.07, 47.89],
              [88.65, 48.18],
              [87.84, 49.17],
              [92.32, 50.81],
              [97.34, 49.73],
              [98.29, 50.29],
              [97.83, 51],
              [98.93, 52.14],
              [102.22, 51.33],
              [102.33, 50.57],
              [102.92, 50.32],
              [106.66, 50.34],
              [108.57, 49.33],
              [110.79, 49.15],
              [114.31, 50.28],
              [116.71, 49.83],
              [115.59, 47.92],
              [117.37, 47.65],
              [118.54, 47.99],
              [119.9, 46.68],
              [117.42, 46.58],
              [113.64, 44.75],
              [111.98, 45.09],
              [111.42, 44.38],
              [111.96, 43.69],
              [110.44, 42.78],
              [107.47, 42.47],
              [105.01, 41.58],
              [100.84, 42.68],
              [96.38, 42.73],
              [95.42, 44.29],
              [90.9, 45.25],
              [91.02, 46.6]
            ]
          ]
        ]
      },
      "properties": { "name": "Mongolia" },
      "id": "MN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [28.12, 46.85],
              [26.63, 48.26],
              [27.76, 48.45],
              [29.14, 47.99],
              [29.95, 46.81],
              [30.12, 46.39],
              [28.99, 46.48],
              [28.21, 45.45],
              [28.12, 46.85]
            ]
          ]
        ]
      },
      "properties": { "name": "Moldova" },
      "id": "MD"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [18.46, 42.57],
              [19.23, 43.51],
              [20.35, 42.89],
              [20.07, 42.56],
              [19.65, 42.62],
              [19.37, 41.85],
              [18.5, 42.45],
              [18.46, 42.57]
            ]
          ]
        ]
      },
      "properties": { "name": "Montenegro" },
      "id": "ME"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-62.17, 16.67],
              [-62.24, 16.71],
              [-62.2, 16.81],
              [-62.17, 16.67]
            ]
          ]
        ]
      },
      "properties": { "name": "Montserrat" },
      "id": "MS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-9.05, 32.73],
              [-6.81, 34.05],
              [-5.92, 35.79],
              [-5.4, 35.92],
              [-4.42, 35.15],
              [-2.99, 35.42],
              [-2.21, 35.09],
              [-1.75, 34.75],
              [-1.18, 32.11],
              [-3.82, 31.7],
              [-3.63, 30.97],
              [-8.67, 28.71],
              [-8.67, 27.67],
              [-13.17, 27.67],
              [-10.14, 29.43],
              [-9.64, 30.17],
              [-9.81, 31.45],
              [-9.05, 32.73]
            ]
          ]
        ]
      },
      "properties": { "name": "Morocco" },
      "id": "MA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [40.5, -11.03],
              [40.59, -15.48],
              [39.09, -16.99],
              [36.84, -17.88],
              [36.25, -18.89],
              [34.9, -19.86],
              [34.62, -19.62],
              [35.55, -22.23],
              [35.5, -24.11],
              [32.81, -25.61],
              [32.89, -26.85],
              [32.13, -26.84],
              [31.97, -25.96],
              [32.02, -24.46],
              [31.3, -22.41],
              [32.49, -21.34],
              [33.02, -19.94],
              [32.7, -18.94],
              [32.99, -17.27],
              [32.98, -16.71],
              [30.42, -16.01],
              [30.42, -15.63],
              [30.21, -14.98],
              [33.22, -14.01],
              [33.63, -14.54],
              [34.52, -14.57],
              [34.26, -15.9],
              [35.29, -17.13],
              [35.14, -16.55],
              [35.81, -16.02],
              [35.92, -14.89],
              [34.57, -13.34],
              [34.38, -12.16],
              [34.97, -11.57],
              [37.46, -11.73],
              [40.44, -10.48],
              [40.5, -11.03]
            ],
            [
              [34.6, -12.01],
              [34.62, -12.04],
              [34.63, -12.01],
              [34.6, -12.01]
            ],
            [
              [34.72, -12.03],
              [34.7, -12.08],
              [34.73, -12.1],
              [34.75, -12.04],
              [34.72, -12.03]
            ]
          ]
        ]
      },
      "properties": { "name": "Mozambique" },
      "id": "MZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [56.04, 24.94],
              [56.37, 24.98],
              [57.16, 23.94],
              [58.61, 23.63],
              [59.81, 22.23],
              [58.52, 20.41],
              [57.83, 20.22],
              [57.8, 18.97],
              [56.81, 18.75],
              [56.35, 17.93],
              [55.44, 17.83],
              [55.04, 17.02],
              [53.11, 16.64],
              [52, 19],
              [55, 20],
              [55.67, 22],
              [55.2, 22.7],
              [56.04, 24.94]
            ]
          ],
          [
            [
              [56.37, 26.38],
              [56.27, 25.64],
              [56.18, 25.65],
              [56.08, 26.07],
              [56.37, 26.38]
            ]
          ]
        ]
      },
      "properties": { "name": "Oman" },
      "id": "OM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [23.28, -17.66],
              [23.48, -17.63],
              [24.97, -17.56],
              [25.26, -17.8],
              [23.62, -18.49],
              [23.3, -18],
              [20.99, -18.32],
              [20.99, -22],
              [20, -22.01],
              [20, -24.77],
              [20, -28.42],
              [18.18, -28.91],
              [17.4, -28.71],
              [17.06, -28.03],
              [16.49, -28.58],
              [15.29, -27.32],
              [14.51, -22.55],
              [11.8, -18.08],
              [11.75, -17.25],
              [13.16, -16.95],
              [13.99, -17.42],
              [18.45, -17.39],
              [20.85, -18.02],
              [23.28, -17.66]
            ]
          ]
        ]
      },
      "properties": { "name": "Namibia" },
      "id": "NA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [166.93, -0.55],
              [166.93, -0.49],
              [166.96, -0.51],
              [166.93, -0.55]
            ]
          ]
        ]
      },
      "properties": { "name": "Nauru" },
      "id": "NR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [82.07, 27.91],
              [80.06, 28.84],
              [81.03, 30.2],
              [82.1, 30.34],
              [86.01, 27.88],
              [88.14, 27.87],
              [88.01, 26.36],
              [85.86, 26.57],
              [84.15, 27.51],
              [83.29, 27.34],
              [82.07, 27.91]
            ]
          ]
        ]
      },
      "properties": { "name": "Nepal" },
      "id": "NP"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [3.76, 51.35],
              [3.37, 51.37],
              [4.24, 51.35],
              [3.76, 51.35]
            ]
          ],
          [
            [
              [5.76, 52.42],
              [5.43, 52.26],
              [5.14, 52.38],
              [5.64, 52.6],
              [5.86, 52.54],
              [5.76, 52.42]
            ]
          ],
          [
            [
              [5.42, 52.64],
              [5.05, 52.39],
              [5.04, 52.63],
              [5.42, 52.64]
            ]
          ],
          [
            [
              [6.87, 53.42],
              [7.21, 53.24],
              [6.69, 52.55],
              [7.07, 52.39],
              [6.83, 51.97],
              [5.96, 51.81],
              [6.01, 50.76],
              [5.04, 51.49],
              [4.25, 51.38],
              [3.44, 51.54],
              [4.29, 51.45],
              [3.69, 51.71],
              [4.17, 51.69],
              [3.87, 51.81],
              [4.58, 52.46],
              [5.42, 52.25],
              [5.77, 52.41],
              [5.88, 52.51],
              [5.86, 52.61],
              [5.6, 52.66],
              [5.6, 52.76],
              [5.72, 52.84],
              [5.37, 52.88],
              [5.42, 52.96],
              [5.36, 53.07],
              [5.1, 52.95],
              [5.3, 52.71],
              [5.03, 52.63],
              [5.03, 52.38],
              [4.58, 52.47],
              [4.73, 52.96],
              [6.87, 53.42]
            ]
          ]
        ]
      },
      "properties": { "name": "Netherlands" },
      "id": "NL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-68.2, 12.22],
              [-68.25, 12.02],
              [-68.42, 12.26],
              [-68.2, 12.22]
            ]
          ],
          [
            [
              [-68.97, 12.2],
              [-69.16, 12.37],
              [-68.75, 12.04],
              [-68.97, 12.2]
            ]
          ]
        ]
      },
      "properties": { "name": "Netherlands Antilles" },
      "id": "AN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-69.88, 12.41],
              [-70.06, 12.54],
              [-70.06, 12.63],
              [-69.88, 12.41]
            ]
          ]
        ]
      },
      "properties": { "name": "Aruba" },
      "id": "AW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [164.32, -20.33],
              [167.01, -22.32],
              [165.26, -21.56],
              [163.99, -20.09],
              [164.32, -20.33]
            ]
          ]
        ]
      },
      "properties": { "name": "New Caledonia" },
      "id": "NC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [167.42, -16.11],
              [167.77, -16.54],
              [167.18, -15.9],
              [167.42, -16.11]
            ]
          ],
          [
            [
              [166.8, -15.16],
              [167.06, -14.95],
              [167.24, -15.52],
              [166.76, -15.64],
              [166.59, -14.62],
              [166.8, -15.16]
            ]
          ]
        ]
      },
      "properties": { "name": "Vanuatu" },
      "id": "VU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [172.86, -40.51],
              [173.11, -41.31],
              [174.32, -41],
              [174.29, -41.75],
              [172.76, -43.24],
              [173.09, -43.86],
              [171.29, -44.34],
              [170.78, -45.88],
              [169.01, -46.68],
              [166.48, -46.01],
              [168.37, -44.04],
              [170.79, -42.9],
              [172.11, -40.89],
              [172.86, -40.51]
            ]
          ],
          [
            [
              [173.04, -34.44],
              [173.27, -35.02],
              [174.32, -35.23],
              [174.85, -36.85],
              [175.58, -37.24],
              [175.35, -36.48],
              [175.84, -36.75],
              [175.99, -37.64],
              [177.16, -38.01],
              [178.57, -37.71],
              [177.91, -39.26],
              [177.05, -39.2],
              [176.83, -40.18],
              [175.32, -41.61],
              [174.59, -41.28],
              [175.16, -40.1],
              [173.75, -39.29],
              [174.59, -38.82],
              [174.97, -37.75],
              [174.55, -37.07],
              [174.89, -37.06],
              [174.19, -36.5],
              [174.51, -36.23],
              [173.91, -35.87],
              [174.08, -36.41],
              [173.4, -35.57],
              [173.66, -35.31],
              [173.09, -35.21],
              [172.72, -34.5],
              [173.04, -34.44]
            ]
          ]
        ]
      },
      "properties": { "name": "New Zealand" },
      "id": "NZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-83.24, 14.98],
              [-83.13, 14.99],
              [-83.83, 11.87],
              [-83.65, 10.92],
              [-85.09, 11.01],
              [-85.69, 11.08],
              [-87.69, 12.91],
              [-87.3, 12.99],
              [-84.91, 14.81],
              [-83.24, 14.98]
            ]
          ]
        ]
      },
      "properties": { "name": "Nicaragua" },
      "id": "NI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [4.25, 18.65],
              [4.25, 19.15],
              [5.81, 19.45],
              [11.99, 23.52],
              [14.23, 22.61],
              [15, 23],
              [15.2, 21.5],
              [16, 20.35],
              [15.49, 16.91],
              [13.47, 14.46],
              [13.63, 13.72],
              [12.46, 13.07],
              [10.72, 13.39],
              [9.63, 12.8],
              [7.82, 13.35],
              [6.93, 13],
              [5.87, 13.75],
              [4.14, 13.48],
              [3.6, 11.69],
              [2.84, 12.4],
              [2.4, 11.9],
              [2.14, 12.69],
              [0.99, 13.05],
              [1.29, 13.35],
              [0.6, 13.7],
              [0.24, 14.92],
              [3.52, 15.36],
              [4.2, 16.39],
              [4.25, 18.65]
            ]
          ]
        ]
      },
      "properties": { "name": "Niger" },
      "id": "NE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [5.87, 13.75],
              [6.93, 13],
              [7.82, 13.35],
              [9.63, 12.8],
              [10.72, 13.39],
              [12.46, 13.07],
              [13.63, 13.72],
              [14.07, 13.08],
              [14.65, 11.58],
              [13.81, 11.06],
              [11.86, 7.08],
              [11.34, 6.44],
              [10.62, 7.07],
              [9.8, 6.8],
              [8.59, 4.81],
              [6.96, 4.73],
              [7.01, 4.37],
              [6.77, 4.77],
              [6.85, 4.35],
              [6.1, 4.27],
              [5.45, 4.92],
              [5.64, 5.54],
              [5.26, 5.44],
              [5.5, 5.62],
              [4.53, 6.3],
              [2.72, 6.37],
              [2.79, 9.04],
              [3.86, 10.58],
              [3.6, 11.69],
              [4.14, 13.48],
              [5.87, 13.75]
            ]
          ]
        ]
      },
      "properties": { "name": "Nigeria" },
      "id": "NG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-169.89, -19.15],
              [-169.93, -19.02],
              [-169.82, -18.97],
              [-169.89, -19.15]
            ]
          ]
        ]
      },
      "properties": { "name": "Niue" },
      "id": "NU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [167.96, -29.08],
              [167.91, -29.01],
              [168, -29.03],
              [167.96, -29.08]
            ]
          ]
        ]
      },
      "properties": { "name": "Norfolk Island" },
      "id": "NF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [13.97, 68.24],
              [14.14, 68.24],
              [13.45, 68.07],
              [13.97, 68.24]
            ]
          ],
          [
            [
              [15.03, 68.35],
              [14.2, 68.15],
              [15.17, 68.45],
              [15.03, 68.35]
            ]
          ],
          [
            [
              [15.38, 68.85],
              [15.06, 68.57],
              [14.37, 68.68],
              [15.38, 68.85]
            ]
          ],
          [
            [
              [15.99, 68.75],
              [15.74, 68.53],
              [16.57, 68.65],
              [14.99, 68.25],
              [15.64, 68.95],
              [15.99, 68.75]
            ]
          ],
          [
            [
              [17.64, 69.52],
              [18.07, 69.43],
              [17.88, 69.24],
              [18, 69.19],
              [16.78, 69.06],
              [17.64, 69.52]
            ]
          ],
          [
            [
              [18.89, 69.69],
              [18, 69.59],
              [18.69, 69.88],
              [18.89, 69.69]
            ]
          ],
          [
            [
              [23.6, 70.58],
              [23.23, 70.28],
              [22.85, 70.41],
              [23.6, 70.58]
            ]
          ],
          [
            [
              [23.45, 70.78],
              [22.78, 70.52],
              [21.95, 70.65],
              [23.45, 70.78]
            ]
          ],
          [
            [
              [5.34, 61.59],
              [4.94, 61.68],
              [6.76, 61.87],
              [5.15, 61.89],
              [5.15, 62.21],
              [6.36, 62.06],
              [5.92, 62.22],
              [6.31, 62.37],
              [6.54, 62.1],
              [6.39, 62.37],
              [6.7, 62.45],
              [6.87, 62.42],
              [7, 62.25],
              [6.94, 62.11],
              [7.04, 62.09],
              [7.42, 62.23],
              [6.98, 62.31],
              [6.92, 62.36],
              [6.97, 62.37],
              [6.78, 62.48],
              [6.26, 62.45],
              [6.65, 62.5],
              [6.25, 62.57],
              [8.15, 62.69],
              [7.04, 62.97],
              [8.55, 62.65],
              [7.88, 63.01],
              [8.53, 62.84],
              [8.16, 63.12],
              [9.65, 63.62],
              [10.26, 63.26],
              [10.05, 63.41],
              [10.91, 63.45],
              [11.49, 64.02],
              [11.31, 64.12],
              [10.57, 63.8],
              [11.08, 63.84],
              [10.94, 63.74],
              [10.05, 63.5],
              [9.79, 63.66],
              [10.1, 63.76],
              [9.54, 63.76],
              [12.94, 65.31],
              [12.25, 65.23],
              [12.67, 65.92],
              [13.18, 65.85],
              [12.67, 66.07],
              [14.15, 66.32],
              [13.03, 66.19],
              [13.54, 66.3],
              [12.97, 66.52],
              [13.73, 66.6],
              [13.23, 66.71],
              [13.99, 66.78],
              [13.55, 66.93],
              [15.74, 67.17],
              [14.36, 67.23],
              [15.9, 67.56],
              [14.76, 67.81],
              [15.97, 68.25],
              [16.22, 67.89],
              [16.5, 67.79],
              [16.21, 68],
              [16.52, 67.95],
              [16.36, 68.03],
              [16.72, 68.07],
              [16.1, 68.28],
              [16.81, 68.13],
              [16.32, 68.37],
              [17.35, 68.17],
              [17.55, 68.52],
              [16.46, 68.51],
              [17.68, 68.65],
              [17.24, 68.75],
              [17.79, 68.76],
              [17.43, 68.91],
              [18.15, 69.15],
              [18, 69.28],
              [18.26, 69.49],
              [19.44, 69.23],
              [18.94, 69.61],
              [19.76, 69.81],
              [19.68, 69.43],
              [20.3, 69.97],
              [19.95, 69.26],
              [21.31, 70.02],
              [22.1, 69.74],
              [21.8, 70.03],
              [22.1, 70.11],
              [21.3, 70.25],
              [23.31, 69.94],
              [24.73, 70.62],
              [24.25, 70.78],
              [24.59, 70.96],
              [25.91, 70.89],
              [25.07, 70.5],
              [25.23, 70.09],
              [26.57, 70.94],
              [26.5, 70.36],
              [27.03, 70.47],
              [27.65, 71.11],
              [28.55, 70.97],
              [27.65, 70.61],
              [28.3, 70.71],
              [27.85, 70.48],
              [28.34, 70.51],
              [28.04, 70.06],
              [29.04, 70.87],
              [31.08, 70.29],
              [28.61, 70.11],
              [29.67, 69.97],
              [29.49, 69.66],
              [30.85, 69.79],
              [28.96, 69.05],
              [29.18, 69.64],
              [28.17, 69.91],
              [26.45, 69.93],
              [24.93, 68.58],
              [22.4, 68.71],
              [21.32, 69.33],
              [20.58, 69.06],
              [20.1, 69.04],
              [20.35, 68.79],
              [19.94, 68.34],
              [18.09, 68.51],
              [17.88, 67.95],
              [16.73, 67.9],
              [15.47, 66.28],
              [14.5, 66.13],
              [14.49, 65.31],
              [13.66, 64.58],
              [14.12, 64.47],
              [13.99, 64.02],
              [12.14, 63.58],
              [12.12, 61.73],
              [12.86, 61.36],
              [12.21, 61],
              [12.49, 60.11],
              [11.82, 59.85],
              [11.43, 58.99],
              [10.8, 59.19],
              [10.53, 59.88],
              [10.23, 59.04],
              [9.54, 59.11],
              [8.21, 58.12],
              [6.6, 58.07],
              [5.46, 58.74],
              [6.62, 59.05],
              [5.87, 59.07],
              [6.47, 59.56],
              [5.18, 59.51],
              [7.11, 60.49],
              [5.75, 59.99],
              [5.73, 60.39],
              [5.14, 60.35],
              [5.7, 60.69],
              [4.93, 60.8],
              [6.59, 61.15],
              [7.11, 60.86],
              [7.57, 61.48],
              [5.4, 61.07],
              [4.95, 61.26],
              [5.63, 61.36],
              [4.95, 61.41],
              [5.8, 61.45],
              [5.34, 61.59]
            ],
            [
              [5.34, 61.59],
              [5.35, 61.59],
              [5.28, 61.59],
              [5.34, 61.59]
            ]
          ]
        ]
      },
      "properties": { "name": "Norway" },
      "id": "NO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [145.28, 14.16],
              [145.12, 14.12],
              [145.24, 14.19],
              [145.28, 14.16]
            ]
          ],
          [
            [
              [145.62, 14.91],
              [145.57, 15.01],
              [145.63, 15.08],
              [145.62, 14.91]
            ]
          ],
          [
            [
              [145.74, 15.13],
              [145.68, 15.11],
              [145.82, 15.27],
              [145.74, 15.13]
            ]
          ],
          [
            [
              [145.74, 18.04],
              [145.78, 18.17],
              [145.82, 18.16],
              [145.74, 18.04]
            ]
          ],
          [
            [
              [145.68, 18.72],
              [145.66, 18.81],
              [145.71, 18.77],
              [145.68, 18.72]
            ]
          ]
        ]
      },
      "properties": { "name": "Northern Mariana Islands" },
      "id": "MP"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [163.01, 5.26],
              [162.9, 5.31],
              [163.02, 5.38],
              [163.01, 5.26]
            ]
          ],
          [
            [
              [158.3, 6.79],
              [158.12, 6.93],
              [158.32, 6.93],
              [158.3, 6.79]
            ]
          ],
          [
            [
              [138.21, 9.52],
              [138.06, 9.42],
              [138.13, 9.57],
              [138.21, 9.52]
            ]
          ]
        ]
      },
      "properties": { "name": "Micronesia, Federated States of" },
      "id": "FM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [168.77, 7.3],
              [168.79, 7.29],
              [168.67, 7.33],
              [168.77, 7.3]
            ]
          ],
          [
            [
              [168.57, 7.4],
              [168.55, 7.42],
              [168.56, 7.47],
              [168.57, 7.4]
            ]
          ],
          [
            [
              [168.97, 7.57],
              [168.94, 7.62],
              [168.97, 7.6],
              [168.97, 7.57]
            ]
          ]
        ]
      },
      "properties": { "name": "Marshall Islands" },
      "id": "MH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [134.56, 7.37],
              [134.49, 7.44],
              [134.63, 7.73],
              [134.56, 7.37]
            ]
          ]
        ]
      },
      "properties": { "name": "Palau" },
      "id": "PW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [74.82, 37.02],
              [75.86, 36.66],
              [76.17, 35.82],
              [77.82, 35.5],
              [76.87, 34.66],
              [73.94, 34.65],
              [74.3, 33.98],
              [74.02, 33.19],
              [75.38, 32.21],
              [74.61, 31.88],
              [74.69, 31.05],
              [71.9, 27.96],
              [70.37, 28.02],
              [69.58, 27.17],
              [71.11, 24.42],
              [68.78, 24.33],
              [68.2, 23.77],
              [67.49, 23.89],
              [66.36, 25.61],
              [64.65, 25.16],
              [61.61, 25.2],
              [61.86, 26.23],
              [63.18, 26.63],
              [63.34, 27.12],
              [62.78, 27.26],
              [62.78, 28.27],
              [61.91, 28.55],
              [60.87, 29.86],
              [62.48, 29.41],
              [66.26, 29.85],
              [66.72, 31.21],
              [69.33, 31.94],
              [69.51, 33.03],
              [70.33, 33.33],
              [69.91, 34.04],
              [71.08, 34.06],
              [71.65, 35.42],
              [71.24, 36.13],
              [72.56, 36.82],
              [74.57, 37.03],
              [74.82, 37.02]
            ]
          ]
        ]
      },
      "properties": { "name": "Pakistan" },
      "id": "PK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-79.46, 9.57],
              [-77.37, 8.67],
              [-77.22, 7.94],
              [-77.89, 7.23],
              [-78.43, 8.05],
              [-78.14, 8.4],
              [-77.78, 8.15],
              [-78.98, 9.14],
              [-80.47, 8.21],
              [-79.99, 7.52],
              [-80.43, 7.24],
              [-81.74, 8.16],
              [-82.9, 8.03],
              [-82.93, 9.47],
              [-82.56, 9.56],
              [-82.24, 9],
              [-81.2, 8.78],
              [-79.46, 9.57]
            ]
          ]
        ]
      },
      "properties": { "name": "Panama" },
      "id": "PA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [152.24, -4.21],
              [152.1, -5.46],
              [150.47, -6.28],
              [148.32, -5.68],
              [149.88, -5.54],
              [150.09, -5.01],
              [150.16, -5.55],
              [150.92, -5.49],
              [151.68, -4.91],
              [151.51, -4.2],
              [152.24, -4.21]
            ]
          ],
          [
            [
              [141.89, -2.97],
              [144.51, -3.82],
              [145.74, -4.8],
              [145.77, -5.49],
              [147.48, -5.97],
              [147.87, -6.66],
              [146.96, -6.75],
              [147.18, -7.46],
              [148.14, -8.07],
              [148.6, -9.08],
              [149.31, -9.02],
              [149.22, -9.47],
              [150.01, -9.63],
              [149.91, -10.05],
              [150.88, -10.23],
              [150.21, -10.7],
              [147.95, -10.15],
              [146.09, -8.09],
              [144.52, -7.5],
              [144.21, -7.8],
              [143.66, -7.47],
              [143.96, -7.98],
              [143.36, -7.9],
              [143.61, -8.24],
              [142.14, -8.23],
              [143.11, -8.47],
              [143.33, -9.03],
              [141.01, -9.13],
              [141, -2.61],
              [141.89, -2.97]
            ]
          ]
        ]
      },
      "properties": { "name": "Papua New Guinea" },
      "id": "PG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-54.33, -24.68],
              [-54.6, -25.57],
              [-54.7, -26.44],
              [-55.74, -27.44],
              [-58.6, -27.32],
              [-57.58, -25.55],
              [-57.76, -25.18],
              [-61.01, -23.81],
              [-62.64, -22.24],
              [-61.74, -19.65],
              [-59.1, -19.35],
              [-58.16, -20.17],
              [-57.99, -22.09],
              [-55.85, -22.29],
              [-55.41, -23.96],
              [-54.41, -23.92],
              [-54.33, -24.68]
            ]
          ]
        ]
      },
      "properties": { "name": "Paraguay" },
      "id": "PY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-78.71, -4.58],
              [-78.34, -3.42],
              [-75.56, -1.53],
              [-75.22, -0.97],
              [-75.63, -0.11],
              [-75.29, -0.12],
              [-73.56, -1.37],
              [-72.88, -2.51],
              [-71.7, -2.15],
              [-70.29, -2.51],
              [-70.72, -3.78],
              [-69.96, -4.24],
              [-70.77, -4.15],
              [-72.85, -5.12],
              [-73.12, -6.45],
              [-74.01, -7.54],
              [-72.96, -8.98],
              [-73.21, -9.41],
              [-72.37, -9.49],
              [-72.14, -10],
              [-70.51, -9.43],
              [-70.63, -11.01],
              [-69.57, -10.95],
              [-68.67, -12.5],
              [-69.42, -15.62],
              [-68.82, -16.34],
              [-69.5, -17.51],
              [-70.41, -18.35],
              [-75.93, -14.66],
              [-78.99, -8.23],
              [-79.98, -6.76],
              [-81.17, -6.09],
              [-80.87, -5.65],
              [-81.29, -4.31],
              [-80.34, -3.38],
              [-80.47, -4.44],
              [-79.65, -4.43],
              [-79.05, -5.01],
              [-78.71, -4.58]
            ]
          ]
        ]
      },
      "properties": { "name": "Peru" },
      "id": "PE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [126.05, 9.23],
              [126.59, 7.28],
              [126.19, 6.27],
              [125.65, 7.24],
              [125.41, 5.56],
              [125.26, 6.09],
              [124.19, 6.21],
              [124.27, 7.37],
              [123.68, 7.81],
              [122.83, 7.28],
              [122.62, 7.77],
              [121.92, 6.99],
              [122.22, 7.96],
              [123.38, 8.73],
              [123.82, 8.48],
              [123.67, 7.95],
              [124.73, 8.49],
              [124.8, 9],
              [125.51, 9.01],
              [125.44, 9.81],
              [126.05, 9.23]
            ]
          ],
          [
            [
              [124.48, 10.05],
              [124.37, 9.63],
              [123.79, 9.73],
              [124.48, 10.05]
            ]
          ],
          [
            [
              [123.56, 10.79],
              [123.01, 9.03],
              [122.45, 9.97],
              [122.95, 10.89],
              [123.56, 10.79]
            ]
          ],
          [
            [
              [124.02, 11.12],
              [124.03, 10.38],
              [123.31, 9.41],
              [124.02, 11.12]
            ]
          ],
          [
            [
              [119.51, 11.34],
              [119.71, 10.5],
              [117.19, 8.33],
              [119.31, 10.58],
              [119.22, 10.96],
              [119.46, 10.72],
              [119.51, 11.34]
            ]
          ],
          [
            [
              [124.64, 11.29],
              [125.03, 11.2],
              [125.01, 10.03],
              [124.29, 11.54],
              [124.64, 11.29]
            ]
          ],
          [
            [
              [122.23, 11.8],
              [123.15, 11.6],
              [123.13, 11.17],
              [121.94, 10.42],
              [121.85, 11.76],
              [122.23, 11.8]
            ]
          ],
          [
            [
              [124.46, 12.52],
              [125.3, 12.46],
              [125.76, 11.01],
              [124.84, 11.47],
              [124.46, 12.52]
            ]
          ],
          [
            [
              [123.67, 12.35],
              [124.08, 11.72],
              [123.53, 12.21],
              [123.16, 11.91],
              [123.24, 12.61],
              [123.67, 12.35]
            ]
          ],
          [
            [
              [120.72, 13.48],
              [121.5, 13.15],
              [121.22, 12.23],
              [120.3, 13.44],
              [120.72, 13.48]
            ]
          ],
          [
            [
              [121.26, 18.57],
              [122.24, 18.51],
              [122.53, 17.1],
              [121.38, 15.33],
              [121.73, 14.17],
              [122.23, 13.9],
              [122.71, 14.34],
              [123.1, 13.67],
              [123.34, 14.09],
              [123.92, 13.79],
              [123.53, 13.58],
              [124.2, 13.06],
              [124.08, 12.54],
              [122.56, 13.94],
              [122.61, 13.16],
              [121.75, 13.96],
              [120.66, 13.77],
              [120.96, 14.64],
              [120.09, 14.79],
              [119.79, 16.32],
              [120.42, 16.16],
              [120.57, 18.49],
              [121.26, 18.57]
            ]
          ]
        ]
      },
      "properties": { "name": "Philippines" },
      "id": "PH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-128.29, -24.41],
              [-128.34, -24.33],
              [-128.3, -24.33],
              [-128.29, -24.41]
            ]
          ]
        ]
      },
      "properties": { "name": "Pitcairn Islands" },
      "id": "PN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [18.85, 49.52],
              [17.72, 50.32],
              [16.64, 50.11],
              [16.34, 50.66],
              [14.83, 50.87],
              [14.28, 53.7],
              [17.9, 54.82],
              [19.8, 54.44],
              [22.79, 54.36],
              [23.5, 53.95],
              [23.94, 52.73],
              [23.17, 52.28],
              [23.64, 52.08],
              [23.6, 51.53],
              [24.11, 50.57],
              [22.56, 49.08],
              [18.85, 49.52]
            ]
          ]
        ]
      },
      "properties": { "name": "Poland" },
      "id": "PL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-8.2, 41.87],
              [-6.59, 41.95],
              [-6.19, 41.58],
              [-6.93, 41.02],
              [-7.02, 39.67],
              [-7.53, 39.67],
              [-6.95, 39.03],
              [-7.43, 37.25],
              [-8.99, 37.02],
              [-8.67, 38.41],
              [-9.18, 38.42],
              [-8.98, 38.95],
              [-9.48, 38.71],
              [-8.66, 40.69],
              [-8.75, 41.95],
              [-8.2, 41.87]
            ]
          ]
        ]
      },
      "properties": { "name": "Portugal" },
      "id": "PT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-14.52, 12.68],
              [-13.71, 12.68],
              [-13.71, 11.72],
              [-14.69, 11.51],
              [-15.02, 10.96],
              [-15.51, 11.34],
              [-15.03, 11.59],
              [-15.56, 11.72],
              [-15, 11.97],
              [-15.96, 11.73],
              [-15.7, 12],
              [-16.25, 11.93],
              [-16.11, 12.33],
              [-16.72, 12.32],
              [-14.52, 12.68]
            ]
          ]
        ]
      },
      "properties": { "name": "Guinea-Bissau" },
      "id": "GW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [124.45, -9.18],
              [124.34, -9.46],
              [124.05, -9.34],
              [124.45, -9.18]
            ]
          ],
          [
            [
              [127.25, -8.48],
              [125.13, -9.44],
              [125.16, -9.07],
              [124.95, -8.95],
              [127.25, -8.48]
            ]
          ]
        ]
      },
      "properties": { "name": "Timor-Leste" },
      "id": "TL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-67, 18.5],
              [-65.6, 18.23],
              [-67.19, 17.93],
              [-67, 18.5]
            ]
          ]
        ]
      },
      "properties": { "name": "Puerto Rico" },
      "id": "PR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [51.52, 25.38],
              [51.22, 24.62],
              [50.83, 24.75],
              [51.04, 26.05],
              [51.57, 25.91],
              [51.52, 25.38]
            ]
          ]
        ]
      },
      "properties": { "name": "Qatar" },
      "id": "QA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [55.71, -21],
              [55.67, -21.37],
              [55.22, -21.03],
              [55.71, -21]
            ]
          ]
        ]
      },
      "properties": { "name": "Reunion" },
      "id": "RE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [20.73, 46.18],
              [22.89, 47.95],
              [24.92, 47.71],
              [26.63, 48.26],
              [28.12, 46.85],
              [28.21, 45.45],
              [29.66, 45.21],
              [29.55, 44.82],
              [28.87, 44.94],
              [28.58, 43.75],
              [27.04, 44.15],
              [24.18, 43.68],
              [22.68, 44.22],
              [22.48, 44.71],
              [21.4, 44.78],
              [21.51, 45.15],
              [20.26, 46.11],
              [20.73, 46.18]
            ]
          ]
        ]
      },
      "properties": { "name": "Romania" },
      "id": "RO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [143.66, 49.31],
              [143.02, 49.14],
              [142.53, 48],
              [143.09, 46.8],
              [143.49, 46.81],
              [143.47, 46.09],
              [142.71, 46.74],
              [142.08, 45.89],
              [141.85, 48.75],
              [142.27, 51.12],
              [141.64, 52.32],
              [141.77, 53.37],
              [142.8, 53.7],
              [142.39, 54.24],
              [142.7, 54.42],
              [143.29, 53.13],
              [143.22, 51.52],
              [144.75, 48.64],
              [143.66, 49.31]
            ],
            [
              [143.66, 49.31],
              [143.25, 49.38],
              [143.32, 49.31],
              [143.66, 49.31]
            ]
          ],
          [
            [
              [21.43, 55.25],
              [22.84, 54.9],
              [22.79, 54.36],
              [19.8, 54.44],
              [20.4, 54.68],
              [19.87, 54.64],
              [19.97, 54.96],
              [20.94, 55.29],
              [20.53, 54.97],
              [21.09, 54.89],
              [21.26, 55.25],
              [21.43, 55.25]
            ]
          ],
          [
            [
              [-179.63, 68.91],
              [-175.46, 67.71],
              [-174.83, 67.38],
              [-175, 66.67],
              [-174.46, 66.3],
              [-173.76, 66.45],
              [-174.65, 67.06],
              [-173.67, 67.13],
              [-171.73, 66.96],
              [-169.69, 66.07],
              [-170.64, 65.61],
              [-171.54, 65.83],
              [-171.12, 65.48],
              [-172.8, 65.68],
              [-172.13, 65.09],
              [-173.2, 64.79],
              [-172.36, 64.46],
              [-173.19, 64.25],
              [-173.41, 64.62],
              [-175.45, 64.78],
              [-176.08, 65.47],
              [-178.56, 65.52],
              [-178.91, 65.99],
              [-178.52, 66.4],
              [-179.7, 66.18],
              [-179.32, 65.54],
              [-180, 65.07],
              [-180, 68.98],
              [-179.63, 68.91]
            ]
          ],
          [
            [
              [55.33, 73.33],
              [56.59, 73.14],
              [55.62, 72.96],
              [56.26, 72.96],
              [55.43, 72.78],
              [55.95, 72.67],
              [55.12, 72.45],
              [55.58, 72.2],
              [55.22, 71.93],
              [57.64, 70.73],
              [53.46, 70.81],
              [54.24, 71.13],
              [51.42, 71.74],
              [53.22, 72.65],
              [52.38, 72.72],
              [53.38, 72.88],
              [53.15, 73.16],
              [55.33, 73.33]
            ]
          ],
          [
            [
              [139.19, 76.07],
              [140.48, 75.64],
              [141.62, 76.01],
              [141.36, 76.18],
              [145.39, 75.52],
              [143.95, 75.03],
              [142.9, 75.13],
              [142.5, 75.36],
              [142.51, 75.45],
              [143.04, 75.67],
              [142.45, 75.71],
              [142.16, 75.38],
              [142.61, 75.1],
              [143.71, 74.94],
              [139.1, 74.65],
              [136.86, 75.35],
              [137.4, 75.35],
              [136.96, 75.6],
              [137.74, 75.75],
              [137.45, 75.95],
              [139.19, 76.07]
            ]
          ],
          [
            [
              [68.27, 76.96],
              [68.86, 76.54],
              [61.29, 75.33],
              [59.15, 74.44],
              [58.18, 74.57],
              [58.74, 74.27],
              [57.26, 74.08],
              [57.72, 73.71],
              [56.56, 73.88],
              [57.61, 73.66],
              [56.72, 73.67],
              [57.25, 73.49],
              [56.76, 73.25],
              [55.91, 73.44],
              [54.22, 73.32],
              [55.18, 73.71],
              [53.63, 73.76],
              [55.87, 74.1],
              [55.07, 74.26],
              [56.98, 74.69],
              [55.83, 74.8],
              [56.68, 74.95],
              [55.8, 75.15],
              [58, 75.67],
              [68.27, 76.96]
            ]
          ],
          [
            [
              [104.27, 77.68],
              [106.29, 77.36],
              [104.12, 77.09],
              [107.5, 76.92],
              [106.4, 76.51],
              [111.1, 76.76],
              [113.89, 75.85],
              [113.51, 75.53],
              [112.34, 75.85],
              [113.72, 75.41],
              [105.21, 72.76],
              [110.91, 73.7],
              [109.53, 73.77],
              [110.2, 74.02],
              [112.89, 73.96],
              [113.5, 73.33],
              [113.49, 72.96],
              [113.1, 72.85],
              [113.18, 72.72],
              [113.53, 72.63],
              [114.04, 72.6],
              [113.36, 72.69],
              [113.23, 72.74],
              [113.15, 72.83],
              [113.53, 72.96],
              [113.55, 73.24],
              [114.03, 73.34],
              [113.48, 73.5],
              [118.63, 73.57],
              [118.99, 73.49],
              [118.39, 73.24],
              [119.82, 72.94],
              [126.37, 72.35],
              [127.22, 71.39],
              [127.33, 71.9],
              [126.72, 72.39],
              [127.66, 72.35],
              [131.13, 70.73],
              [132.73, 71.94],
              [133.68, 71.43],
              [135.86, 71.64],
              [137.85, 71.11],
              [138.07, 71.57],
              [139.93, 71.48],
              [139.34, 71.95],
              [140.2, 72.2],
              [139.09, 72.23],
              [141.02, 72.59],
              [140.75, 72.89],
              [146.85, 72.35],
              [144.39, 72.17],
              [146.93, 72.31],
              [144.96, 71.96],
              [145.32, 71.66],
              [147.14, 72.32],
              [149.19, 72.22],
              [150.08, 71.88],
              [148.82, 71.67],
              [152.54, 70.84],
              [159.05, 70.87],
              [160.04, 70.41],
              [159.73, 69.83],
              [161, 69.59],
              [161.41, 68.98],
              [160.85, 68.52],
              [161.58, 68.91],
              [161.45, 69.39],
              [164.01, 69.77],
              [167.79, 69.78],
              [168.28, 69.24],
              [170.61, 68.76],
              [171.03, 69.04],
              [170.12, 69.61],
              [170.47, 70.13],
              [176.11, 69.89],
              [180, 68.98],
              [180, 65.07],
              [178.52, 64.59],
              [176.9, 65.08],
              [176.3, 65.05],
              [177.3, 64.83],
              [174.44, 64.69],
              [177.49, 64.76],
              [179.56, 62.62],
              [179.06, 62.28],
              [176.98, 62.87],
              [177.27, 62.57],
              [172.71, 61.43],
              [170.25, 59.91],
              [169.21, 60.62],
              [166.14, 59.82],
              [166.35, 60.49],
              [164.83, 59.78],
              [163.64, 60.05],
              [163.19, 59.05],
              [161.94, 58.07],
              [162.35, 57.68],
              [163.21, 57.84],
              [162.74, 57.36],
              [163.35, 56.2],
              [162.39, 56.4],
              [161.71, 55.5],
              [162.11, 54.76],
              [160.01, 54.14],
              [160.06, 53.09],
              [158.43, 53.02],
              [158.28, 51.94],
              [156.67, 50.88],
              [155.54, 55.3],
              [155.95, 56.67],
              [156.98, 57.41],
              [156.75, 57.73],
              [158.23, 58.02],
              [161.91, 60.42],
              [163.66, 60.87],
              [164.13, 62.28],
              [165.64, 62.45],
              [163.26, 62.54],
              [162.95, 61.81],
              [163.29, 61.66],
              [160.14, 60.58],
              [160.39, 61.03],
              [159.78, 60.94],
              [159.83, 61.26],
              [160.35, 61.95],
              [157.49, 61.8],
              [154.23, 59.88],
              [154.11, 59.46],
              [155.19, 59.36],
              [154.74, 59.13],
              [151.31, 58.84],
              [151.07, 59.11],
              [152.29, 59.23],
              [149.6, 59.77],
              [148.9, 59.24],
              [142.16, 59.07],
              [135.15, 54.86],
              [136.82, 54.65],
              [136.76, 53.77],
              [137.74, 54.32],
              [137.31, 53.53],
              [138.55, 53.99],
              [138.44, 53.51],
              [138.64, 54.3],
              [139.75, 54.31],
              [141.42, 53.29],
              [140.71, 53.11],
              [141.51, 52.21],
              [140.46, 50.71],
              [140.7, 50.09],
              [140.18, 48.45],
              [135.13, 43.5],
              [133.15, 42.68],
              [131.81, 43.33],
              [130.7, 42.29],
              [130.64, 42.41],
              [130.6, 42.42],
              [130.41, 42.72],
              [131.31, 43.39],
              [130.95, 44.84],
              [131.86, 45.35],
              [133.12, 45.13],
              [134.74, 48.27],
              [130.99, 47.69],
              [130.67, 48.86],
              [127.53, 49.79],
              [126.1, 52.76],
              [123.38, 53.53],
              [120.86, 53.28],
              [120.03, 52.77],
              [120.78, 52.11],
              [119.21, 50.02],
              [117.87, 49.52],
              [116.71, 49.83],
              [114.31, 50.28],
              [110.79, 49.15],
              [108.57, 49.33],
              [106.66, 50.34],
              [102.92, 50.32],
              [102.33, 50.57],
              [102.22, 51.33],
              [98.93, 52.14],
              [97.83, 51],
              [98.29, 50.29],
              [97.34, 49.73],
              [92.32, 50.81],
              [87.84, 49.17],
              [87.35, 49.09],
              [86.77, 49.79],
              [85.26, 49.59],
              [83.46, 51],
              [81.47, 50.74],
              [80.69, 51.31],
              [80.08, 50.76],
              [77.91, 53.27],
              [76.52, 53.99],
              [76.81, 54.45],
              [73.44, 53.44],
              [73.76, 54.07],
              [71.19, 54.1],
              [70.84, 55.3],
              [69.19, 55.34],
              [65.22, 54.32],
              [61.01, 53.95],
              [60.91, 53.62],
              [61.58, 53.51],
              [61.18, 53.31],
              [62.12, 53],
              [61.1, 52.98],
              [60.69, 52.68],
              [61.06, 52.34],
              [60, 51.96],
              [61.69, 51.27],
              [61.38, 50.78],
              [59.54, 50.48],
              [58.34, 51.16],
              [56.51, 51.08],
              [55.69, 50.53],
              [54.65, 51.04],
              [54.52, 50.53],
              [53.43, 51.49],
              [50.77, 51.77],
              [48.7, 50.59],
              [48.8, 49.94],
              [47.52, 50.44],
              [46.5, 48.42],
              [47.38, 47.69],
              [48.2, 47.7],
              [49.03, 46.78],
              [48.56, 46.56],
              [49.22, 46.35],
              [48.71, 45.83],
              [47.38, 45.74],
              [46.68, 44.52],
              [47.41, 43.5],
              [47.7, 43.87],
              [47.46, 43.02],
              [48.58, 41.84],
              [47.77, 41.2],
              [46.57, 41.87],
              [46.45, 41.9],
              [44.93, 42.76],
              [43.91, 42.58],
              [42.85, 43.18],
              [40, 43.38],
              [36.58, 45.18],
              [37.74, 45.3],
              [37.94, 46.03],
              [38.57, 46.09],
              [37.73, 46.67],
              [39.3, 47.08],
              [38.24, 47.11],
              [38.85, 47.86],
              [39.8, 47.86],
              [39.66, 48.62],
              [40.08, 48.87],
              [39.7, 49.01],
              [40.14, 49.6],
              [38.02, 49.9],
              [37.46, 50.44],
              [35.61, 50.37],
              [35.37, 51.04],
              [34.38, 51.26],
              [34.1, 51.65],
              [34.42, 51.81],
              [33.42, 52.36],
              [31.78, 52.11],
              [31.27, 53.02],
              [32.74, 53.46],
              [30.78, 54.79],
              [30.93, 55.6],
              [28.17, 56.15],
              [27.86, 57.3],
              [27.37, 57.54],
              [27.82, 57.87],
              [27.43, 58.81],
              [28.02, 59.48],
              [30.25, 59.98],
              [28.6, 60.38],
              [28.69, 60.74],
              [27.81, 60.55],
              [31.59, 62.91],
              [29.99, 63.74],
              [30.58, 64.22],
              [29.64, 64.93],
              [30.13, 65.72],
              [29.07, 66.9],
              [30.03, 67.69],
              [28.69, 68.2],
              [28.82, 68.84],
              [28.43, 68.9],
              [28.96, 69.05],
              [30.85, 69.79],
              [33.09, 69.75],
              [32.03, 69.64],
              [33.52, 69.42],
              [33.02, 68.95],
              [33.72, 69.33],
              [35.97, 69.17],
              [40.99, 67.72],
              [41.22, 66.84],
              [38.61, 66.05],
              [31.85, 67.15],
              [34.85, 65.9],
              [34.38, 65.38],
              [34.79, 64.55],
              [37.41, 63.8],
              [37.98, 64.32],
              [36.44, 64.94],
              [37.03, 65.21],
              [38.41, 64.86],
              [38.05, 64.64],
              [40.51, 64.54],
              [39.75, 65.55],
              [42.17, 66.52],
              [44.17, 65.87],
              [44.5, 66.91],
              [43.75, 67.32],
              [44.25, 68.27],
              [43.31, 68.68],
              [46.52, 68.14],
              [46.71, 67.81],
              [44.91, 67.37],
              [46.39, 66.74],
              [47.7, 66.99],
              [47.99, 67.65],
              [49.1, 67.63],
              [48.59, 67.93],
              [52.27, 68.31],
              [52.73, 68.47],
              [52.49, 68.59],
              [52.65, 68.65],
              [52.29, 68.61],
              [53.78, 68.97],
              [54.56, 68.99],
              [53.59, 68.9],
              [54.02, 68.86],
              [53.95, 68.4],
              [53.21, 68.26],
              [54.79, 68.16],
              [58.9, 69],
              [59.43, 68.75],
              [59.07, 68.42],
              [59.84, 68.37],
              [60.91, 68.9],
              [60.14, 69.57],
              [60.87, 69.86],
              [64.15, 69.54],
              [68.44, 68.22],
              [69.22, 68.96],
              [66.8, 69.57],
              [67.34, 70.75],
              [66.62, 71.05],
              [68.47, 71.82],
              [69.33, 72.95],
              [71.56, 72.91],
              [72.83, 72.71],
              [72.88, 72.28],
              [71.8, 71.47],
              [72.84, 70.87],
              [72.42, 70.27],
              [72.55, 68.98],
              [73.65, 68.46],
              [71.41, 66.97],
              [71.56, 66.65],
              [68.97, 66.8],
              [72, 66.22],
              [74.74, 67.69],
              [74.33, 68.38],
              [74.64, 68.77],
              [76.59, 68.97],
              [77.32, 68.52],
              [77.09, 67.78],
              [79.04, 67.57],
              [77.46, 67.76],
              [78.17, 68.26],
              [77.63, 68.91],
              [73.75, 69.17],
              [73.52, 69.76],
              [74.31, 70.67],
              [73.02, 71.42],
              [74.96, 72.11],
              [74.83, 72.83],
              [75.72, 72.56],
              [75.24, 71.38],
              [76.92, 71.07],
              [79.11, 71],
              [76.1, 71.93],
              [78.1, 71.88],
              [77.37, 72.1],
              [78.54, 72.4],
              [83.26, 71.72],
              [82.26, 71.26],
              [82.08, 70.57],
              [82.35, 70.2],
              [82.16, 70.58],
              [83.12, 70.89],
              [82.64, 70.17],
              [83.11, 70.07],
              [83.75, 70.46],
              [83.15, 71.24],
              [83.63, 71.62],
              [80.72, 72.53],
              [80.82, 72.97],
              [80.23, 73.17],
              [80.51, 73.57],
              [87.09, 73.86],
              [85.78, 73.46],
              [86.78, 72.99],
              [85.85, 73.48],
              [87.66, 73.89],
              [85.95, 74.28],
              [87.13, 74.37],
              [85.79, 74.63],
              [86.03, 74.81],
              [86.91, 74.61],
              [87.79, 75.02],
              [86.99, 75.15],
              [94.16, 75.94],
              [92.87, 75.95],
              [93.16, 76.1],
              [99.28, 76.21],
              [99.77, 76.03],
              [99.09, 75.55],
              [100.19, 75.17],
              [99.17, 75.57],
              [99.88, 76.09],
              [98.81, 76.49],
              [102.23, 76.38],
              [100.85, 76.88],
              [104.27, 77.68]
            ]
          ],
          [
            [
              [102.97, 79.33],
              [102.39, 78.83],
              [103.95, 79.13],
              [105.41, 78.56],
              [99.34, 78.02],
              [101.62, 78.98],
              [100.99, 79.06],
              [101.55, 79.35],
              [102.97, 79.33]
            ]
          ],
          [
            [
              [97.61, 80.17],
              [98.03, 80.07],
              [97.19, 79.7],
              [100.02, 79.82],
              [99.04, 79.29],
              [99.94, 78.95],
              [97.3, 78.85],
              [92.85, 79.55],
              [97.61, 80.17]
            ]
          ]
        ]
      },
      "properties": { "name": "Russia" },
      "id": "RU"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [29.95, -2.31],
              [29.85, -2.76],
              [29.02, -2.74],
              [29.6, -1.39],
              [30.48, -1.06],
              [30.89, -2.08],
              [30.57, -2.4],
              [29.95, -2.31]
            ]
          ]
        ]
      },
      "properties": { "name": "Rwanda" },
      "id": "RW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-63.03, 18.02],
              [-63.14, 18.06],
              [-63.01, 18.07],
              [-63.03, 18.02]
            ]
          ]
        ]
      },
      "properties": { "name": "Saint Barthelemy" },
      "id": "BL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-5.66, -15.99],
              [-5.79, -15.99],
              [-5.7, -15.9],
              [-5.66, -15.99]
            ]
          ]
        ]
      },
      "properties": { "name": "Saint Helena" },
      "id": "SH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-62.55, 17.09],
              [-62.62, 17.11],
              [-62.6, 17.2],
              [-62.55, 17.09]
            ]
          ],
          [
            [
              [-62.7, 17.34],
              [-62.63, 17.22],
              [-62.86, 17.37],
              [-62.7, 17.34]
            ]
          ]
        ]
      },
      "properties": { "name": "Saint Kitts and Nevis" },
      "id": "KN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-63.17, 18.16],
              [-62.97, 18.27],
              [-62.99, 18.23],
              [-63.17, 18.16]
            ]
          ]
        ]
      },
      "properties": { "name": "Anguilla" },
      "id": "AI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-60.95, 13.71],
              [-61.08, 13.88],
              [-60.93, 14.11],
              [-60.95, 13.71]
            ]
          ]
        ]
      },
      "properties": { "name": "Saint Lucia" },
      "id": "LC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-63.01, 18.08],
              [-63.01, 18.07],
              [-63.14, 18.06],
              [-63.01, 18.08]
            ]
          ]
        ]
      },
      "properties": { "name": "Saint Martin" },
      "id": "MF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-56.15, 46.76],
              [-56.24, 46.76],
              [-56.17, 46.81],
              [-56.15, 46.76]
            ]
          ],
          [
            [
              [-56.27, 46.99],
              [-56.37, 46.78],
              [-56.39, 47.12],
              [-56.27, 46.99]
            ]
          ]
        ]
      },
      "properties": { "name": "Saint Pierre and Miquelon" },
      "id": "PM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-61.17, 13.13],
              [-61.28, 13.21],
              [-61.18, 13.38],
              [-61.17, 13.13]
            ]
          ]
        ]
      },
      "properties": { "name": "Saint Vincent and the Grenadines" },
      "id": "VC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [12.42, 43.96],
              [12.51, 43.99],
              [12.46, 43.9],
              [12.42, 43.96]
            ]
          ]
        ]
      },
      "properties": { "name": "San Marino" },
      "id": "SM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [6.52, 0.02],
              [6.47, 0.26],
              [6.69, 0.4],
              [6.52, 0.02]
            ]
          ],
          [
            [
              [7.42, 1.56],
              [7.33, 1.61],
              [7.41, 1.7],
              [7.42, 1.56]
            ]
          ]
        ]
      },
      "properties": { "name": "Sao Tome and Principe" },
      "id": "ST"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [44.72, 29.2],
              [46.55, 29.1],
              [48.42, 28.55],
              [48.84, 27.62],
              [50.16, 26.66],
              [49.99, 26.02],
              [50.83, 24.75],
              [51.22, 24.62],
              [51.58, 24.26],
              [52.58, 22.94],
              [55.2, 22.7],
              [55.67, 22],
              [55, 20],
              [52, 19],
              [48.77, 18.27],
              [46.33, 15.62],
              [46.33, 16.67],
              [44.47, 17.41],
              [43.31, 17.46],
              [42.79, 16.38],
              [40.76, 19.76],
              [39.18, 21.1],
              [38.45, 23.78],
              [37.44, 24.37],
              [35.16, 28.06],
              [34.57, 28.09],
              [34.96, 29.36],
              [36.07, 29.19],
              [37.5, 30],
              [38, 30.5],
              [37.01, 31.51],
              [39.2, 32.15],
              [42.08, 31.11],
              [44.72, 29.2]
            ]
          ]
        ]
      },
      "properties": { "name": "Saudi Arabia" },
      "id": "SA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-17.15, 14.61],
              [-17.54, 14.76],
              [-16.53, 16.06],
              [-16.28, 16.52],
              [-14.35, 16.64],
              [-12.24, 14.76],
              [-11.37, 12.41],
              [-13.71, 12.68],
              [-14.52, 12.68],
              [-16.72, 12.32],
              [-15.39, 12.83],
              [-16.76, 12.56],
              [-16.75, 13.06],
              [-15.81, 13.16],
              [-15.8, 13.35],
              [-15.29, 13.37],
              [-15.11, 13.6],
              [-13.8, 13.41],
              [-15.07, 13.83],
              [-16.57, 13.59],
              [-16.36, 14.17],
              [-16.78, 14.01],
              [-17.15, 14.61]
            ]
          ]
        ]
      },
      "properties": { "name": "Senegal" },
      "id": "SN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [20.07, 42.56],
              [20.35, 42.89],
              [19.23, 43.51],
              [19.62, 44.05],
              [19.04, 44.86],
              [19.42, 45.23],
              [18.82, 45.91],
              [20.26, 46.11],
              [21.51, 45.15],
              [21.4, 44.78],
              [22.48, 44.71],
              [22.68, 44.22],
              [22.37, 43.83],
              [23, 43.19],
              [22.37, 42.32],
              [20.59, 41.88],
              [20.07, 42.56]
            ]
          ]
        ]
      },
      "properties": { "name": "Serbia" },
      "id": "RS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [55.54, -4.76],
              [55.38, -4.63],
              [55.46, -4.55],
              [55.54, -4.76]
            ]
          ]
        ]
      },
      "properties": { "name": "Seychelles" },
      "id": "SC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-11.21, 10],
              [-10.57, 9.06],
              [-10.7, 8.3],
              [-10.27, 8.49],
              [-11.49, 6.93],
              [-12.96, 7.9],
              [-13.3, 9.03],
              [-12.46, 9.89],
              [-11.21, 10]
            ]
          ]
        ]
      },
      "properties": { "name": "Sierra Leone" },
      "id": "SL"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [103.99, 1.38],
              [103.64, 1.32],
              [103.71, 1.43],
              [103.99, 1.38]
            ]
          ]
        ]
      },
      "properties": { "name": "Singapore" },
      "id": "SG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [17.25, 48.02],
              [17.17, 48.01],
              [16.95, 48.62],
              [18.85, 49.52],
              [22.56, 49.08],
              [22.15, 48.41],
              [20.66, 48.56],
              [18.66, 47.76],
              [17.25, 48.02]
            ]
          ]
        ]
      },
      "properties": { "name": "Slovakia" },
      "id": "SK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [105.58, 23.06],
              [106.71, 22.86],
              [106.69, 22.03],
              [107.99, 21.54],
              [106.64, 21.02],
              [105.61, 18.98],
              [108.83, 15.42],
              [109.47, 12.89],
              [109.27, 11.89],
              [108, 10.7],
              [107.27, 10.38],
              [106.74, 10.67],
              [106.42, 10.31],
              [106.78, 10.08],
              [106.29, 10.25],
              [106.62, 9.81],
              [106.12, 10.24],
              [106.54, 9.58],
              [105.82, 10],
              [106.19, 9.37],
              [105.02, 8.59],
              [105.11, 9.95],
              [104.45, 10.42],
              [105.1, 10.96],
              [106.2, 10.77],
              [105.85, 11.66],
              [107.55, 12.35],
              [107.49, 14.45],
              [107.55, 14.71],
              [107.7, 15.27],
              [107.18, 15.78],
              [107.46, 16.08],
              [106.69, 16.46],
              [105.19, 18.64],
              [103.88, 19.29],
              [104.98, 20],
              [104.38, 20.44],
              [104.64, 20.66],
              [103.17, 20.85],
              [102.98, 21.74],
              [102.14, 22.4],
              [102.48, 22.77],
              [103.96, 22.5],
              [105.35, 23.33],
              [105.58, 23.06]
            ]
          ]
        ]
      },
      "properties": { "name": "Vietnam" },
      "id": "VN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [13.72, 46.53],
              [16.11, 46.87],
              [16.61, 46.48],
              [16.57, 46.48],
              [15.65, 46.22],
              [15.17, 45.43],
              [13.59, 45.48],
              [13.72, 45.6],
              [13.38, 46.3],
              [13.72, 46.53]
            ]
          ]
        ]
      },
      "properties": { "name": "Slovenia" },
      "id": "SI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [42.94, 11],
              [43.25, 11.47],
              [44.28, 10.45],
              [44.9, 10.42],
              [50.77, 11.98],
              [51.28, 11.84],
              [51.01, 10.44],
              [51.41, 10.45],
              [50.9, 10.32],
              [50.84, 9.43],
              [47.95, 4.46],
              [43.49, 0.65],
              [41.56, -1.67],
              [41, -0.87],
              [40.99, 2.83],
              [41.91, 3.98],
              [43.69, 4.89],
              [44.95, 4.9],
              [47.99, 8],
              [44.01, 9.01],
              [42.85, 10.22],
              [42.94, 11]
            ]
          ]
        ]
      },
      "properties": { "name": "Somalia" },
      "id": "SO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [29.89, -22.19],
              [31.3, -22.41],
              [32.02, -24.46],
              [31.97, -25.96],
              [31.33, -25.75],
              [30.9, -26.31],
              [31.16, -27.2],
              [31.99, -27.32],
              [32.13, -26.84],
              [32.89, -26.85],
              [32.39, -28.54],
              [27.9, -33.04],
              [25.7, -34.03],
              [22.54, -34.01],
              [20, -34.82],
              [18.79, -34.09],
              [18.4, -34.3],
              [17.85, -32.83],
              [18.29, -32.62],
              [18.28, -31.89],
              [16.49, -28.58],
              [17.06, -28.03],
              [17.4, -28.71],
              [18.18, -28.91],
              [20, -28.42],
              [20, -24.77],
              [20.81, -25.88],
              [20.64, -26.83],
              [21.67, -26.86],
              [23.01, -25.3],
              [25.51, -25.68],
              [26.96, -23.75],
              [29.37, -22.19],
              [29.89, -22.19]
            ],
            [
              [28.57, -28.61],
              [27.01, -29.63],
              [27.56, -30.4],
              [28.08, -30.65],
              [29.17, -29.91],
              [29.43, -29.28],
              [28.57, -28.61]
            ]
          ]
        ]
      },
      "properties": { "name": "South Africa" },
      "id": "ZA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [32.99, -17.27],
              [32.7, -18.94],
              [33.02, -19.94],
              [32.49, -21.34],
              [31.3, -22.41],
              [29.89, -22.19],
              [29.37, -22.19],
              [28.02, -21.57],
              [27.71, -20.51],
              [26.17, -19.53],
              [25.26, -17.8],
              [27.04, -17.96],
              [28.93, -15.97],
              [30.42, -15.63],
              [30.42, -16.01],
              [32.98, -16.71],
              [32.99, -17.27]
            ]
          ]
        ]
      },
      "properties": { "name": "Zimbabwe" },
      "id": "ZW"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-16.34, 28.37],
              [-16.67, 27.98],
              [-16.91, 28.34],
              [-16.34, 28.37]
            ]
          ],
          [
            [
              [3.25, 39.73],
              [3.06, 39.26],
              [2.36, 39.56],
              [3.25, 39.73]
            ]
          ],
          [
            [
              [-7.86, 43.76],
              [-1.78, 43.36],
              [1.45, 42.6],
              [1.72, 42.51],
              [3.18, 42.44],
              [3.18, 41.87],
              [0.96, 41.03],
              [0.05, 40.04],
              [-0.34, 39.44],
              [0.21, 38.73],
              [-0.72, 37.61],
              [-2.13, 36.73],
              [-4.4, 36.72],
              [-5.33, 36.16],
              [-5.36, 36.16],
              [-6.04, 36.18],
              [-6.36, 36.86],
              [-7.43, 37.25],
              [-6.95, 39.03],
              [-7.53, 39.67],
              [-7.02, 39.67],
              [-6.93, 41.02],
              [-6.19, 41.58],
              [-6.59, 41.95],
              [-8.2, 41.87],
              [-8.75, 41.95],
              [-9.21, 43.15],
              [-7.86, 43.76]
            ]
          ]
        ]
      },
      "properties": { "name": "Spain" },
      "id": "ES"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-15.74, 21.34],
              [-16.95, 21.34],
              [-17.05, 20.76],
              [-16.92, 21.94],
              [-16.49, 22.33],
              [-16.36, 22.57],
              [-16.28, 22.9],
              [-16.16, 22.99],
              [-16.18, 23.08],
              [-16.08, 23.32],
              [-15.77, 23.78],
              [-15.78, 23.91],
              [-16.01, 23.67],
              [-15.84, 23.9],
              [-15.58, 24.06],
              [-15.18, 24.49],
              [-15.03, 24.54],
              [-14.9, 24.69],
              [-14.48, 26.17],
              [-13.57, 26.73],
              [-13.17, 27.67],
              [-8.67, 27.67],
              [-8.67, 27.29],
              [-8.67, 26],
              [-12, 26],
              [-12, 23.45],
              [-13.11, 22.89],
              [-13, 21.34],
              [-15.74, 21.34]
            ]
          ]
        ]
      },
      "properties": { "name": "Western Sahara" },
      "id": "EH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [31.45, 22],
              [36.89, 22],
              [37.43, 18.86],
              [38.6, 17.99],
              [37, 17.07],
              [36.54, 14.26],
              [36.14, 12.71],
              [35.1, 11.83],
              [34.86, 10.73],
              [34.29, 10.55],
              [34.12, 8.58],
              [33.25, 8.46],
              [32.99, 7.92],
              [34.7, 6.68],
              [35.94, 4.62],
              [34.39, 4.61],
              [34, 4.22],
              [33.52, 3.75],
              [30.86, 3.49],
              [29.64, 4.64],
              [28.36, 4.29],
              [27.46, 5.02],
              [25.25, 7.85],
              [23.52, 8.71],
              [23.67, 9.87],
              [22.87, 10.92],
              [22.47, 12.62],
              [21.83, 12.8],
              [22.94, 15.56],
              [24, 15.7],
              [24, 19.5],
              [24, 20],
              [25, 20],
              [25, 22],
              [31.45, 22]
            ]
          ]
        ]
      },
      "properties": { "name": "Sudan" },
      "id": "SD"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-55.13, 5.82],
              [-54.03, 5.82],
              [-54.17, 5.35],
              [-54.48, 4.75],
              [-54, 3.45],
              [-54.6, 2.33],
              [-55.97, 2.53],
              [-55.9, 1.89],
              [-56.47, 1.94],
              [-58.05, 4.01],
              [-57.25, 5.49],
              [-56.97, 6],
              [-55.13, 5.82]
            ]
          ]
        ]
      },
      "properties": { "name": "Suriname" },
      "id": "SR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [23.34, 78.2],
              [23.06, 78.02],
              [24.91, 77.75],
              [22.64, 77.25],
              [22.78, 77.55],
              [20.86, 77.46],
              [21.64, 77.91],
              [20.9, 78.11],
              [23.34, 78.2]
            ]
          ],
          [
            [
              [21.5, 78.57],
              [22.27, 78.26],
              [20.12, 78.47],
              [21.5, 78.57]
            ]
          ],
          [
            [
              [16.82, 79.87],
              [18.1, 79.72],
              [17.64, 79.37],
              [18.36, 79.63],
              [18.92, 79.16],
              [21.55, 78.77],
              [18.97, 78.46],
              [19.09, 78.1],
              [16.61, 76.57],
              [15.5, 76.88],
              [16.52, 77],
              [13.91, 77.53],
              [16.22, 77.43],
              [14.74, 77.66],
              [17.01, 77.93],
              [13.94, 77.72],
              [13.59, 78.05],
              [17.3, 78.42],
              [16.33, 78.45],
              [16.83, 78.67],
              [15.46, 78.45],
              [15.38, 78.84],
              [13.01, 78.2],
              [12.36, 78.48],
              [13.2, 78.54],
              [11.33, 78.96],
              [12.51, 78.91],
              [11.76, 79.08],
              [12.11, 79.3],
              [11.24, 79.09],
              [10.68, 79.54],
              [13.82, 79.88],
              [12.45, 79.57],
              [14.06, 79.26],
              [13.89, 79.54],
              [14.58, 79.8],
              [16.45, 78.9],
              [15.64, 79.83],
              [16.82, 79.87]
            ]
          ],
          [
            [
              [20.04, 80.46],
              [22.23, 79.98],
              [22.89, 80.49],
              [23.36, 80.43],
              [23.1, 80.12],
              [24.84, 80.35],
              [27.24, 79.9],
              [23.51, 79.18],
              [19.64, 79.6],
              [22.31, 79.8],
              [18.77, 79.72],
              [17.78, 80.13],
              [20.04, 80.46]
            ]
          ]
        ]
      },
      "properties": { "name": "Svalbard" },
      "id": "SJ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [30.9, -26.31],
              [31.33, -25.75],
              [31.97, -25.96],
              [32.13, -26.84],
              [31.99, -27.32],
              [31.16, -27.2],
              [30.9, -26.31]
            ]
          ]
        ]
      },
      "properties": { "name": "Swaziland" },
      "id": "SZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [21.81, 68.57],
              [23.67, 67.94],
              [23.43, 67.47],
              [24.01, 66.8],
              [23.66, 66.31],
              [24.17, 65.81],
              [21.77, 65.72],
              [22.2, 65.55],
              [21.26, 65.34],
              [21.62, 65.14],
              [21.04, 64.82],
              [21.58, 64.44],
              [20.78, 63.87],
              [18.21, 62.78],
              [17.7, 62.99],
              [18.05, 62.6],
              [17.33, 62.49],
              [17.65, 62.23],
              [17.15, 60.95],
              [19.08, 59.76],
              [17.94, 59.34],
              [17.59, 59.81],
              [17.73, 59.44],
              [16.02, 59.49],
              [17.38, 59.25],
              [18.65, 59.32],
              [16.19, 58.63],
              [16.94, 58.48],
              [16.41, 58.47],
              [16.82, 58.2],
              [16.42, 57.89],
              [16.69, 57.47],
              [15.87, 56.09],
              [14.7, 56.16],
              [14.19, 55.39],
              [12.98, 55.4],
              [12.45, 56.3],
              [12.89, 56.64],
              [11.7, 57.7],
              [11.8, 58.32],
              [11.2, 58.4],
              [11.11, 59],
              [11.43, 58.99],
              [11.82, 59.85],
              [12.49, 60.11],
              [12.21, 61],
              [12.86, 61.36],
              [12.12, 61.73],
              [12.14, 63.58],
              [13.99, 64.02],
              [14.12, 64.47],
              [13.66, 64.58],
              [14.49, 65.31],
              [14.5, 66.13],
              [15.47, 66.28],
              [16.73, 67.9],
              [17.88, 67.95],
              [18.09, 68.51],
              [19.94, 68.34],
              [20.35, 68.79],
              [20.1, 69.04],
              [20.58, 69.06],
              [21.81, 68.57]
            ]
          ]
        ]
      },
      "properties": { "name": "Sweden" },
      "id": "SE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [7.7, 47.54],
              [9.57, 47.54],
              [9.53, 47.27],
              [9.47, 47.06],
              [9.6, 47.06],
              [10.47, 46.87],
              [10.13, 46.23],
              [9.28, 46.5],
              [9.04, 45.84],
              [8.44, 46.46],
              [7.86, 45.92],
              [7.04, 45.93],
              [6.79, 46.43],
              [5.97, 46.21],
              [6.99, 47.5],
              [7.59, 47.58],
              [7.7, 47.54]
            ]
          ],
          [
            [
              [8.71, 47.7],
              [8.68, 47.69],
              [8.67, 47.71],
              [8.71, 47.7]
            ]
          ]
        ]
      },
      "properties": { "name": "Switzerland" },
      "id": "CH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [42.36, 37.11],
              [41.29, 36.36],
              [41, 34.42],
              [38.79, 33.38],
              [36.84, 32.31],
              [35.65, 32.69],
              [35.62, 33.25],
              [36.62, 34.2],
              [35.97, 34.65],
              [35.92, 35.93],
              [36.69, 36.24],
              [36.66, 36.83],
              [42.36, 37.11]
            ]
          ]
        ]
      },
      "properties": { "name": "Syria" },
      "id": "SY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [74.92, 37.24],
              [73.31, 37.46],
              [71.68, 36.68],
              [71.59, 37.9],
              [70.97, 38.47],
              [69.32, 37.12],
              [67.78, 37.19],
              [68.38, 38.2],
              [67.44, 39.48],
              [68.54, 39.55],
              [69.01, 40.09],
              [68.6, 40.18],
              [70.42, 41.05],
              [70.8, 40.73],
              [70.38, 40.38],
              [70.98, 40.24],
              [69.54, 40.13],
              [69.31, 39.54],
              [73.66, 39.45],
              [73.82, 38.61],
              [74.86, 38.47],
              [74.92, 37.24]
            ]
          ]
        ]
      },
      "properties": { "name": "Tajikistan" },
      "id": "TJ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [100.08, 20.35],
              [100.09, 20.35],
              [100.58, 20.16],
              [100.5, 19.53],
              [101.28, 19.56],
              [100.92, 17.57],
              [102.09, 18.21],
              [102.68, 17.82],
              [103.4, 18.43],
              [104.72, 17.5],
              [104.75, 16.53],
              [105.64, 15.66],
              [105.21, 14.35],
              [103.18, 14.33],
              [102.38, 13.57],
              [102.92, 11.64],
              [102.06, 12.57],
              [100.85, 12.68],
              [100.98, 13.46],
              [100.07, 13.42],
              [100.02, 12.19],
              [99.15, 10.37],
              [99.24, 9.25],
              [99.85, 9.3],
              [100.42, 7.16],
              [102.1, 6.24],
              [101.14, 5.63],
              [100.65, 6.45],
              [100.13, 6.42],
              [98.66, 8.38],
              [98.27, 8.27],
              [98.74, 10.35],
              [99.66, 11.83],
              [99.17, 13.73],
              [98.2, 15.07],
              [98.93, 16.39],
              [97.35, 18.56],
              [97.77, 18.57],
              [98.05, 19.81],
              [100.08, 20.35]
            ]
          ]
        ]
      },
      "properties": { "name": "Thailand" },
      "id": "TH"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [1.4, 9.43],
              [1.64, 6.22],
              [1.2, 6.1],
              [0.53, 6.95],
              [0.73, 8.32],
              [-0.15, 11.14],
              [0.92, 11],
              [0.78, 10.38],
              [1.4, 9.43]
            ]
          ]
        ]
      },
      "properties": { "name": "Togo" },
      "id": "TG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-171.84, -9.21],
              [-171.86, -9.21],
              [-171.85, -9.17],
              [-171.84, -9.21]
            ]
          ]
        ]
      },
      "properties": { "name": "Tokelau" },
      "id": "TK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-175.15, -21.18],
              [-175.36, -21.1],
              [-175.05, -21.14],
              [-175.15, -21.18]
            ]
          ]
        ]
      },
      "properties": { "name": "Tonga" },
      "id": "TO"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-60.92, 10.8],
              [-61.01, 10.14],
              [-61.92, 10.04],
              [-61.46, 10.28],
              [-61.66, 10.71],
              [-60.92, 10.8]
            ]
          ],
          [
            [
              [-60.64, 11.2],
              [-60.85, 11.16],
              [-60.53, 11.35],
              [-60.64, 11.2]
            ]
          ]
        ]
      },
      "properties": { "name": "Trinidad and Tobago" },
      "id": "TT"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [56.18, 25.65],
              [56.27, 25.64],
              [56.37, 24.98],
              [56.04, 24.94],
              [55.2, 22.7],
              [52.58, 22.94],
              [51.58, 24.26],
              [54.12, 24.14],
              [56.08, 26.07],
              [56.18, 25.65]
            ]
          ]
        ]
      },
      "properties": { "name": "United Arab Emirates" },
      "id": "AE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [9.85, 37.14],
              [10.38, 36.72],
              [11.07, 37.05],
              [10.46, 36.12],
              [11.13, 35.24],
              [10.01, 34.17],
              [11.53, 33.17],
              [11.57, 32.44],
              [10.29, 31.69],
              [10.21, 30.73],
              [9.54, 30.23],
              [9.06, 32.1],
              [7.49, 33.89],
              [8.25, 34.64],
              [8.18, 36.52],
              [8.62, 36.94],
              [9.85, 37.14]
            ]
          ]
        ]
      },
      "properties": { "name": "Tunisia" },
      "id": "TN"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [35.1, 41.96],
              [38.36, 40.91],
              [40.15, 40.92],
              [41.53, 41.52],
              [42.83, 41.58],
              [43.46, 41.11],
              [43.66, 40.11],
              [44.78, 39.71],
              [44.81, 39.63],
              [44.03, 39.38],
              [44.79, 37.15],
              [42.36, 37.11],
              [36.66, 36.83],
              [36.69, 36.24],
              [35.92, 35.93],
              [36.02, 36.93],
              [32.81, 36.03],
              [31.05, 36.85],
              [29.68, 36.12],
              [28.45, 36.88],
              [27.38, 36.68],
              [28.33, 37.04],
              [27.25, 36.97],
              [27.6, 37.23],
              [27.19, 37.35],
              [27.27, 37.96],
              [26.28, 38.26],
              [26.37, 38.66],
              [27.16, 38.44],
              [26.73, 38.65],
              [26.95, 39.55],
              [26.07, 39.48],
              [26.71, 40.38],
              [29.94, 40.72],
              [29.17, 41.23],
              [31.23, 41.09],
              [33.33, 42.02],
              [35.1, 41.96]
            ]
          ],
          [
            [
              [27.39, 42.01],
              [28.01, 41.98],
              [29.04, 41.06],
              [27.51, 40.98],
              [26.18, 40.05],
              [26.83, 40.59],
              [26.04, 40.74],
              [26.63, 41.35],
              [26.36, 41.71],
              [27.39, 42.01]
            ]
          ]
        ]
      },
      "properties": { "name": "Turkey" },
      "id": "TR"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [58.78, 42.66],
              [60.01, 42.22],
              [60.14, 41.38],
              [61.87, 41.13],
              [62.55, 39.93],
              [64.38, 38.95],
              [66.64, 38],
              [66.54, 37.37],
              [64.8, 37.12],
              [64.5, 36.28],
              [63.12, 35.86],
              [62.72, 35.25],
              [61.28, 35.61],
              [61.16, 36.65],
              [60.33, 36.66],
              [59.34, 37.54],
              [57.21, 38.28],
              [53.91, 37.35],
              [53.98, 38.92],
              [53.16, 39.18],
              [53.26, 39.66],
              [53.73, 39.52],
              [53.58, 39.97],
              [53, 39.76],
              [52.72, 40.45],
              [52.92, 41.08],
              [53.75, 40.62],
              [54.73, 41.1],
              [53.8, 42.12],
              [52.95, 41.97],
              [52.88, 41.05],
              [52.44, 41.74],
              [53.01, 42.14],
              [54.17, 42.34],
              [56, 41.33],
              [57.04, 41.26],
              [56.99, 41.89],
              [58.78, 42.66]
            ]
          ]
        ]
      },
      "properties": { "name": "Turkmenistan" },
      "id": "TM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-71.66, 21.82],
              [-71.66, 21.74],
              [-71.85, 21.85],
              [-71.66, 21.82]
            ]
          ],
          [
            [
              [-71.88, 21.85],
              [-72.03, 21.94],
              [-71.91, 21.94],
              [-71.88, 21.85]
            ]
          ]
        ]
      },
      "properties": { "name": "Turks and Caicos Islands" },
      "id": "TC"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [179.22, -8.55],
              [179.2, -8.46],
              [179.23, -8.5],
              [179.22, -8.55]
            ]
          ]
        ]
      },
      "properties": { "name": "Tuvalu" },
      "id": "TV"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [34, 4.22],
              [35.01, 1.9],
              [33.91, 0.1],
              [33.92, -1],
              [31.68, -1],
              [30.48, -1.06],
              [29.6, -1.39],
              [29.96, 0.83],
              [31.3, 2.12],
              [30.73, 2.45],
              [30.86, 3.49],
              [33.52, 3.75],
              [34, 4.22]
            ]
          ]
        ]
      },
      "properties": { "name": "Uganda" },
      "id": "UG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [33.42, 52.36],
              [34.42, 51.81],
              [34.1, 51.65],
              [34.38, 51.26],
              [35.37, 51.04],
              [35.61, 50.37],
              [37.46, 50.44],
              [38.02, 49.9],
              [40.14, 49.6],
              [39.7, 49.01],
              [40.08, 48.87],
              [39.66, 48.62],
              [39.8, 47.86],
              [38.85, 47.86],
              [38.24, 47.11],
              [35.91, 46.65],
              [34.99, 46.08],
              [35.34, 46.32],
              [35.2, 46.44],
              [35.21, 46.39],
              [35.05, 46.26],
              [34.7, 46.18],
              [34.56, 45.98],
              [33.67, 46.22],
              [35.13, 45.33],
              [35.34, 45.33],
              [35.05, 45.61],
              [34.76, 46.02],
              [34.67, 46.09],
              [34.83, 46.07],
              [34.9, 45.88],
              [35.05, 45.65],
              [35.31, 45.38],
              [35.48, 45.29],
              [36.64, 45.38],
              [33.96, 44.38],
              [33.37, 44.58],
              [33.54, 45.11],
              [32.48, 45.4],
              [33.61, 46.15],
              [31.79, 46.28],
              [32.06, 46.4],
              [31.51, 46.58],
              [32.36, 46.46],
              [32.65, 46.64],
              [32.02, 46.63],
              [31.75, 47.25],
              [31.91, 46.65],
              [31.48, 46.63],
              [31.59, 46.8],
              [30.83, 46.55],
              [30.25, 45.88],
              [29.75, 45.62],
              [29.63, 45.82],
              [29.66, 45.21],
              [28.21, 45.45],
              [28.99, 46.48],
              [30.12, 46.39],
              [29.95, 46.81],
              [29.14, 47.99],
              [27.76, 48.45],
              [26.63, 48.26],
              [24.92, 47.71],
              [22.89, 47.95],
              [22.15, 48.41],
              [22.56, 49.08],
              [24.11, 50.57],
              [23.6, 51.53],
              [25.78, 51.94],
              [30.55, 51.25],
              [30.94, 52.07],
              [31.78, 52.11],
              [33.42, 52.36]
            ]
          ]
        ]
      },
      "properties": { "name": "Ukraine" },
      "id": "UA"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [20.82, 40.91],
              [20.59, 41.88],
              [22.37, 42.32],
              [22.94, 41.34],
              [20.98, 40.86],
              [20.82, 40.91]
            ]
          ]
        ]
      },
      "properties": { "name": "Macedonia" },
      "id": "MK"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [25.32, 31.5],
              [29.07, 30.82],
              [31.03, 31.6],
              [31.92, 31.53],
              [32.14, 31.07],
              [34.22, 31.32],
              [34.27, 31.22],
              [34.9, 29.49],
              [34.25, 27.73],
              [33.24, 28.55],
              [32.57, 30.01],
              [32.34, 29.6],
              [35.81, 23.92],
              [35.48, 23.94],
              [35.67, 22.97],
              [36.89, 22],
              [31.45, 22],
              [25, 22],
              [24.71, 30.17],
              [25.15, 31.65],
              [25.32, 31.5]
            ]
          ]
        ]
      },
      "properties": { "name": "Egypt" },
      "id": "EG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-6.35, 55.24],
              [-5.43, 54.48],
              [-6.27, 54.1],
              [-8.16, 54.44],
              [-7.41, 54.95],
              [-7.25, 55.07],
              [-6.35, 55.24]
            ]
          ],
          [
            [
              [-5.78, 56.51],
              [-6.37, 56.31],
              [-6.32, 56.6],
              [-5.78, 56.51]
            ]
          ],
          [
            [
              [-6.14, 57.57],
              [-5.66, 57.2],
              [-6.79, 57.45],
              [-6.14, 57.57]
            ]
          ],
          [
            [
              [-6.2, 58.36],
              [-7.12, 57.82],
              [-7.04, 58.23],
              [-6.2, 58.36]
            ]
          ],
          [
            [
              [-3.01, 58.63],
              [-4.44, 57.57],
              [-1.77, 57.46],
              [-3.28, 56.36],
              [-2.58, 56.27],
              [-3.73, 56.03],
              [-1.63, 55.58],
              [-1.3, 54.76],
              [-0.07, 54.11],
              [0.12, 53.56],
              [-0.72, 53.7],
              [0.24, 53.4],
              [0.000037000000134, 52.88],
              [1.68, 52.75],
              [1.59, 52.08],
              [0.38, 51.45],
              [1.41, 51.18],
              [-5.68, 50.04],
              [-4.23, 51.19],
              [-3.03, 51.21],
              [-2.38, 51.76],
              [-3.35, 51.38],
              [-5.25, 51.73],
              [-4.13, 52.33],
              [-4.13, 52.91],
              [-4.76, 52.79],
              [-4.2, 53.21],
              [-2.7, 53.35],
              [-3.11, 53.55],
              [-2.81, 54.22],
              [-3.63, 54.51],
              [-3.04, 54.98],
              [-4.95, 54.65],
              [-5.17, 55],
              [-4.61, 55.49],
              [-4.92, 55.7],
              [-4.88, 55.94],
              [-4.48, 55.92],
              [-4.83, 56.11],
              [-5.3, 55.85],
              [-5.03, 56.23],
              [-5.78, 55.3],
              [-5.12, 56.82],
              [-6.24, 56.71],
              [-5.4, 57.11],
              [-5.82, 57.82],
              [-5.1, 57.85],
              [-5.46, 58.08],
              [-5, 58.62],
              [-3.01, 58.63]
            ]
          ],
          [
            [
              [-2.79, 58.95],
              [-3.19, 58.91],
              [-3.35, 59.11],
              [-2.79, 58.95]
            ]
          ],
          [
            [
              [-1.3, 60.49],
              [-1.27, 59.85],
              [-1.69, 60.28],
              [-1.3, 60.49]
            ]
          ]
        ]
      },
      "properties": { "name": "United Kingdom" },
      "id": "GB"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-2.59, 49.42],
              [-2.67, 49.43],
              [-2.5, 49.51],
              [-2.59, 49.42]
            ]
          ]
        ]
      },
      "properties": { "name": "Guernsey" },
      "id": "GG"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-2.01, 49.21],
              [-2.21, 49.18],
              [-2.25, 49.25],
              [-2.01, 49.21]
            ]
          ]
        ]
      },
      "properties": { "name": "Jersey" },
      "id": "JE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-4.78, 54.06],
              [-4.35, 54.41],
              [-4.39, 54.19],
              [-4.78, 54.06]
            ]
          ]
        ]
      },
      "properties": { "name": "Isle of Man" },
      "id": "IM"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [31.68, -1],
              [33.92, -1],
              [37.6, -3],
              [37.61, -3.5],
              [39.2, -4.67],
              [38.78, -6.05],
              [39.55, -6.99],
              [39.39, -8.9],
              [40.44, -10.48],
              [37.46, -11.73],
              [34.97, -11.57],
              [34.33, -9.73],
              [33.13, -9.49],
              [32.94, -9.41],
              [30.77, -8.19],
              [29.55, -6.3],
              [29.42, -4.45],
              [30.83, -3.26],
              [30.57, -2.4],
              [30.89, -2.08],
              [30.48, -1.06],
              [31.68, -1]
            ]
          ]
        ]
      },
      "properties": { "name": "Tanzania" },
      "id": "TZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-155.01, 19.33],
              [-155.86, 19.03],
              [-155.86, 20.27],
              [-155.16, 19.96],
              [-155.01, 19.33]
            ]
          ],
          [
            [
              [-156.47, 20.9],
              [-155.99, 20.75],
              [-156.42, 20.59],
              [-156.47, 20.9]
            ]
          ],
          [
            [
              [-157.73, 21.41],
              [-158.1, 21.3],
              [-158.27, 21.58],
              [-157.73, 21.41]
            ]
          ],
          [
            [
              [-159.43, 21.88],
              [-159.79, 22.06],
              [-159.35, 22.22],
              [-159.43, 21.88]
            ]
          ],
          [
            [
              [-95.08, 49.36],
              [-94.61, 48.72],
              [-91.42, 48.04],
              [-88.37, 48.31],
              [-84.13, 46.53],
              [-82.54, 45.36],
              [-82.13, 43.59],
              [-83.17, 42.05],
              [-82.7, 41.68],
              [-78.99, 42.82],
              [-79.18, 43.47],
              [-78.72, 43.63],
              [-76.8, 43.63],
              [-74.99, 44.99],
              [-70.88, 45.24],
              [-69.23, 47.47],
              [-67.79, 47.07],
              [-67.8, 45.7],
              [-67.21, 45.18],
              [-67.19, 44.66],
              [-68.8, 44.58],
              [-70.73, 43.12],
              [-70.58, 42.65],
              [-71.04, 42.31],
              [-69.94, 41.67],
              [-71.19, 41.47],
              [-71.39, 41.81],
              [-71.51, 41.37],
              [-74, 40.71],
              [-73.96, 41.31],
              [-74.42, 39.36],
              [-74.96, 38.92],
              [-75.56, 39.62],
              [-75.03, 40.01],
              [-75.59, 39.65],
              [-75.04, 38.42],
              [-75.96, 37.15],
              [-75.64, 37.96],
              [-76.36, 38.86],
              [-75.83, 39.58],
              [-76.62, 39.25],
              [-76.31, 38.05],
              [-77.24, 38.4],
              [-77.06, 38.91],
              [-77.32, 38.35],
              [-76.24, 37.9],
              [-76.35, 37.62],
              [-77.13, 38.17],
              [-76.27, 37.08],
              [-77.23, 37.3],
              [-75.99, 36.91],
              [-75.53, 35.8],
              [-75.94, 36.72],
              [-75.79, 36.07],
              [-76.71, 36.26],
              [-76.73, 35.94],
              [-75.72, 35.81],
              [-76.15, 35.34],
              [-77.05, 35.53],
              [-76.47, 35.27],
              [-76.94, 34.98],
              [-76.34, 34.89],
              [-77.43, 34.74],
              [-77.93, 33.93],
              [-78.83, 33.73],
              [-80.47, 32.32],
              [-80.84, 32.52],
              [-81.5, 31.13],
              [-80.03, 26.79],
              [-80.4, 25.18],
              [-81.09, 25.12],
              [-81.74, 25.96],
              [-81.78, 26.71],
              [-82.06, 26.54],
              [-82.66, 27.46],
              [-82.42, 27.92],
              [-82.85, 27.86],
              [-82.63, 28.88],
              [-83.67, 29.91],
              [-85.35, 29.68],
              [-86.26, 30.5],
              [-88.02, 30.22],
              [-88.02, 30.7],
              [-88.13, 30.31],
              [-90.42, 30.2],
              [-89.4, 30.05],
              [-89.75, 29.63],
              [-89.01, 29.18],
              [-89.4, 28.93],
              [-90.18, 29.57],
              [-90.21, 29.09],
              [-91.25, 29.24],
              [-91.84, 29.83],
              [-92.31, 29.54],
              [-93.85, 29.99],
              [-93.86, 29.68],
              [-94.77, 29.36],
              [-94.48, 29.56],
              [-95.06, 29.72],
              [-95.14, 29.06],
              [-96.21, 28.49],
              [-96.64, 28.72],
              [-96.4, 28.44],
              [-97.52, 27.87],
              [-97.41, 27.33],
              [-97.77, 27.46],
              [-97.14, 25.97],
              [-99.1, 26.43],
              [-99.51, 27.57],
              [-101.41, 29.77],
              [-102.31, 29.89],
              [-103.38, 29.02],
              [-106.4, 31.75],
              [-111.05, 31.33],
              [-113.05, 31.97],
              [-114.72, 32.72],
              [-117.12, 32.54],
              [-118.53, 34.05],
              [-120.62, 34.57],
              [-120.61, 35.14],
              [-122.49, 37.52],
              [-122.39, 37.82],
              [-122.01, 37.47],
              [-122.39, 37.96],
              [-122.24, 38.06],
              [-121.42, 38.01],
              [-122.37, 38.16],
              [-122.49, 37.83],
              [-123, 38.01],
              [-124.33, 40.27],
              [-124.04, 41.43],
              [-124.52, 42.87],
              [-123.95, 46.18],
              [-123.16, 46.2],
              [-124, 46.32],
              [-123.8, 46.98],
              [-124.16, 46.94],
              [-124.72, 48.4],
              [-122.75, 48.16],
              [-123.15, 47.37],
              [-122.45, 47.77],
              [-122.88, 47.06],
              [-122.31, 47.4],
              [-122.76, 49],
              [-95.15, 49],
              [-95.08, 49.36]
            ]
          ],
          [
            [
              [-156.45, 71.26],
              [-155.59, 71.16],
              [-155.97, 70.76],
              [-155.09, 71.15],
              [-152.25, 70.84],
              [-152.5, 70.65],
              [-152.08, 70.57],
              [-152.63, 70.56],
              [-151.97, 70.44],
              [-143.28, 70.12],
              [-141, 69.64],
              [-141, 60.31],
              [-139.07, 60.35],
              [-137.48, 58.91],
              [-135.47, 59.8],
              [-133.43, 58.46],
              [-131.82, 56.6],
              [-130.02, 55.91],
              [-130.17, 55.75],
              [-130.14, 55.54],
              [-129.99, 55.28],
              [-130.36, 54.91],
              [-130.69, 54.76],
              [-131.01, 55],
              [-130.46, 55.33],
              [-131.06, 55.12],
              [-130.61, 55.3],
              [-131.01, 56.11],
              [-132.16, 55.58],
              [-131.77, 56.2],
              [-133.51, 57.19],
              [-133.06, 57.35],
              [-133.64, 57.7],
              [-133, 57.51],
              [-133.56, 57.9],
              [-133.12, 57.86],
              [-134.05, 58.07],
              [-133.77, 58.52],
              [-134.76, 58.38],
              [-135.34, 59.47],
              [-135.09, 58.23],
              [-135.92, 58.38],
              [-135.77, 58.9],
              [-137.06, 59.07],
              [-136.03, 58.39],
              [-136.65, 58.22],
              [-139.71, 59.5],
              [-139.49, 59.98],
              [-139.29, 59.57],
              [-138.89, 59.81],
              [-139.5, 60.03],
              [-140.4, 59.7],
              [-141.39, 60.14],
              [-143.92, 59.99],
              [-144.94, 60.3],
              [-144.61, 60.72],
              [-145.29, 60.35],
              [-146.65, 60.7],
              [-146.12, 60.84],
              [-146.76, 60.96],
              [-146.3, 61.13],
              [-147.87, 60.83],
              [-147.72, 61.28],
              [-148.7, 60.79],
              [-148.2, 60.63],
              [-148.68, 60.45],
              [-147.94, 60.46],
              [-148.44, 59.95],
              [-149.42, 60.12],
              [-150.91, 59.24],
              [-151.98, 59.28],
              [-150.99, 59.78],
              [-151.88, 59.75],
              [-151.41, 60.73],
              [-149.03, 60.85],
              [-150.06, 61.15],
              [-149.42, 61.51],
              [-153.1, 60.29],
              [-152.58, 60.06],
              [-154.26, 59.14],
              [-153.27, 58.85],
              [-156.55, 56.98],
              [-158.42, 56.44],
              [-158.12, 56.23],
              [-158.51, 55.99],
              [-161.25, 55.35],
              [-161.56, 55.62],
              [-161.97, 55.1],
              [-162.63, 55.3],
              [-162.56, 54.96],
              [-163.36, 54.81],
              [-161.8, 55.89],
              [-160.25, 55.77],
              [-160.35, 56.29],
              [-157.4, 57.49],
              [-157.61, 58.09],
              [-157.14, 58.16],
              [-157.55, 58.38],
              [-156.78, 59.15],
              [-158.19, 58.61],
              [-158.49, 59],
              [-157.99, 58.9],
              [-158.54, 59.18],
              [-158.9, 58.4],
              [-160.33, 59.06],
              [-162.17, 58.65],
              [-161.57, 59.1],
              [-161.99, 59.14],
              [-161.71, 59.5],
              [-162.24, 60.06],
              [-162.15, 60.25],
              [-162.37, 60.18],
              [-161.88, 60.7],
              [-162.57, 60.32],
              [-162.52, 59.99],
              [-164.07, 59.82],
              [-165.43, 60.56],
              [-163.55, 60.9],
              [-165.15, 60.93],
              [-164.82, 61.11],
              [-165.12, 61.08],
              [-165.14, 61.26],
              [-165.16, 61.17],
              [-165.37, 61.2],
              [-165.29, 61.25],
              [-165.15, 61.42],
              [-165.06, 61.42],
              [-165, 61.47],
              [-164.85, 61.49],
              [-164.72, 61.63],
              [-164.76, 61.63],
              [-165.02, 61.5],
              [-165.08, 61.43],
              [-165.16, 61.43],
              [-165.29, 61.33],
              [-165.27, 61.31],
              [-165.31, 61.26],
              [-165.41, 61.21],
              [-165.34, 61.16],
              [-165.39, 61.07],
              [-166.2, 61.59],
              [-165.25, 62.45],
              [-164.64, 62.42],
              [-164.48, 62.75],
              [-164.88, 62.84],
              [-164.41, 63.21],
              [-161.15, 63.51],
              [-160.78, 63.87],
              [-161.53, 64.42],
              [-160.78, 64.72],
              [-162.79, 64.34],
              [-166.12, 64.57],
              [-166.96, 65.19],
              [-166.06, 65.26],
              [-168.13, 65.67],
              [-164.35, 66.59],
              [-163.63, 66.57],
              [-164.19, 66.2],
              [-163.66, 66.07],
              [-161, 66.2],
              [-161.91, 66.27],
              [-162.64, 66.87],
              [-162.34, 66.96],
              [-161.6, 66.45],
              [-160.23, 66.4],
              [-161.51, 66.53],
              [-161.9, 66.73],
              [-161.5, 66.98],
              [-162.35, 67.16],
              [-163.73, 67.11],
              [-164.12, 67.61],
              [-166.83, 68.35],
              [-166.22, 68.88],
              [-163.65, 69.11],
              [-161.94, 70.31],
              [-159.94, 70.59],
              [-159.84, 70.27],
              [-159.29, 70.53],
              [-160.12, 70.62],
              [-159.67, 70.8],
              [-156.45, 71.26]
            ]
          ]
        ]
      },
      "properties": { "name": "United States" },
      "id": "US"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-64.76, 17.77],
              [-64.56, 17.75],
              [-64.9, 17.68],
              [-64.76, 17.77]
            ]
          ]
        ]
      },
      "properties": { "name": "Virgin Islands" },
      "id": "VI"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-2.83, 11],
              [-2.69, 9.48],
              [-3.63, 9.95],
              [-4.7, 9.7],
              [-5.52, 10.44],
              [-5.27, 11.84],
              [-4.42, 12.3],
              [-3.96, 13.5],
              [-3.44, 13.17],
              [-2.47, 14.29],
              [-0.73, 15.08],
              [0.24, 14.92],
              [0.6, 13.7],
              [1.29, 13.35],
              [0.99, 13.05],
              [2.14, 12.69],
              [2.4, 11.9],
              [0.92, 11],
              [-0.15, 11.14],
              [-2.83, 11]
            ]
          ]
        ]
      },
      "properties": { "name": "Burkina Faso" },
      "id": "BF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-57.81, -30.75],
              [-57.61, -30.18],
              [-56.81, -30.11],
              [-56.01, -31.08],
              [-55.58, -30.85],
              [-53.88, -31.97],
              [-53.09, -32.73],
              [-53.52, -33.15],
              [-53.37, -33.74],
              [-54.15, -34.67],
              [-56.32, -34.91],
              [-57.12, -34.46],
              [-57.84, -34.49],
              [-58.4, -33.93],
              [-58.36, -33.13],
              [-58.15, -33.1],
              [-58.05, -32.93],
              [-58.2, -32.45],
              [-57.81, -30.75]
            ]
          ]
        ]
      },
      "properties": { "name": "Uruguay" },
      "id": "UY"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [64.38, 38.95],
              [62.55, 39.93],
              [61.87, 41.13],
              [60.14, 41.38],
              [60.01, 42.22],
              [58.78, 42.66],
              [56.99, 41.89],
              [57.04, 41.26],
              [56, 41.33],
              [56, 45],
              [58.57, 45.57],
              [62.03, 43.48],
              [64.93, 43.74],
              [66.12, 43],
              [66.03, 42],
              [66.53, 42],
              [66.72, 41.17],
              [67.94, 41.18],
              [68.46, 40.6],
              [69.06, 41.38],
              [70.97, 42.25],
              [71.28, 42.2],
              [70.19, 41.53],
              [71.42, 41.12],
              [71.69, 41.56],
              [73.17, 40.82],
              [70.98, 40.24],
              [70.38, 40.38],
              [70.8, 40.73],
              [70.42, 41.05],
              [68.6, 40.18],
              [69.01, 40.09],
              [68.54, 39.55],
              [67.44, 39.48],
              [68.38, 38.2],
              [67.78, 37.19],
              [66.54, 37.37],
              [66.64, 38],
              [64.38, 38.95]
            ]
          ]
        ]
      },
      "properties": { "name": "Uzbekistan" },
      "id": "UZ"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-69.77, 11.7],
              [-68.42, 11.18],
              [-68.16, 10.5],
              [-66.24, 10.64],
              [-65.08, 10.06],
              [-63.7, 10.49],
              [-64.26, 10.66],
              [-61.88, 10.73],
              [-62.92, 10.53],
              [-62.62, 10.12],
              [-63.02, 10.1],
              [-60.85, 9.44],
              [-61.6, 8.55],
              [-59.99, 8.54],
              [-59.83, 8.24],
              [-60.72, 7.54],
              [-60.29, 7.06],
              [-61.13, 6.71],
              [-61.39, 5.94],
              [-60.73, 5.2],
              [-60.99, 4.52],
              [-62.75, 4.03],
              [-62.88, 3.56],
              [-64.8, 4.28],
              [-64.05, 2.48],
              [-63.39, 2.15],
              [-65.52, 0.65],
              [-66.87, 1.22],
              [-67.19, 2.39],
              [-67.83, 2.83],
              [-67.29, 3.4],
              [-67.86, 4.56],
              [-67.45, 6.19],
              [-69.25, 6.08],
              [-70.12, 6.98],
              [-72, 7.02],
              [-72.78, 9.08],
              [-73.38, 9.17],
              [-72.49, 11.12],
              [-71.98, 11.66],
              [-71.32, 11.85],
              [-71.97, 11.56],
              [-71.58, 10.71],
              [-72.13, 9.81],
              [-71.62, 9.04],
              [-71.06, 9.34],
              [-71.5, 10.96],
              [-69.8, 11.43],
              [-70.24, 11.63],
              [-70.03, 12.2],
              [-69.77, 11.7]
            ]
          ]
        ]
      },
      "properties": { "name": "Venezuela" },
      "id": "VE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-178.04, -14.32],
              [-178.14, -14.32],
              [-178.19, -14.24],
              [-178.04, -14.32]
            ]
          ],
          [
            [
              [-176.16, -13.35],
              [-176.16, -13.21],
              [-176.12, -13.26],
              [-176.16, -13.35]
            ]
          ]
        ]
      },
      "properties": { "name": "Wallis and Futuna" },
      "id": "WF"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-171.43, -14.02],
              [-172.06, -13.87],
              [-171.82, -13.81],
              [-171.43, -14.02]
            ]
          ],
          [
            [
              [-172.29, -13.49],
              [-172.21, -13.81],
              [-172.78, -13.53],
              [-172.29, -13.49]
            ]
          ]
        ]
      },
      "properties": { "name": "Samoa" },
      "id": "WS"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [52.23, 15.67],
              [43.96, 12.59],
              [43.25, 13.21],
              [42.79, 16.38],
              [43.31, 17.46],
              [44.47, 17.41],
              [46.33, 16.67],
              [46.33, 15.62],
              [48.77, 18.27],
              [52, 19],
              [53.11, 16.64],
              [52.3, 16.27],
              [52.23, 15.67]
            ]
          ]
        ]
      },
      "properties": { "name": "Yemen" },
      "id": "YE"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [24.97, -17.56],
              [23.48, -17.63],
              [22, -16.17],
              [22, -13],
              [24.02, -13.01],
              [23.99, -10.87],
              [24.45, -11.46],
              [25.33, -11.19],
              [26, -11.9],
              [26.87, -11.97],
              [27.2, -11.57],
              [29.02, -13.4],
              [29.8, -13.45],
              [29.81, -12.16],
              [29.03, -12.38],
              [28.36, -11.55],
              [28.7, -10.65],
              [28.37, -9.26],
              [28.9, -8.48],
              [30.77, -8.19],
              [32.94, -9.41],
              [33.7, -10.56],
              [33.25, -10.89],
              [33.55, -12.36],
              [32.68, -13.61],
              [33.22, -14.01],
              [30.21, -14.98],
              [30.42, -15.63],
              [28.93, -15.97],
              [27.04, -17.96],
              [25.26, -17.8],
              [24.97, -17.56]
            ]
          ]
        ]
      },
      "properties": { "name": "Zambia" },
      "id": "ZM"
    }
  ]
}

export default data;