import * as React from "react";
import NavBar from "./components/NavBar/NavBar";
import News from "./views/News/News";
import Foods from "./views/Foods/Foods";
import Issues from "./views/Issues/Issues";
import Substances from "./views/Substances/Substances";
import Recalls from "./views/Recalls/Recalls";
import { tabMenu } from "./components/NavBar/NavBar";
import "./App.scss";


export default function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="views-wrapper">
      {tabMenu.value === 'news' && <News />}
      {tabMenu.value === 'foods' && <Foods />}
      {tabMenu.value === 'issues' && <Issues />}
      {tabMenu.value === 'substances' && <Substances />}
      {tabMenu.value === 'recalls' && <Recalls />}
      </div>
    </div>
  );
}