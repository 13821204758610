const data2 = [
  {
    "country": "AD",
    "hot dog": 5,
    "hot dogColor": "hsl(90, 70%, 50%)",
    "burger": 177,
    "burgerColor": "hsl(308, 70%, 50%)",
    "sandwich": 13,
    "sandwichColor": "hsl(59, 70%, 50%)",
    "kebab": 53,
    "kebabColor": "hsl(24, 70%, 50%)",
    "fries": 72,
    "friesColor": "hsl(340, 70%, 50%)",
    "donut": 102,
    "donutColor": "hsl(286, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 45,
    "hot dogColor": "hsl(166, 70%, 50%)",
    "burger": 174,
    "burgerColor": "hsl(142, 70%, 50%)",
    "sandwich": 27,
    "sandwichColor": "hsl(267, 70%, 50%)",
    "kebab": 14,
    "kebabColor": "hsl(347, 70%, 50%)",
    "fries": 22,
    "friesColor": "hsl(203, 70%, 50%)",
    "donut": 94,
    "donutColor": "hsl(177, 70%, 50%)"
  },
  {
    "country": "AF",
    "hot dog": 61,
    "hot dogColor": "hsl(134, 70%, 50%)",
    "burger": 198,
    "burgerColor": "hsl(298, 70%, 50%)",
    "sandwich": 190,
    "sandwichColor": "hsl(29, 70%, 50%)",
    "kebab": 119,
    "kebabColor": "hsl(185, 70%, 50%)",
    "fries": 198,
    "friesColor": "hsl(171, 70%, 50%)",
    "donut": 117,
    "donutColor": "hsl(158, 70%, 50%)"
  },
  {
    "country": "AG",
    "hot dog": 183,
    "hot dogColor": "hsl(147, 70%, 50%)",
    "burger": 188,
    "burgerColor": "hsl(321, 70%, 50%)",
    "sandwich": 121,
    "sandwichColor": "hsl(323, 70%, 50%)",
    "kebab": 187,
    "kebabColor": "hsl(345, 70%, 50%)",
    "fries": 63,
    "friesColor": "hsl(251, 70%, 50%)",
    "donut": 171,
    "donutColor": "hsl(219, 70%, 50%)"
  },
  {
    "country": "AI",
    "hot dog": 176,
    "hot dogColor": "hsl(174, 70%, 50%)",
    "burger": 185,
    "burgerColor": "hsl(233, 70%, 50%)",
    "sandwich": 199,
    "sandwichColor": "hsl(138, 70%, 50%)",
    "kebab": 61,
    "kebabColor": "hsl(50, 70%, 50%)",
    "fries": 10,
    "friesColor": "hsl(119, 70%, 50%)",
    "donut": 150,
    "donutColor": "hsl(345, 70%, 50%)"
  },
  {
    "country": "AL",
    "hot dog": 145,
    "hot dogColor": "hsl(183, 70%, 50%)",
    "burger": 32,
    "burgerColor": "hsl(330, 70%, 50%)",
    "sandwich": 86,
    "sandwichColor": "hsl(42, 70%, 50%)",
    "kebab": 22,
    "kebabColor": "hsl(292, 70%, 50%)",
    "fries": 192,
    "friesColor": "hsl(138, 70%, 50%)",
    "donut": 80,
    "donutColor": "hsl(46, 70%, 50%)"
  },
  {
    "country": "AM",
    "hot dog": 138,
    "hot dogColor": "hsl(46, 70%, 50%)",
    "burger": 123,
    "burgerColor": "hsl(125, 70%, 50%)",
    "sandwich": 144,
    "sandwichColor": "hsl(351, 70%, 50%)",
    "kebab": 135,
    "kebabColor": "hsl(148, 70%, 50%)",
    "fries": 159,
    "friesColor": "hsl(281, 70%, 50%)",
    "donut": 105,
    "donutColor": "hsl(194, 70%, 50%)"
  }
]

export default data2;