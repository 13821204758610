const global = [
	{
		title: 'Obesity',
		value: '5115',
		percentage: 5,
		trend: 'up',
	},
	{
		title: 'Processed foods',
		value: '389',
		percentage: 28,
		trend: 'up'
	},
	{
		title: 'Endocrine disruption',
		value: '1405',
		percentage: 5,
		trend: 'up'
	},
	{ 
		title: 'Food packaging',
		value: '1161',
		percentage: 48,
		trend: 'down'
	},
	{
		title: 'Food additives',
		value: '1312',
		percentage: 14,
		trend: 'up'
	}
]

const recalls = {
	headers: [
		'food', 'substance', 'Change'
	],
	values: [
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: 'Guar gum',
				type: 'text'
			},
			{
				value: 'Ethylene Oxide',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#DD1616'
			},
		],
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: 'Fish',
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#F47C25'
			},
		],
		[
			{
				value: null,
				type: 'text'
			},
			{
				value: 'Mercury',
				type: 'text'
			},
			{
				value: 25,
				type: 'chip',
				color: '#DD1616'
			},
		]
	]
}

const substances = {
	headers: [
		'Substance', 'Publication', 'Publication change', 'Risk change'
	],
	values: [
		[
			{
				value: 'DEHP',
				type: 'text'
			},
			{
				value: '109',
				type: 'text'
			},
			{
				value: null,
				type: 'text',
			},
			{
				value: '+14',
				type: 'text',
			},
		],
		[
			{
				value: 'DEHP',
				type: 'text'
			},
			{
				value: '109',
				type: 'text'
			},
			{
				value: null,
				type: 'text',
			},
			{
				value: '+14',
				type: 'text',
			},
		],
		[
			{
				value: 'DEHP',
				type: 'text'
			},
			{
				value: '109',
				type: 'text'
			},
			{
				value: null,
				type: 'text',
			},
			{
				value: '+14',
				type: 'text',
			},
		],
		[
			{
				value: 'DEHP',
				type: 'text'
			},
			{
				value: '109',
				type: 'text'
			},
			{
				value: null,
				type: 'text',
			},
			{
				value: '+14',
				type: 'text',
			},
		],
		[
			{
				value: 'DEHP',
				type: 'text'
			},
			{
				value: '109',
				type: 'text'
			},
			{
				value: null,
				type: 'text',
			},
			{
				value: '+14',
				type: 'text',
			},
		],
	],
}

const opinions = {
	headers: [
		'Date', 'Substance', 'Agency', 'Risk', 'Opinion', 'Title'
	],
	values: [
		[
			{
				value: '2023/11/23',
				type: 'date'
			},
			{
				value: 'ARTIFICIAL FLAVOR',
				type: 'text'
			},
			{
				value: 'EFSA',
				type: 'text',
			},
			{
				value: 'Cancer',
				type: 'text',
			},
			{
				value: 'UNSAFE',
				type: 'chip',
				color: '#F47C25'
			},
			{
				value: 'Scientific opinion on the renewal of the authorisation of proFagus SmokeR714....',
				type: 'link',
			},
		],
		[
			{
				value: '2023/11/23',
				type: 'date'
			},
			{
				value: 'ARTIFICIAL FLAVOR',
				type: 'text'
			},
			{
				value: 'EFSA',
				type: 'text',
			},
			{
				value: 'Cancer',
				type: 'text',
			},
			{
				value: 'UNSAFE',
				type: 'chip',
				color: '#F47C25'
			},
			{
				value: 'Scientific opinion on the renewal of the authorisation of proFagus SmokeR714....',
				type: 'link',
			},
		],
		[
			{
				value: '2023/11/23',
				type: 'date'
			},
			{
				value: 'ARTIFICIAL FLAVOR',
				type: 'text'
			},
			{
				value: 'EFSA',
				type: 'text',
			},
			{
				value: 'Cancer',
				type: 'text',
			},
			{
				value: 'UNSAFE',
				type: 'chip',
				color: '#F47C25'
			},
			{
				value: 'Scientific opinion on the renewal of the authorisation of proFagus SmokeR714....',
				type: 'link',
			},
		]
	],
}

export { global, recalls, substances, opinions };